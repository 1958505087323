.POS {
	position: fixed;
	display: block;
	overflow: hidden;
	padding: 0;
	border: 2px solid rgb(0, 0, 0);
	border-radius: 3px;
	background-color: var(--whiteSkyTill);
	opacity: 1;
	filter: alpha(opacity=100);
	animation: appear 0.5s ease;
}

@keyframes appear {
	from {
		opacity: 0;
		filter: alpha(opacity=0);
	}
	to {
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

#dragItem {
	display: flex;
	position: fixed;
	align-items: center;
	width: 60px;
	height: 40px;
	margin: 0;
	padding: 20px 10px;
	border-radius: 40px;
	background-color: rgb(255, 255, 255);
	box-shadow: 0 0 6px rgba(0, 0, 0, .5);
	cursor: move;
	transition: left 0s linear, top 0s linear, opacity .2s ease;
}
#dragItem.forbidden {
	cursor: not-allowed;
	opacity: 0.8;
	filter: alpha(opacity=80);
}
#dragItem > p {
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 18px;
	text-align: center;
	display: -webkit-box;
	width: 60px;
	max-width: 60px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.POS .keyboards {
	display: block;
	min-height: calc(100% - 21px);
	max-height: calc(100% - 21px);
	height: calc(100% - 21px);
	margin: 0;
	padding: 0;
}
.POS.format43 .keyboards {
	width: calc(100% - 203px);
}
.POS.format169 .keyboards {
	width: calc(100% - 225px);
}
.POS .keyboards.left {
	float: left;
}
.POS .keyboards.right {
	float: right;
}

.POS .keyboards .keyboard {
	position: relative;
}
.POS .keyboards .keyboard > .highlightedBg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(188, 227, 250, 0.8);
	transition: all .4s;
	visibility: hidden;
	opacity: 0;
	filter: alpha(opacity=0);
}
.POS .keyboards .keyboard > .highlightedBg.visible {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}

.POS .keyboards .middle {
	display: block;
	width: 100%;
	height: calc(100% - (var(--heightTKeyboard) + (var(--heightMSKeyboard) * 2) + 10px)); /* Adding 10, it's padding MS keyboard */
	margin: 0;
	padding: 0;
}

.POS .keyboards .keyboard.top {
	display: block;
	width: 100%;
	height: var(--heightTKeyboard);
	max-height: var(--heightTKeyboard);
	margin: 0;
	padding: 0;
}
.POS .keyboards .keyboard.top.edit {
	width: calc(100% - 2px);
	height: 26px;
	max-height: 26px;
	border: 1px solid var(--borderColorKeyboardEdit);
}
.POS .keyboards .keyboard.center {
	display: block;
	float: left;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	margin: 0;
	padding: 0;
}
.POS .keyboards .keyboard.center.column1 {
	width: calc(100% - 90px);
}
.POS .keyboards .keyboard.center.column2 {
	width: calc(100% - 180px);
}
.POS .keyboards .keyboard.center.column3 {
	width: calc(100% - 270px);
}
.POS .keyboards .keyboard.center.edit {
	height: calc(100% - 2px);
	min-height: calc(100% - 2px);
	max-height: calc(100% - 2px);
	border: 1px solid var(--borderColorKeyboardEdit);
}
.POS .keyboards .keyboard.center.column1.edit {
	width: calc(100% - 92px);
}
.POS .keyboards .keyboard.center.column2.edit {
	width: calc(100% - 182px);
}
.POS .keyboards .keyboard.center.column3.edit {
	width: calc(100% - 272px);
}
.POS .keyboards .keyboard.right {
	display: block;
	float: left;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	margin: 0;
	padding: 0;
}
.POS .keyboards .keyboard.right.column1 {
	width: 90px;
}
.POS .keyboards .keyboard.right.column2 {
	width: 180px;
}
.POS .keyboards .keyboard.right.column3 {
	width: 270px;
}
.POS .keyboards .keyboard.right.edit {
	height: calc(100% - 2px);
	min-height: calc(100% - 2px);
	max-height: calc(100% - 2px);
	border: 1px solid var(--borderColorKeyboardEdit);
}
.POS .keyboards .keyboard.right.column1.edit {
	width: 88px;
}
.POS .keyboards .keyboard.right.column2.edit {
	width: 178px;
}
.POS .keyboards .keyboard.right.column3.edit {
	width: 268px;
}
.POS .keyboards .keyboard.mainBottom,
.POS .keyboards .keyboard.secondaryBottom {
	display: block;
	width: calc(100% - 4px);
	height: var(--heightMSKeyboard);
	max-height: var(--heightMSKeyboard);
	margin: 0;
	background-color: #323232;
}
.POS .keyboards .keyboard.mainBottom {
	padding: 4px 2px 2px 2px;
}
.POS .keyboards .keyboard.secondaryBottom {
	padding: 2px 2px 4px 2px;
}
.POS .keyboards .keyboard.mainBottom.edit,
.POS .keyboards .keyboard.secondaryBottom.edit {
	width: calc(100% - 8px);
	height: 69px;
	border: 1px solid var(--borderColorKeyboardEdit);
}

.POS .keyboards .keyboard .keyboardSetting {
	display: block;
	position: absolute;
	z-index: 1;
	overflow: hidden;
	top: 0;
	right: -30px;
	width: 30px;
	height: 30px;
	margin: 0;
	padding: 0;
	background-color: rgb(100, 100, 100);
	visibility: hidden;
	cursor: pointer;
	border-radius: 0 15px 15px 0;
}
.POS .keyboards .keyboard .pasteis {
	padding: 0;
}
.POS .keyboards .keyboard .scroller:hover ~ .keyboardSetting {
	visibility: visible;
}
.POS .keyboards .keyboard .pasteis:hover ~ .keyboardSetting {
	visibility: visible;
}
.POS .keyboards .keyboard .keyboardSetting:hover {
	visibility: visible;
	background-color: rgb(60, 60, 60);
}
.POS .keyboards .keyboard .keyboardSetting svg {
	width: 14px;
	height: 14px;
	margin: 8px 0 0 8px;
	padding: 0;
}

.POS .keyboards .keyboard > .scroller {
	overflow-x: hidden;
	overflow-y: visible;
	display: block;
	max-width: 100%;
	width: 100%;
	height: 100%;
	max-height: 100%;
	margin: 0;
	padding: 0;
}

.POS .keyboards .keyboard .table {
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	transition: all .1s;
}
.POS .keyboards .keyboard .table.row {
	flex-direction: row;
}
.POS .keyboards .keyboard .table.column {
	flex-direction: column;
}
.POS .keyboards .keyboard.top .table {
	flex-wrap: nowrap;
	height: var(--heightTKeyboard);
	max-height: var(--heightTKeyboard);
}
.POS .keyboards .keyboard.right .table {
	flex-wrap: wrap;
	width: 100%;
}
.POS .keyboards .keyboard.center .table {
	flex-wrap: wrap;
	margin: 6px 6px 0 6px;
	padding: 0;
}
.POS .keyboards .keyboard.mainBottom .table,
.POS .keyboards .keyboard.secondaryBottom .table {
	flex-wrap: nowrap;
	height: var(--heightMSKeyboard);
	max-height: var(--heightMSKeyboard);
}

.POS .keyboards .keyboard .table > .key {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 0;
	background-color: #E6E6E6;
	transition: background-color .15s, filter .15s;
}
.POS .keyboards .keyboard .table.P > .key {
	margin-bottom: 6px;
}
.POS .keyboards .keyboard .table > .key.noVisible {
	background-color: transparent;
}
.POS .keyboards .keyboard .table > .key.actionnable {
	cursor: pointer;
}
.POS .keyboards .keyboard .table > .key.actionnable:hover {
	filter: brightness(90%);
}
.POS .keyboards .keyboard .table > .key.highlighted {
	filter: brightness(70%);
}
.POS .keyboards .keyboard.mainBottom .table > .key,
.POS .keyboards .keyboard.secondaryBottom .table > .key {
	border-radius: var(--keyProtoBorderRadius);
	background-color: var(--keyProtoBackgroundColor);
}

.POS .keyboards .keyboard .table > .key > .layer {
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	margin: 0;
	padding: 0;
	background-color: transparent;
	opacity: .2;
	filter: alpha(opacity=20);
}
.POS .keyboards .keyboard .table > .key > .radiusBg {
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	opacity: .2;
	filter: alpha(opacity=20);
}

/*
 * Top / Right keyboards keys
 */
.POS .keyboards .keyboard .table > .key > .wrap {
	position: absolute;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	margin: 0;
	padding: 0;
}
.POS .keyboards .keyboard .table > .key > .wrap > .icon {
	display: block;
	width: 24px;
	height: 24px;
	margin: 0 auto;
	padding: 15px 0 0 0;
}
.POS .keyboards .keyboard .table > .key > .wrap > .iconSubCategory {
	position: absolute;
	display: block;
	width: 16px;
	height: 16px;
	margin: 0;
	padding: 8px 0 0 8px;
}
.POS .keyboards .keyboard .table > .key > .wrap > .icon svg {
	width: 24px;
	height: 24px;
}
.POS .keyboards .keyboard .table > .key > .wrap > .iconSubCategory svg {
	width: 16px;
	height: 16px;
}
.POS .keyboards .keyboard .table.T .key > .wrap > .icon {
	display: none;
}
.POS .keyboards .keyboard .table.M .key > .wrap > .icon,
.POS .keyboards .keyboard .table.S .key > .wrap > .icon {
	width: 18px;
	height: 18px;
	padding: 8px 0 0 0;
}
.POS .keyboards .keyboard .table.M .key > .wrap > .icon svg,
.POS .keyboards .keyboard .table.S .key > .wrap > .icon svg {
	width: 18px;
	height: 18px;
}
.POS .keyboards .keyboard .table > .key > .wrap > .contentInfos {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: calc(100% - 20px);
	height: 100%;
	margin: 0;
	padding: 10px;
}
.POS .keyboards .keyboard .table > .key > .wrap > .contentInfos.complex {
	justify-content: space-between;
}
.POS .keyboards .keyboard .table.T > .key > .wrap > .contentInfos,
.POS .keyboards .keyboard .table.M > .key > .wrap > .contentInfos,
.POS .keyboards .keyboard .table.S > .key > .wrap > .contentInfos {
	padding: 0 10px;
}
.POS .keyboards .keyboard .table > .key > .wrap > .contentInfos > .label {
	overflow: hidden;
	display: -webkit-box;
	text-overflow: ellipsis;
	word-wrap: break-word;
	margin: 0;
	padding: 0;
	text-align: center;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.POS .keyboards .keyboard .table > .key > .wrap > .contentInfos.complex > .label {
	text-align: left;
}
.POS .keyboards .keyboard .table.M > .key > .wrap > .contentInfos > .label.withIcon,
.POS .keyboards .keyboard .table.S > .key > .wrap > .contentInfos > .label.withIcon {
	-webkit-line-clamp: 1;
}

.POS .keyboards .keyboard .table > .key > .wrap > .contentInfos > .contentPriceStock {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	padding: 0;
}
.POS .keyboards .keyboard .table > .key > .wrap > .contentInfos > .contentPriceStock > .price {
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	padding: 0;
	text-align: left;
}

/*
 * Setting / Add button
 */
.POS .keyboards .keyboard .table > .key .setting {
	display: none;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 22px;
	height: 22px;
	margin: 3px;
	padding: 0;
	background-color: rgb(100, 100, 100);
	cursor: pointer;
	border-radius: 12px;
}
.POS .keyboards .keyboard .table > .key:hover .setting {
	display: block;
}
.POS .keyboards .keyboard .table > .key .setting:hover {
	background-color: var(--darkBlueSkyTill);
}
.POS .keyboards .keyboard .table > .key .setting svg {
	display: block;
	width: 10px;
	height: 10px;
	margin: 6px auto;
}
