.main.product .wrapList .list .checkbox {
	text-align: center;
	width: 30px;
	min-width: 30px;
	max-width: 30px;
}
.main.product .wrapList .list .name {
	width: 50%;
	min-width: 150px;
	max-width: 50%;
	text-align: left;
}
.main.product .wrapList .list .catalogName {
	width: 200px;
	min-width: 200px;
	max-width: 200px;
	text-align: left;
}
.main.product .wrapList .list .catalogName > div {
	float: left;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	margin: 0;
	padding: 0 10px 0 0;
	border-width: 1px;
	border-style: solid;
	border-radius: 5px;
}
.main.product .wrapList .list .catalogName > div > .color {
	display: block;
	width: 15px;
	height: 36px;
	margin: 0;
	padding: 0;
}
.main.product .wrapList .list .catalogName > div > .text {
	display: block;
	margin: 0;
	padding: 0 0 0 10px;
	font-size: 8pt;
	line-height: 36px;
}
.main.product .wrapList .list .reference {
	min-width: 110px;
	text-align: left;
}
.main.product .wrapList .list .category {
	width: 25%;
	min-width: 150px;
	max-width: 25%;
	text-align: left;
}
.main.product .wrapList .list th.price {
	padding-left: 25px;
}
.main.product .wrapList .list th.vat {
	padding-left: 25px;
}
.main.product .wrapList .list .price {
	min-width: 80px;
	text-align: left;
	white-space: nowrap;
}
.main.product .wrapList .list .vat {
	width: 80px;
	min-width: 80px;
	max-width: 80px;
	text-align: left;
}

.main.product .wrapList .list .price > .containerPrice,
.main.product .wrapList .list .vat > .containerPrice {
	height: 40px;
	margin: 0;
	padding: 0;
}
.main.product .wrapList .list .price > .containerPrice > .forbidden,
.main.product .wrapList .list .vat > .containerPrice > .forbidden {
	display: block;
	width: 16px;
	height: 16px;
	margin: 0;
	padding: 12px 15px;
}
.main.product .wrapList .list .price > .containerPrice > .infoPrice,
.main.product .wrapList .list .vat > .containerPrice > .infoPrice {
	margin: 0;
	padding: 0 15px;
	line-height: 40px;
	font-family: Montserrat-Bold, Arial, sans-serif;
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview {
	overflow: hidden;
	position: absolute;
	height: 40px;
	margin: -40px 0 0 0;
	padding: 0;
	transition: all .15s;
	border-radius: 20px;
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	filter: alpha(opacity=0);
	background-color: white;
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview > .background,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview > .background {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;
	background-color: white;
}
.main.product .wrapList .list .price > .containerPrice > .infoPrice:hover ~ .priceOverview,
.main.product .wrapList .list .vat > .containerPrice > .infoPrice:hover ~ .priceOverview,
.main.product .wrapList .list .price > .containerPrice > .forbidden:hover ~ .priceOverview,
.main.product .wrapList .list .vat > .containerPrice > .forbidden:hover ~ .priceOverview,
.main.product .wrapList .list .price > .containerPrice > .priceOverview:hover,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview:hover,
.main.product .wrapList .list .price > .containerPrice > .priceOverview.visible,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview.visible {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview .textPrice,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .textPrice {
	margin: 0;
	padding: 0 15px;
	line-height: 40px;
	white-space: nowrap;
	font-family: Montserrat-Bold, Arial, sans-serif;
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview .forbidden,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .forbidden {
	width: 16px;
	height: 16px;
	margin: 0;
	padding: 12px 15px;
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview .edit,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .edit,
.main.product .wrapList .list .price > .containerPrice > .priceOverview .variation,
.main.product .wrapList .list .price > .containerPrice > .priceOverview .linkOpen,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .linkOpen,
.main.product .wrapList .list .price > .containerPrice > .priceOverview .open,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .open {
	margin: 2px 1px;
	padding: 0;
	width: 36px;
	height: 36px;
	background-repeat: no-repeat;
	background-position: center center;
	cursor: pointer;
	border-radius: 18px;
	transition: all .15s;
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview .linkOpen,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .linkOpen {
	margin: 0 2px 0 0;
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview .edit:hover,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .edit:hover,
.main.product .wrapList .list .price > .containerPrice > .priceOverview .variation:hover,
.main.product .wrapList .list .price > .containerPrice > .priceOverview .open:hover,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .open:hover {
	background-color: var(--lighterGraySkyTill);
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview .edit,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .edit {
	background-image: url("../../../../image/ressource/edit-priceoverview.svg");
	background-size: 15px 15px;
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview .variation {
	background-image: url("../../../../image/ressource/variation-2-priceoverview.svg");
	background-size: 15px 15px;
}
.main.product .wrapList .list .price > .containerPrice > .priceOverview .open,
.main.product .wrapList .list .vat > .containerPrice > .priceOverview .open {
	background-image: url("../../../../image/ressource/see-priceoverview.svg");
	background-size: 18px 18px;
}

.main.product .wrapList .list .price > .containerPrice > .priceOverview input {
	width: 80px;
	margin: 0;
	padding: 0 15px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: transparent;
	border: none;
	outline: none;
	font-size: 9pt;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
}
.main.product .wrapList .list .vat > .containerPrice > .priceOverview select {
	width: 90px;
	margin: 0;
	padding: 0 45px 0 15px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: transparent;
	border: none;
	outline: none;
	font-size: 9pt;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
	background-position: right 15px center;
	background-size: 14px 14px;
	background-repeat:  no-repeat;
	background-image: url(../../../../image/ressource/down-arrow.svg);
}

@media (max-width: 720px) {
	.main.product .wrapList .list .reference {
		display: none;
	}
}
@media (max-width: 940px) {
	.main.product .wrapList .list .category {
		display: none;
	}
}
@media (max-width: 1100px) {
	.main.product .wrapList .list .catalogName {
		display: none;
	}
}
@media (max-width: 1250px) {
	.main.product .wrapList .list .vat,
	.main.product .wrapList .list .updated_at {
		display: none;
	}
}