.main.closings .empty {
	display: block;
	width: 100px;
	height: 100px;
	margin: calc(50vh - 173px) auto 0 auto;
}

.main.closings .emptyLabel {
	display: block;
	margin: 50px 0 0 0;
	text-align: center;
	font-size: 14pt;
	color: var(--darkGraySkyTill);
}

.main.closings .wrapPeriods {
	display: block;
	margin: 15px 15px 0 15px;
	padding: 0;
	border-radius: 5px;
}
.main.closings .wrapPeriods:first-child {
	margin-top: 0;
}

.main.closings .wrapPeriods .line {
	display: block;
	margin: 15px 0 0 0;
	padding: 0;
	border-radius: 5px;
}
.main.closings .wrapPeriods .line:not(.unavailable).first {
	background-color: var(--lighterGraySkyTill);
}
.main.closings .wrapPeriods .line:not(.unavailable).second {
	background-color: var(--light2GraySkyTill);
}
.main.closings .wrapPeriods .line:not(.unavailable).third {
	background-color: var(--lightGraySkyTill);
}
.main.closings .wrapPeriods .line.unavailable {
	background-color: var(--darkGraySkyTill);
	opacity: 0.5;
	filter: alpha(opacity=50);
}
.main.closings .wrapPeriods .line > table {
	border-collapse: collapse;
	width: 100%;
	height: 45px;
	border-radius: 5px;
}
.main.closings .wrapPeriods .line.first > table {
	background-color: var(--darkerBlueSkyTill);
}
.main.closings .wrapPeriods .line.second > table {
	background-color: var(--darkBlueSkyTill);
}
.main.closings .wrapPeriods .line.third > table {
	background-color: var(--darkLessBlueSkyTill);
}
.main.closings .wrapPeriods .line.first.unavailable > table,
.main.closings .wrapPeriods .line.second.unavailable > table,
.main.closings .wrapPeriods .line.third.unavailable > table {
	background-color: transparent;
}
.main.closings .wrapPeriods .line > table td {
	padding: 0 15px;
	font-size: 11pt;
	color: var(--whiteSkyTill);
}
.main.closings .wrapPeriods .line > table td.title {
	text-transform: capitalize;
}
.main.closings .wrapPeriods .line > table td.number,
.main.closings .wrapPeriods .line > table td.nbSales,
.main.closings .wrapPeriods .line > table td.amountTax,
.main.closings .wrapPeriods .line > table td.amountInclTax {
	color: var(--whiteSkyTill);
	width: 200px;
	max-width: 200px;
	text-align: left;
}
.main.closings .wrapPeriods .line > table td.number {
	width: 120px;
	max-width: 120px;
}
.main.closings .wrapPeriods .line > table td > span {
	color: var(--lighterGraySkyTill);
	font-size: 9pt;
	padding: 0 8px;
}
.main.closings .wrapPeriods .line > table td.download,
.main.closings .wrapPeriods .line > table td.show {
	width: 60px;
	max-width: 60px;
}
.main.closings .wrapPeriods .line > table td > svg {
	float: right;
	width: 20px;
	height: 20px;
	transition: all .15s;
	cursor: pointer;
}
.main.closings .wrapPeriods .line > table td > svg.clockIco {
	cursor: auto;
}
.main.closings .wrapPeriods .line > table td > svg.downloadIco:hover,
.main.closings .wrapPeriods .line > table td > svg.showIco:hover {
	fill: var(--lighterGraySkyTill);
}
.main.closings .wrapPeriods .line > .subs {
	overflow: hidden;
	display: block;
	height: auto;
	max-height: none;
	margin: 0;
	padding: 10px 15px;
	border-radius: 5px;
	transition: all .15s;
}
.main.closings .wrapPeriods .line > .subs.hidden {
	height: 0;
	max-height: 0;
	padding-top: 0;
	padding-bottom: 0;
}