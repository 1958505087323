.POS .statusBar {
    display: block;
    min-height: 21px;
    max-height: 21px;
	height: 21px;
    margin: 0;
    padding: 0;
}
.POS.format43 .statusBar {
	width: calc(100% - 203px);
}
.POS.format169 .statusBar {
	width: calc(100% - 225px);
}
.POS .statusBar.left {
	float: left;
}
.POS .statusBar.right {
	float: right;
}

.POS .statusBar > .date {
    display: inline-block;
    margin: 0;
    padding: 0 5px 0 10px;
    font-size: 7pt;
    line-height: 21px;
}
.POS .statusBar > .date.left {
	float: left;
}
.POS .statusBar > .date.right {
	float: right;
}

.POS .statusBar > .timer {
	display: flex;
	margin: 0;
	padding: 0;
}
.POS .statusBar > .timer.left {
	float: left;
}
.POS .statusBar > .timer.right {
	float: right;
}
.POS .statusBar > .timer > .number {
    display: block;
	margin: 0;
    padding: 0 0.5px;
    font-size: 7pt;
    line-height: 21px;
}
.POS .statusBar > .timer > .point {
    display: block;
    margin: 0;
    padding: 0 1.5px;
    font-size: 7pt;
    line-height: 20px;
    visibility: hidden;
}
.POS .statusBar > .timer > .point.visible {
    visibility: visible;
}
