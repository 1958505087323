:root {
	--vh: 1vh;
	--blueSkyTill: #00A8E8;
	--lightBlueSkyTill: #BCE3FA;
	--lighterBlueSkyTill: #DDF1FD;
	--lightBlueAlphaSkyTill: rgba(188, 227, 250, 0.3);
	--darkerBlueSkyTill: #00355B;
	--darkBlueSkyTill: #00406F;
	--darkLessBlueSkyTill: #0065AC;
	--darkGraySkyTill: #9C9C9C;
	--darkerMoreGraySkyTill: #464646;
	--darkerGraySkyTill: #646464;
	--darkerLessGraySkyTill: #727272;
	--lightGraySkyTill: #DADADA;
	--light2GraySkyTill: #EAEAEA;
	--lightLessGraySkyTill: #CACACA;
	--lightGrayAlphaSkyTill: rgba(218, 218, 218, 0.4);
	--lighterGraySkyTill: #F5F5F5;
	--whiteSkyTill: #FFFFFF;
	--whiteAlphaSkyTill: rgba(255, 255, 255, 0.5);
	--whityyySkyTill: #E8E8E8;
	--whitySkyTill: #D7D7D7;
	--whiteHoverSkyTill: rgba(255, 255, 255, 0.1);
	--whiteMenuMobile: rgba(255, 255, 255, 0.9);
	--blackSkyTill: #000000;
	--lightBlackSkyTill: #1E1E1E;
	--lighterBlackSkyTill: #323232;
	--greenSkyTill: #00A26B;
	--greenHoverSkyTill: #17C288;
	--redSkyTill: #EB4343;
	--redDarkSkyTill: #930808;
	--redHoverSkyTill: #EB6D6D;
	--yellowSkyTill: #FFFBCB;
	--yellowHoverSkyTill: #FFFFF0;
	--redAlphaSkyTill: rgba(235, 67, 67, 0.1);
	--orangeSkyTill: #FF9F0B;
	--orangeLightSkyTill: #FFE8BE;
	--orangeHoverSkyTill: #C59B4C;
	--purpleSkyTill: #790021;
	
	--mainColor: var(--darkBlueSkyTill);
	--mainColorHover: var(--blueSkyTill);
	--mainColorAlpha: var(--lightBlueSkyTill);
	--mainColorAlphaHover: var(--lightBlueAlphaSkyTill);
	--fontSizeGeneralText: 8pt;
	--fontSizeInputText: 9pt;
	--borderRadiusInputText: 10px;
	--paddingInputText: 0 10px;
	--backgroundColor: var(--whiteSkyTill);
	--backgroundColorDefaultPricelist: var(--blueSkyTill);
	--boxColor: var(--lightGraySkyTill);
	--boxColorHover: var(--darkGraySkyTill);
	--logo: url(../image/logo/skytill-logo-color.png);
	--logo-central: url(../image/logo/skytill-logo-central-color.png);
	--textColor: var(--blackSkyTill);
	--textColorSecondary: var(--darkGraySkyTill);
	--textColorList: var(--lightBlackSkyTill);
	--errorColor: var(--redSkyTill);
	--errorHoverColor: var(--redHoverSkyTill);
	--warningColor: var(--orangeSkyTill);
	--validColor: var(--blueSkyTill);
	
	--heightHeader: 50px;
	--backgroundColorHeader: var(--lighterGraySkyTill);

	--backgroundColorOptions: var(--backgroundColor);
	--borderColorOptions: 1px solid var(--lightGraySkyTill);
	--boxShadowOptions: 0 3px 6px rgba(0, 0, 0, 0.2);
	--textColorOptionItems: var(--textColor);
	--backgroundColorOptionItemsHover: var(--boxColor);
	--textColorOptionItemsRed: var(--redSkyTill);
	--backgroundColorOptionItemsRed: var(--redAlphaSkyTill);

	--widthMenu: 250px;
	--widthMenuReduced: 85px;
	--backgroundColorMenu: var(--boxColor);
	--borderMenu: 1px solid var(--lightGraySkyTill);
	--borderItemMenuSelected: 3px solid var(--mainColor);

	--textColorSubMenuNavItem: var(--textColor);
	--backgroundColorSubMenuNavItemHover: var(--lightGraySkyTill);

	--backgroundColorUser: var(--mainColor);
	--backgroundColorUserHover: var(--backgroundColorSubmitHover);
	--boxShadowUser: 0 0 3px 1px rgba(0, 0, 0, 0.2);

	--backgroundColorInput: var(--whiteSkyTill);
	--backgroundColorInputHover: var(--whiteSkyTill);
	--textColorInput: var(--textColorList);
	--borderInput: 1px solid var(--lightGraySkyTill);
	--borderColorInputHover: var(--lightBlueSkyTill);
	--borderColorInputText: var(--lightBlueAlphaSkyTill);
	--borderColorInputTextFocus: var(--darkLessBlueSkyTill);
	--backgroundColorNoInputHover: var(--lightBlueAlphaSkyTill);

	--textColorButtonAccess: var(--blackSkyTill);
	--borderButtonAccess: 1px solid var(--mainColorAlpha);
	--borderButtonAccessHover: 1px solid var(--mainColor);

	--backgroundColorBarStrengthLow: var(--errorColor);
	--backgroundColorBarStrengthMid: var(--warningColor);
	--backgroundColorBarStrengthStrong: var(--validColor);

	--backgroundColorListChoices: var(--backgroundColorInput);
	--backgroundColorListChoicesChoiceHover: var(--backgroundColorInputHover);
	--borderBottomListChoicesChoice: 1px solid var(--backgroundColor);

	--backgroundColorSubmit: var(--mainColor);
	--backgroundColorSubmitHover: var(--mainColorHover);
	--textColorSubmit: white;

	--backgroundColorDelete: var(--redSkyTill);
	--backgroundColorDeleteHover: var(--redHoverSkyTill);
	--textColorDelete: var(--whiteSkyTill);

	--textColorLabel: var(--darkGraySkyTill);
	--textColorLabelCheckbox: var(--blackSkyTill);

	--borderTable: 1px solid var(--lightGraySkyTill);
	--backgroundColorTRHover: var(--whiteHoverSkyTill);

	--borderTabsBar: 1px solid var(--lightGraySkyTill);
	--borderTabsBarTab: 2px solid var(--mainColor);
	--borderTabsBarTabTransparent: 2px solid transparent;

	--backgroundColorBubbleInformation: var(--mainColor);
	--textColorBubbleInformation: var(--whiteSkyTill);

	--backgroundTrLineButton: var(--mainColor);
	--backgroundTrLineButtonHover: var(--backgroundColorSubmitHover);

	--borderColorKeyboardEdit: var(--mainColor);

	--backgroundColorOptionsScreen: var(--backgroundColorHeader);
	--colorShadowOptionsScreen: var(--darkGraySkyTill);

	--colorTextInputPlaceholder: var(--darkGraySkyTill);

	--backgroundColorInputLibrary: rgb(127, 127, 127);
	--borderInputLibrary: 1px solid var(--mainColorHover);
	--borderRadiusInputLibrary: 0 0 3px 3px;
	--backgroundColorInputLibraryItem: var(--lightGraySkyTill);
	
	--borderColorWrapList: var(--lightGraySkyTill);
	--borderSearchBar: 1px solid var(--borderColorWrapList);
	
	--keyProtoBackgroundColor: var(--darkGraySkyTill);
	--keyProtoTextColor: var(--whiteSkyTill);
	--keyProtoBorderRadius: 3px;
	--keyProtoMargin: 0 6px 0 0;
	--keyProtoPadding: 0;
	
	--keyPasteisTextColor: var(--blackSkyTill);
	--keyPasteisBorderRadius: 0;
	--keyPasteisMargin: 6px 0 0 6px;
	--keyPasteisPadding: 0;
	
	--heightTKeyboard: 38px;
	--heightMSKeyboard: 66px;
}
