.toolsBarStatistics {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	margin: 0;
	padding: 15px;
}
.toolsBarStatistics > .left {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0;
	padding: 0;
}
.toolsBarStatistics > .right {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-end;
	margin: 0;
	padding: 0;
}
.toolsBarStatistics > .right > .loader {
	display: block;
	width: 22px;
	height: 22px;
	margin: 9px 0 0 0;
	padding: 0;
}
.toolsBarStatistics > .right > .loader.hide {
	display: none;
}

@media only screen and (max-width: 1199px) {
}
@media only screen and (max-width: 801px) {
	.toolsBarStatistics {
		flex-direction: column;
		padding: 15px 15px 0 15px;
	}
	
	.toolsBarStatistics > .left {
		margin: 0 auto;
	}
	
	.toolsBarStatistics > .right {
		margin: 15px auto 0 auto;
	}
}
@media print {
	.toolsBarStatistics {
		display: none;
	}
}