.wrapCompany {
	margin: 0;
}

.wrapCompany .company {
	width: calc(100% - 50px);
	height: 40px;
	margin: 0 25px 20px 25px;
	padding: 20px 0 0 0;
	border-bottom: 1px solid rgb(230, 230, 230);
}
.wrapCompany .company .title {
	display: block;
	float: left;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	padding: 0;
	font-size: 16pt;
	font-weight: bold;
	color: #262962;
}

@media (max-width: 640px) {
	.wrapCompany {
		padding: 10px 0 0 0;
	}
	
	.wrapCompany .stores {
		display: block;
		margin: 30px 25px 0 25px;
		padding: 0 0 20px 0;
	}
	.wrapCompany .stores .empty {
		margin: 0;
		padding: 15px 0;
		font-size: var(--fontSizeGeneralText);
		color: rgb(100, 100, 100);
	}
}
@media (min-width: 641px) {
	.wrapCompany {
		padding: 10px 0 0 0;
	}
	
	.wrapCompany .stores {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		gap: 15px;
		width: calc(100% - 50px);
		margin: 0 25px 15px 25px;
		padding: 0 0 20px 0;
	}
	.wrapCompany .stores .empty {
		margin: 0;
		padding: 15px 0;
		font-size: var(--fontSizeGeneralText);
		color: rgb(100, 100, 100);
	}
}
