.main.brand .wrapList .list .checkbox {
	text-align: center;
	width: 30px;
	min-width: 30px;
	max-width: 30px;
}
.main.brand .wrapList .list .name {
	width: 100%;
	min-width: 150px;
	max-width: 100%;
	text-align: left;
}
.main.brand .wrapList .list .reference {
	width: 110px;
	min-width: 110px;
	max-width: 110px;
	text-align: left;
}
.main.brand .wrapList .list .updated_at {
	width: 180px;
	min-width: 180px;
	max-width: 180px;
	color: var(--darkGraySkyTill);
}
.main.brand .wrapList .list .updated_at b {
	color: var(--textColorList);
	word-wrap: break-word;
}

@media (max-width: 720px) {
	.main.brand .wrapList .list .reference {
		display: none;
	}
}
@media (max-width: 1100px) {
	.main.brand .wrapList .list .updated_at {
		display: none;
	}
}
