@media only screen and (max-width: 600px) {
	.wrapInit {
		width: 100%;
		max-width: 100%;
		height: 100%;
		min-height: 100vh;
		max-height: 100%;
		margin: 0;
		padding: 0;
	}
	.wrapInit .boxInit {
		width: 170px;
		height: 120px;
		margin: 0 auto;
		padding: 50px 0 0 0;
		background-color: var(--whiteSkyTill);
	}
	.wrapInit .box {
		width: calc(100% - 30px);
		height: calc(100vh - 30px);
		margin: 0;
		padding: 15px;
		background-color: var(--whiteSkyTill);
	}
}
@media only screen and (min-width: 601px) {
	.wrapInit {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 100%;
		height: 100%;
		min-height: 100vh;
		max-height: 100%;
		margin: 0;
		background-color: var(--lightGraySkyTill);
	}
	.wrapInit .boxInit {
		width: 170px;
		height: 120px;
		margin: 0 auto;
		padding: 50px 0 0 0;
		background-color: var(--whiteSkyTill);
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
		border-radius: 3px;
	}
	.wrapInit .box {
		width: 500px;
		margin: 0 auto;
		padding: 15px;
		background-color: var(--whiteSkyTill);
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
		border-radius: 3px;
	}
}

.wrapInit .box .waitingText {
	margin: 0;
	padding: 25px 15px 0 15px;
}
.wrapInit .box .waitingText .sentence {
	font-size: 17pt;
	line-height: 34px;
	text-align: center;
	font-style: italic;
	color: var(--darkGraySkyTill);
}
.wrapInit .box .waitingText .sentence:before {
	content: "« ";
	font-size: 24pt;
}
.wrapInit .box .waitingText .sentence:after {
	content: " »";
	font-size: 24pt;
}
.wrapInit .box .waitingText .author {
	padding-right: 15px;
	font-size: 14pt;
	text-align: right;
	color: rgb(70, 70, 70);
}
