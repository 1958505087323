.wrapStat {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: calc(100% - 32px);
	margin: 15px auto 0 auto;
	padding: 0;
	border: 1px solid var(--lighterGraySkyTill);
	border-radius: 10px;
}

.wrapStat > .chartPart {
	width: calc(35% - 10px);
	margin: 0;
	padding: 0;
}
.wrapStat > .chartPart > .title {
	text-align: left;
	margin: 0;
	padding: 0 0 0 15px;
	font-size: 11pt;
	line-height: 40px;
}
.wrapStat > .chartPart > .containerChart {
	width: 100%;
	max-width: 100%;
	max-height: 250px;
	margin: 30px auto;
}
.wrapStat > .chartPart > .containerChart > .chart {
	width: calc(100% - 30px);
	max-width: calc(100% - 30px);
	min-height: 250px;
	margin: 0 auto;
}

.wrapStat > .tablePart {
	width: calc(65% - 11px);
	margin: 0;
	padding: 0;
	border-left: 1px solid var(--lighterGraySkyTill);
}
.wrapStat > .tablePart > .table {
	display: table;
	table-layout: fixed;
	width: 100%;
	margin: 0;
	padding: 0;
	border-collapse: collapse;
}
.wrapStat > .tablePart > .table > tbody > tr {
	height: 55px;
	border-bottom: 1px solid var(--lighterGraySkyTill);
	background-color: transparent;
	transition: all 0.3s;
}
.wrapStat > .tablePart > .table > tbody > tr.header {
	height: 40px;
}
.wrapStat > .tablePart > .table > tbody > tr:not(.header):not(.total):hover {
	background-color: #FAFAFA;
}
.wrapStat > .tablePart > .table > tbody > tr > th {
	padding: 0 10px;
	text-transform: uppercase;
	font-size: 8pt;
	color: var(--darkGraySkyTill);
	background-color: var(--lighterGraySkyTill);
}
.wrapStat > .tablePart > .table > tbody > tr > td {
	padding: 0 10px;
	font-size: 10pt;
}
.wrapStat > .tablePart > .table > tbody > tr > td > .value {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	margin: 0;
	padding: 0;
}
.wrapStat > .tablePart > .table > tbody > tr > td > .value > .arrow {
	width: 14px;
	height: 14px;
	padding: 2px 5px 2px 0;
	display: none;
}
.wrapStat > .tablePart > .table > tbody > tr > td > .value > .arrow.down {
	transform: rotate(180deg);
	fill: var(--redSkyTill);
	display: block;
}
.wrapStat > .tablePart > .table > tbody > tr > td > .value > .arrow.up {
	fill: var(--greenSkyTill);
	display: block;
}
.wrapStat > .tablePart > .table > tbody > tr > td > .value > span.value {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 10pt;
	line-height: 19px;
	color: var(--lightBlackSkyTill);
}
.wrapStat > .tablePart > .table > tbody > tr > td > .value > span.deltaPercent {
	display: block;
	margin: 0 5px 0 0;
	padding: 0 5px;
	font-size: 8pt;
	line-height: 19px;
	color: var(--whiteSkyTill);
	background-color: var(--darkGraySkyTill);
	border-radius: 5px;
}
.wrapStat > .tablePart > .table > tbody > tr > td > .value > span.deltaPercent.more {
	background-color: var(--greenSkyTill);
}
.wrapStat > .tablePart > .table > tbody > tr > td > .value > span.deltaPercent.less {
	background-color: var(--redSkyTill);
}

.wrapStat > .tablePart > .table > tbody > tr > td > p {
	margin: 0;
	padding: 0;
}
.wrapStat > .tablePart > .table > tbody > tr.total > td {
	background-color: var(--lightBlueAlphaSkyTill);
}
.wrapStat > .tablePart > .table > tbody > tr > .name {
	text-align: left;
}
.wrapStat > .tablePart > .table > tbody > tr > .value {
	text-align: right;
}
.wrapStat > .tablePart> .table > tbody > tr > .empty {
	text-align: center;
	color: var(--darkGraySkyTill);
}

@media only screen and (max-width: 1199px) {
	.wrapStat {
		flex-direction: column;
	}
	.wrapStat > .chartPart,
	.wrapStat > .tablePart {
		width: 100%;
	}
}
@media only screen and (max-width: 640px) {
	.wrapStat > .chartPart > .containerChart {
		display: none;
	}
	
	.wrapStat > .tablePart > .table > tbody > tr > td > .value > span.deltaPercent {
		display: none;
	}
}