.storeItem {
	min-height: 100px;
	margin: 15px 0 0 0;
	padding: 15px;
	border-radius: 10px;
	background-color: var(--lighterGraySkyTill);
}
.storeItem:hover {
	filter: brightness(98%);
}
.storeItem:hover > .picture {
	border-color: var(--darkBlueSkyTill);
}

.storeItem > .picture {
	position: absolute;
	display: block;
	width: 56px;
	height: 56px;
	margin: -35px 0 0 0;
	padding: 10px;
	border: 2px solid rgb(200, 200, 200);
	border-radius: 40px;
	text-transform: uppercase;
	background-color: white;
	transition: all .15s;
}
.storeItem > .title {
	width: calc(100% - 110px);
	max-width: calc(100% - 110px);
	margin: 0 0 0 95px;
	padding: 0 0 15px 0;
	font-size: 12pt;
	font-weight: bold;
	text-align: left;
	color: var(--textColor);
	text-transform: uppercase;
	border-bottom: 2px solid var(--lightGraySkyTill);
	transition: all .15s;
}
.storeItem > .address {
	margin: 0;
	padding: 25px 15px 15px 50px;
	text-align: left;
	font-size: 11pt;
	line-height: 18px;
	color: var(--darkGraySkyTill);
	background-position: left 15px top 32px;
	background-repeat: no-repeat;
	background-size: 20px 20px;
	background-image: url("../../../../image/ressource/pin-dark-gray.svg");
}

.storeItem a {
	color: inherit;
	text-decoration: inherit;
}

.storeItem .button {
	display: block;
	overflow: hidden;
	width: 100%;
	height: 50px;
	max-height: 50px;
	margin: 0;
	padding: 0;
	line-height: 50px;
	border-radius: 5px;
	border: none;
	outline: none;
	font-size: 9pt;
	font-family: inherit;
	background-position: left 10px center;
	background-repeat: no-repeat;
	background-size: 20px;
	cursor: pointer;
	transition: all 0.15s;
}
.storeItem .button.left {
	float: left;
	width: calc(50% - 5px);
}
.storeItem .button.right {
	float: right;
	width: calc(50% - 5px);
}
.storeItem .button.alone {
	margin-top: 80px;
}
.storeItem .button.marginTop {
	margin-top: 20px;
}
.storeItem .button.marginBottom {
	margin-bottom: 10px;
}
.storeItem .button.access {
	color: var(--darkBlueSkyTill);
	background-color: var(--lightBlueAlphaSkyTill);
	background-image: url("../../../../image/ressource/access-dark-blue.svg");
}
.storeItem .button.information,
.storeItem .button.account {
	color: var(--darkerGraySkyTill);
	background-color: var(--light2GraySkyTill);
}
.storeItem .button.information {
	background-image: url("../../../../image/ressource/information-dark-gray.svg");
}
.storeItem .button.account {
	background-image: url("../../../../image/ressource/account-dark-gray.svg");
}
.storeItem .button.access:hover {
	background-color: var(--lightBlueSkyTill);
}
.storeItem .button.information:hover,
.storeItem .button.account:hover {
	background-color: var(--lightGraySkyTill);
}

@media (max-width: 640px) {
	.storeItem {
		margin-top: 30px;
	}
}
@media (max-width: 1090px) {
	.storeItem {
		width: calc(100% - 30px);
	}
	.storeItem > .picture {
		width: 46px;
		height: 46px;
		margin: -30px 0 0 0;
		padding: 10px;
		border: 2px solid rgb(200, 200, 200);
		border-radius: 35px;
		text-transform: uppercase;
		background-color: white;
		transition: all .15s;
	}
	.storeItem > .title {
		margin: 0 0 0 90px;
		padding: 0;
		font-size: 12pt;
		font-weight: bold;
		text-align: left;
		color: var(--darkerGraySkyTill);
		text-transform: uppercase;
		transition: all .15s;
	}
}
@media (min-width: 1091px) {
	.storeItem {
		width: calc((100% / 2) - 40px);
	}
}
@media (min-width: 1700px) {
	.storeItem {
		width: calc((100% / 3) - 40px);
	}
}
