.smallListTags {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 4px 0 0 0;
	padding: 0;
	cursor: pointer;
}
.smallListTags > .containerTag {
	display: block;
	width: 29px;
	height: 29px;
	margin: 0 0 0 -15px;
	padding: 0;
	border-radius: 15px;
	background-color: white;
}
.smallListTags > .containerTag:first-child {
	margin-left: 5px;
}
.smallListTags > .containerTag > .tag {
	display: block;
	width: 29px;
	height: 29px;
	margin: 0;
	padding: 0;
	border-radius: 15px;
}
.smallListTags > .containerTag > .tag > .icon {
	width: 14px;
	height: 14px;
	margin: 0;
	padding: 7px 0 0 8px;
}
.smallListTags > .containerTag > .tag > .icon svg {
	width: 14px;
	height: 14px;
	display: block;
	clear: both;
	margin: 0;
	padding: 0;
	cursor: pointer;
}

.previewListTags {
	overflow: hidden;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	max-width: 300px;
	margin: 0;
	padding: 10px 0 0 10px;
	border-radius: 10px;
	background-color: var(--whiteSkyTill);
	opacity: 0;
	filter: alpha(opacity=0);
	transition: opacity .2s, filter .2s;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}
.previewListTags.visible {
	opacity: 1;
	filter: alpha(opacity=100);
}
.previewListTags > .list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0;
	padding: 0;
}
.previewListTags > .list > .tag {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow: hidden;
	margin: 0 10px 10px 0;
	padding: 0 12px;
	border-radius: 15px;
	transition: all .15s;
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.previewListTags > .list > .tag > .icon {
	width: 14px;
	height: 14px;
	margin: 7px 0 0 0;
	padding: 0;
}
.previewListTags > .list > .tag > .icon svg {
	width: 14px;
	height: 14px;
	display: block;
	clear: both;
	margin: 0;
	padding: 0;
	cursor: auto;
}
.previewListTags > .list > .tag > p {
	margin: 0;
	padding: 0 0 0 12px;
	font-size: 8pt;
	line-height: 30px;
}