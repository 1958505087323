.warning {
    display: block;
    fill: #DDDDDD;
    width: 150px;
    height: 150px;
    margin: 50px auto;
    padding: 0;
}

.titleWarning {
    display: block;
    width: 70%;
    margin: 0 auto;
    padding: 0;
    font-size: 24pt;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
    color: #555555;
}
