.catalog {
    display: block;
	margin: 20px 0 25px 0;
	padding: 0;
}
.catalog > .name {
	margin: 0;
	padding: 0 0 10px 0;
    text-align: left;
	text-transform: uppercase;
	font-size: 9pt;
	font-weight: bold;
}
.catalog > .update {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 0;
	padding: 0;
}
.catalog > .update > .name {
	width: 120px;
	margin: 0;
	padding: 0;
	text-align: right;
	font-size: 9pt;
	line-height: 30px;
}
.catalog > .update > .description {
	width: calc(100% - 135px);
	margin: 0;
	padding: 0 0 0 15px;
	text-align: left;
	font-size: 9pt;
	line-height: 30px;
	color: var(--darkerGraySkyTill);
}

.catalog > .button {
	display: block;
	overflow: hidden;
	float: right;
	width: auto;
	height: 40px;
	max-height: 40px;
	margin: 15px 0 0 15px;
	padding: 0 10px 0 35px;
	line-height: 40px;
	border-radius: 5px 0 0 5px;
	border: none;
	outline: none;
	font-size: var(--fontSizeGeneralText);
	font-family: inherit;
	background-position: left 10px center;
	background-repeat: no-repeat;
	background-size: 14px;
	cursor: pointer;
	transition: all 0.15s;
	color: var(--darkBlueSkyTill);
	background-color: var(--lightBlueAlphaSkyTill);
}
.catalog > .button.now {
	background-image: url("../../image/ressource/deploy-dark-blue.svg");
}
.catalog > .button.later {
	background-image: url("../../image/ressource/deploy-later-dark-blue.svg");
}
.catalog > .button:hover {
	background-color: var(--lightBlueSkyTill);
}
.catalog > .button > span {
	margin: 0;
	padding: 0;
}

.catalog > .loader {
	display: block;
	overflow: hidden;
	float: right;
	width: auto;
	height: 40px;
	max-height: 40px;
	margin: 15px 0 0 15px;
	padding: 0 15px;
	line-height: 40px;
	border-radius: 5px 0 0 5px;
	border: none;
	outline: none;
	font-size: var(--fontSizeGeneralText);
	font-family: inherit;
	background-position: left 10px center;
	background-repeat: no-repeat;
	background-size: 12px;
	cursor: pointer;
	transition: all 0.15s;
	color: var(--darkBlueSkyTill);
	background-color: var(--lightBlueAlphaSkyTill);
}
.catalog > .loader > svg {
	fill: var(--darkBlueSkyTill);
}

.catalog > .calendar {
	display: block;
	float: right;
	width: 45px;
	height: 40px;
	margin: 15px 0 0 0;
	border-radius: 0;
	border-left: 1px solid var(--lighterBlueSkyTill);
	cursor: pointer;
	transition: all 0.15s;
	background-position: center center;
	background-size: 12px;
	background-repeat: no-repeat;
	color: var(--darkBlueSkyTill);
	background-color: var(--lightBlueAlphaSkyTill);
	background-image: url("../../image/ressource/calendar-dark-blue.svg");
}
.catalog > .calendar:hover {
	background-color: var(--lightBlueSkyTill);
}

.catalog > .optionButton {
	display: block;
	float: right;
	width: 45px;
	height: 40px;
	margin: 15px 0 0 0;
	border-radius: 0 5px 5px 0;
	border-left: 1px solid var(--lighterBlueSkyTill);
	cursor: pointer;
	transition: all 0.15s;
	background-position: center center;
	background-size: 12px;
	background-repeat: no-repeat;
	color: var(--darkBlueSkyTill);
	background-color: var(--lightBlueAlphaSkyTill);
	background-image: url("../../image/ressource/down-arrow-dark-blue.svg");
}
.catalog > .optionButton:hover {
	background-color: var(--lightBlueSkyTill);
}