.main.client > .searchBar {
	display: block;
	width: calc(100% - 52px);
    height: 37px;
	margin: 20px 15px;
	font-family: inherit;
    padding: var(--paddingInputText);
    font-size: var(--fontSizeInputText);
    color: var(--textColorInput);
    border-radius: var(--borderRadiusInputText);
    border: var(--borderSearchBar);
    background-color: var(--backgroundColorInput);
	transition: all 0.15s;
}
.main.client > .searchBar:hover {
    background-color: var(--backgroundColorInputHover);
}
.main.client > .searchBar:focus {
    outline: none;
	border-color: rgba(0, 40, 122, 0.98);
}

.main.client > .wrapList > .list th.id {
	text-align: center;
}
.main.client > .wrapList > .list th.name {
	text-align: left;
}
.main.client > .wrapList > .list th.reference {
	text-align: left;
}
.main.client > .wrapList > .list th.category {
	text-align: left;
}

.overbox > .clearing > .navigation > .form > .defaultStock {
	display: block;
	float: left;
	max-width: calc(50% - 35px);
	margin: 0 10px 0 5px;
	padding: 0 10px;
	font-size: 11pt;
	font-weight: bold;
	text-align: left;
	line-height: 26px;
	transition: all 0.15s;
}
.overbox > .clearing > .navigation > .form > .defaultStock > span {
	color: #777777;
	font-size: var(--fontSizeGeneralText);
	font-weight: normal;
}

.overbox > .clearing > .navigation > .form > .defaultPrice {
	display: block;
	float: right;
	max-width: calc(50% - 35px);
	margin: 0 5px 0 10px;
	padding: 0 10px;
	font-size: 11pt;
	font-weight: bold;
	text-align: right;
	line-height: 26px;
}
