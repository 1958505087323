.main.display .wrapList .list .checkbox {
	text-align: center;
	width: 30px;
	min-width: 30px;
	max-width: 30px;
}
.main.display .wrapList .list .name {
	width: 100%;
	min-width: 150px;
	max-width: 100%;
	text-align: left;
}
.main.display .wrapList .list .model {
	width: 110px;
	min-width: 110px;
	max-width: 110px;
	text-align: left;
}
.main.display .wrapList .list .interface {
	width: 110px;
	min-width: 110px;
	max-width: 110px;
	text-align: left;
	text-transform: uppercase;
}

@media (max-width: 445px) {
	.main.display .wrapList .list .model {
		display: none;
	}
}
@media (max-width: 770px) {
	.main.display .wrapList .list .interface {
		display: none;
	}
}
@media (max-width: 1100px) {
	.main.display .wrapList .list .updated_at {
		display: none;
	}
}
