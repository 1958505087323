@media only screen and (max-width: 600px) {
	.form.formLogin {
		display: block;
		width: calc(100% - 30px);
		margin: 0 auto;
	}
	.form.formLogin .logo {
		display: block;
		width: 160px;
		margin: 30px auto;
	}
}
@media only screen and (min-width: 601px) {
	.form.formLogin {
		display: block;
		width: calc(100% - 100px);
		margin: 0 auto;
	}
	.form.formLogin .logo {
		display: block;
		width: 170px;
		margin: 30px auto 50px auto;
	}
}

.form.formLogin .explain {
	margin-bottom: 15px;
	font-size: 13px;
	text-align: left;
	color: rgb(100, 100, 100);
}

.form.formLogin button {
	margin-bottom: 30px;
}
