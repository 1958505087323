.Header {
	display: block;
	position: fixed;
	z-index: 5;
	width: calc(100% - var(--widthMenu));
	height: var(--heightHeader);
	left: 0;
	top: 0;
	margin: 0 0 0 var(--widthMenu);
	padding: 0;
	background-color: var(--backgroundColorHeader);
}

.Header > .iconMenuMobile {
	display: none;
	float: left;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 15px;
	fill: var(--lightBlackSkyTill);
	transition: all 0.15s;
}
.Header > .iconMenuMobile:hover {
	fill: var(--darkBlueSkyTill);
}

.Header .wrapAccess .company > .name,
.Header .wrapAccess .store > .name {
	font-size: 10pt;
}

.Header .wrapAccess {
	float: left;
	display: flex;
}
.Header .wrapAccess .iconEmpire {
	display: none;
	width: 22px;
	height: 22px;
	margin: 14px 10px;
	cursor: pointer;
	transition: all .15s;
	fill: var(--blackSkyTill);
}
.Header .wrapAccess .iconEmpire:hover {
	fill: var(--darkerGraySkyTill);
}
.Header .wrapAccess .loaderSwitchEmpire {
	display: none;
	width: 22px;
	height: 22px;
	margin: 14px 10px;
	cursor: pointer;
}
.Header .wrapAccess .company,
.Header .wrapAccess .store {
	margin: 0;
	padding: 0 15px;
	height: var(--heightHeader);
}
.Header .wrapAccess .company.pointer {
	cursor: pointer;
	transition: all .15s;
}
.Header .wrapAccess > .many {
	width: 10px;
	height: 10px;
	margin: calc((var(--heightHeader) - 34px) / 2) 0 0 10px;
	padding: 12px;
	background-image: url(../../image/ressource/down-arrow.svg);
	background-repeat: no-repeat;
	background-size: 10px 10px;
	background-position: center center;
	cursor: pointer;
	border: none;
	border-radius: 20px;
	transition: all .15s;
}
.Header .wrapAccess > .many.opened {
	transform: rotate(180deg);
}
.Header .wrapAccess .company.pointer:hover,
.Header .wrapAccess > .many:hover {
	background-color: var(--lightGraySkyTill);
}
.Header .wrapAccess .company > .item,
.Header .wrapAccess .store > .item {
	display: block;
	margin: 0;
	padding: 7px 0 0 0;
	color: var(--darkerGraySkyTill);
	font-size: 8pt;
}
.Header .wrapAccess .company > .name,
.Header .wrapAccess .store > .name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin: 0;
	padding: 3px 0 0 0;
}
.Header .wrapAccess .company > .loader {
	display: block;
	width: 18px;
	height: 18px;
	margin: 3px auto 0 auto;
	padding: 0;
}

.Header > .wrapAccess > .listStores {
	display: block;
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 5000;
	max-width: 250px;
	height: auto;
	max-height: 0;
	left: 0;
	top: var(--heightHeader);
	margin: 0;
	padding: 0;
	background-color: var(--backgroundColorHeader);
	border-radius: 0 0 5px 5px;
	transition: max-height 0.15s ease-out;
}
.Header > .wrapAccess > .listStores.visible {
	max-height: 250px;
	transition: max-height 0.25s ease-in;
}
.Header > .wrapAccess > .listStores > .wrapCompany {
	margin: 0;
	padding: 0;
}
.Header > .wrapAccess > .listStores > .wrapCompany > .linkCompany {
	margin: 0;
	padding: 15px 15px 10px 15px;
	font-size: 9pt;
	line-height: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.Header > .wrapAccess > .listStores > .linkStore,
.Header > .wrapAccess > .listStores > .wrapCompany > .linkStore {
	margin: 0;
	padding: 15px 30px 15px 15px;
	font-size: 9pt;
	line-height: 20px;
	cursor: pointer;
	transition: all .15s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.Header > .wrapAccess > .listStores > .wrapCompany > .linkStore {
	padding-left: 25px;
}
.Header > .wrapAccess > .listStores > .linkStore.selected,
.Header > .wrapAccess > .listStores > .wrapCompany > .linkStore.selected {
	padding-left: 40px;
	background-position: left 15px center;
	background-repeat: no-repeat;
	background-size: 16px 16px;
	background-image: url("../../image/ressource/check_dark_blue.svg");
	cursor: auto;
}
.Header > .wrapAccess > .listStores > .linkStore:not(.selected):hover,
.Header > .wrapAccess > .listStores > .wrapCompany > .linkStore:not(.selected):hover {
	background-color: var(--lighterBlueSkyTill);
	color: var(--darkBlueSkyTill);
	padding-right: 25px;
}
.Header > .wrapAccess > .listStores > .linkStore:not(.selected):hover {
	padding-left: 20px;
}
.Header > .wrapAccess > .listStores > .wrapCompany > .linkStore:not(.selected):hover {
	padding-left: 30px;
}
.Header > .wrapAccess > .listStores > .linkStore > .loader,
.Header > .wrapAccess > .listStores > .wrapCompany > .linkStore > .loader {
	display: block;
	width: 20px;
	height: 20px;
	margin: 0 auto;
	padding: 0;
}

.Header > .containerButtons {
	display: flex;
	float: right;
	height: var(--heightHeader);
	margin: 0;
	padding: 0;
}
.Header > .containerButtons > .hButton {
	height: calc(var(--heightHeader) - 20px);
	margin: 10px 10px 0 0;
	padding: 0 15px;
	border-radius: calc(var(--heightHeader) / 2);
	cursor: pointer;
	transition: all .25s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.Header > .containerButtons > .hButton:not(.catalogDeploying):hover {
	filter: brightness(90%);
}
.Header > .containerButtons > .hButton.catalogToDeploy,
.Header > .containerButtons > .hButton.catalogDeploying {
	padding-left: 42px;
	background-position: left 15px center;
	background-repeat: no-repeat;
	background-size: 14px 14px;
}
.Header > .containerButtons > .hButton.catalogToDeploy {
	background-color: var(--orangeSkyTill);
	background-image: url(../../image/ressource/deploy-white.svg);
}
.Header > .containerButtons > .hButton.catalogDeploying {
	cursor: auto;
	background-color: var(--purpleSkyTill);
}
.Header > .containerButtons > .hButton.catalogDeploying.upload {
	background-image: url(../../image/ressource/upload-white.svg);
}
.Header > .containerButtons > .hButton.catalogDeploying.download {
	background-image: url(../../image/ressource/import-white.svg);
}
.Header > .containerButtons > .hButton > p {
	margin: 0;
	padding: 0;
	float: left;
	font-size: 8pt;
	line-height: calc(var(--heightHeader) - 20px);
	color: var(--whiteSkyTill);
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.Header > .containerIcons {
	display: flex;
	float: right;
	height: var(--heightHeader);
	margin: 0;
	padding: 0;
}
.Header > .containerIcons > .iconLogout,
.Header > .containerIcons > .iconAccounts,
.Header > .containerIcons > .iconInformation,
.Header > .containerIcons > .iconSetting,
.Header > .containerIcons > .iconSearch,
.Header > .containerIcons > .iconNotification {
	display: block;
	margin: 17px 20px 0 0;
	padding: 0;
	cursor: pointer;
}
.Header > .containerIcons > .iconLogout {
	margin-right: 25px;
}
.Header > .containerIcons > .iconNotification {
	display: none;
}
.Header > .containerIcons > .iconLogout {
	margin-right: 15px;
}
.Header > .containerIcons svg {
	width: 16px;
	height: 16px;
	transition: 0.15s;
	fill: var(--lightBlackSkyTill);
}
.Header > .containerIcons > .iconAccounts:hover > svg,
.Header > .containerIcons > .iconInformation:hover > svg,
.Header > .containerIcons > .iconSearch:hover > svg,
.Header > .containerIcons > .iconNotification:hover > svg {
	fill: var(--darkBlueSkyTill);
}
.Header > .containerIcons > .iconSetting:hover > svg {
	fill: var(--darkGraySkyTill);
}
.Header > .containerIcons > .iconLogout:hover > svg {
	fill: var(--redSkyTill);
}

.typeToDeploy {
	display: block;
	position: fixed;
	z-index: 102;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.9);
	transition: all 0.15s;
	animation: opacityUp 0.5s ease;
	user-select: none;
}
.typeToDeploy > .box {
	display: block;
	position: fixed;
	z-index: 101;
	overflow: hidden;
	left: 50%;
	top: 50%;
	width: 500px;
	max-height: calc(100vh - 100px);
	margin: 0;
	padding: 0;
	background-color: #333333;
	border-radius: 10px;
	box-shadow: 0 0 6px rgba(0, 0, 0, .5);
	transform: translate(-50%, -50%);
	animation: appearDefineBox 0.15s ease;
}
.typeToDeploy > .box > .title {
	margin: 0;
	padding: 25px 25px 25px 25px;
	color: white;
	font-size: 10pt;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
}
.typeToDeploy > .box > .nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: calc(100% - 50px);
	margin: 0;
	padding: 0 25px 25px 25px;
	list-style-type: none;
}
.typeToDeploy > .box > .nav.double {
	justify-content: space-between;
}
.typeToDeploy > .box > .nav > .choice {
	width: 170px;
	margin: 10px 0 0 0;
	padding: 0 25px;
	color: white;
	font-size: 9pt;
	line-height: 42px;
	font-family: Montserrat-Regular, Arial, sans-serif;
	text-align: center;
	background-color: var(--darkerGraySkyTill);
	border-radius: 10px;
	cursor: pointer;
	transition: all .2s;
}
.typeToDeploy > .box > .nav.double > .choice {
	width: calc(50% - 62px);
}
.typeToDeploy > .box > .nav.double > .choice.disable {
	cursor: auto;
	opacity: 0.4;
	filter: alpha(opacity=40);
}
.typeToDeploy > .box > .nav > .choice:not(.disable):hover {
	background-color: var(--darkerLessGraySkyTill);
}
.typeToDeploy > .box > .nav > .choice > svg {
	width: 30px;
	height: 30px;
	margin: 25px auto 10px auto;
	fill: #FFFFFF;
}
.typeToDeploy > .box > .nav > .choice > .title {
	margin: 0;
	padding: 0 0 7px 0;
	text-align: center;
	font-size: 10pt;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
}
.typeToDeploy > .box > .nav > .choice > .description {
	margin: 0;
	padding: 0 0 20px 0;
	text-align: center;
	font-size: 9pt;
	line-height: 18px;
}
.typeToDeploy > .box > .close {
	margin: 15px 0 0 0;
	padding: 0 0 25px 0;
	color: var(--lightGraySkyTill);
	text-align: center;
	font-size: 9pt;
	font-family: Montserrat-Regular, Arial, sans-serif;
	cursor: pointer;
	transition: all .2s;
}
.typeToDeploy > .box > .close:hover {
	color: white;
}
@keyframes appearDefineBox {
	from {
		opacity: 0;
		filter: alpha(opacity=0);
	}
	to {
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

@media only screen and (max-width: 1199px) {
	.Header {
		width: calc(100% - var(--widthMenuReduced));
		margin: 0 0 0 var(--widthMenuReduced);
	}
}
@media only screen and (max-width: 640px) {
	.Header {
		width: 100%;
		margin-left: 0;
	}
	
	.Header .iconMenuMobile {
		display: block;
	}
	
	.Header .wrapAccess .iconEmpire {
		display: block;
	}
	.Header .wrapAccess .loaderSwitchEmpire {
		display: block;
	}
	.Header .wrapAccess .company {
		display: none;
	}
	.Header .wrapAccess .store > .name {
		font-size: 11pt;
	}
	
	.Header > .wrapAccess > .listStores {
		width: 100vw;
		max-width: 100vw;
		left: 0;
	}
}
@media print {
	.Header {
		display: none;
	}
}