.containerCheckbox {
	display: block;
	position: relative;
	padding: 0 0 0 35px;
	margin: -11px 0 0 0;
	cursor: pointer;
	outline: 0;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	height: 18px;
	width: 18px;
	background-color: var(--whiteSkyTill);
	border: 2px solid var(--lightGraySkyTill);
	border-radius: 6px;
	transition: all 0.4s;
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input ~ .checkmark {
	background-color: var(--lighterGraySkyTill);
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmark {
	background-color: var(--darkBlueSkyTill);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmark:after {
	left: 6px;
	top: 3px;
	width: 4px;
	height: 8px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
