.main.subcategory .wrapList .list .checkbox {
	text-align: center;
	width: 30px;
	min-width: 30px;
	max-width: 30px;
}
.main.subcategory .wrapList .list .name {
	width: 100%;
	min-width: 150px;
	max-width: 100%;
	text-align: left;
}
.main.subcategory .wrapList .list .catalogName {
	width: 200px;
	min-width: 200px;
	max-width: 200px;
	text-align: left;
}
.main.subcategory .wrapList .list .catalogName > div {
	float: left;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	margin: 0;
	padding: 0 10px 0 0;
	border-width: 1px;
	border-style: solid;
	border-radius: 5px;
}
.main.subcategory .wrapList .list .catalogName > div > .color {
	display: block;
	width: 15px;
	height: 36px;
	margin: 0;
	padding: 0;
}
.main.subcategory .wrapList .list .catalogName > div > .text {
	display: block;
	margin: 0;
	padding: 0 0 0 10px;
	font-size: 8pt;
	line-height: 36px;
}
.main.subcategory .wrapList .list .reference {
	min-width: 110px;
	text-align: left;
}

@media (max-width: 720px) {
	.main.subcategory .wrapList .list .reference {
		display: none;
	}
}
@media (max-width: 1100px) {
	.main.subcategory .wrapList .list .updated_at {
		display: none;
	}
}
