.ticket {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	height: auto;
	max-height: calc(100% - 100px);
	padding: 15px;
}
.ticket > .line {
	min-height: 20px;
	margin: 0;
	padding: 0;
	font-size: 10pt;
	line-height: 20px;
	font-weight: normal;
	text-align: left;
}
.ticket > .table {
	width: 100%;
	border: none;
	border-collapse: collapse;
}