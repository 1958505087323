.overOptions {
	visibility: hidden;
	position: fixed;
	z-index: 6;
	overflow-x: hidden;
	overflow-y: auto;
	width: 250px;
	max-width: 250px;
	max-height: 300px;
	border-radius: 3px;
	background-color: var(--whitySkyTill);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	opacity: 0;
	filter: alpha(opacity=0);
	transition: all 0.15s;
}
.overOptions.empire {
	top: 90px;
	right: 10px;
}
.overOptions.store {
	top: 90px;
	right: 10px;
}
.overOptions.local {
	position: relative;
	margin: 70px 0 0 0;
}
.overOptions.optionNbItems {
	position: absolute;
	right: 25px;
	margin: 90px 0 0 0;
}
.overOptions.providers,
.overOptions.brands,
.overOptions.categories,
.overOptions.subcategories,
.overOptions.menus,
.overOptions.products,
.overOptions.production-messages,
.overOptions.filters,
.overOptions.groups,
.overOptions.clients,
.overOptions.sellergroups,
.overOptions.sellers,
.overOptions.paymentMethods,
.overOptions.pricelists,
.overOptions.printers,
.overOptions.displays,
.overOptions.barcodereaders,
.overOptions.deploy {
	position: absolute;
	right: 15px;
	margin: 65px 0 0 0;
}
.overOptions.deploy {
	right: 0;
}

.overOptions.show {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}
.overOptions.empire.show {
	top: 70px;
}
.overOptions.store.show {
	top: 70px;
}
.overOptions.local.show {
	margin-top: 50px;
}
.overOptions.optionNbItems.show {
	margin-top: 70px;
}
.overOptions.providers.show,
.overOptions.brands.show,
.overOptions.categories.show,
.overOptions.subcategories.show,
.overOptions.menus.show,
.overOptions.products.show,
.overOptions.production-messages.show,
.overOptions.filters.show,
.overOptions.groups.show,
.overOptions.clients.show,
.overOptions.sellergroups.show,
.overOptions.sellers.show,
.overOptions.paymentMethods.show,
.overOptions.pricelists.show,
.overOptions.printers.show,
.overOptions.displays.show,
.overOptions.barcodereaders.show {
	margin-top: 50px;
}

.overOptions > .options {
	width: 100%;
	margin: 0;
	padding: 0;
}
.overOptions > .options .itemOption {
	width: calc(100% - 20px);
	margin: 0;
	padding: 12px 10px;
	text-align: left;
	font-size: var(--fontSizeGeneralText);
	line-height: 20px;
	cursor: pointer;
	transition: all 0.15s;
}
.overOptions > .options .itemOption:hover {
	background-color: var(--lighterGraySkyTill);
}
.overOptions > .options .itemOption.red {
	color: var(--redSkyTill);
}
.overOptions > .options .itemOption.red:hover {
	background-color: var(--redSkyTill);
	color: var(--whiteSkyTill);
}
.overOptions > .options .itemOption.blue {
	background-color: var(--lighterBlueSkyTill);
	color: var(--darkBlueSkyTill);
}
.overOptions > .options .itemOption.blue:hover {
	background-color: var(--lightBlueSkyTill);
}
.overOptions > .options .itemOption.gray {
	background-color: var(--lighterGraySkyTill);
	color: var(--darkerGraySkyTill);
}
.overOptions > .options .itemOption.gray:hover {
	background-color: var(--lightGraySkyTill);
}
.overOptions > .options .itemOption.add,
.overOptions > .options .itemOption.addMany,
.overOptions > .options .itemOption.export,
.overOptions > .options .itemOption.now,
.overOptions > .options .itemOption.later,
.overOptions > .options .itemOption.import {
	padding: 12px 10px 12px 35px;
	background-size: 15px 15px;
	background-position: left 10px center;
	background-repeat: no-repeat;
}
.overOptions > .options .itemOption.add {
	background-image: url("../image/ressource/cross-dark-blue.svg");
}
.overOptions > .options .itemOption.addMany {
	background-image: url("../image/ressource/add-many-dark-blue.svg");
}
.overOptions > .options .itemOption.export {
	background-image: url("../image/ressource/export-dark-gray.svg");
}
.overOptions > .options .itemOption.now {
	background-image: url("../image/ressource/deploy-dark-blue.svg");
}
.overOptions > .options .itemOption.later {
	background-image: url("../image/ressource/deploy-later-dark-blue.svg");
}
.overOptions > .options .itemOption.import {
	background-image: url("../image/ressource/import-dark-gray.svg");
}

@media (min-width: 641px) {

}
@media (max-width: 640px) {

}
