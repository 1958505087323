.containerHourlyChart {
	display: block;
	max-width: calc(100% - 30px);
	max-height: calc(100vh - 180px);
	margin: 30px auto 0 auto;
	padding: 0 50px;
}
.HourlyChart {
	display: block;
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	margin: 0 auto;
	padding: 0;
}

.toolTipHourlyChart {
	position: fixed;
	z-index: 101;
	left: 100px;
	top: 70px;
	margin: 0;
	padding: 15px;
	background-color: var(--lighterBlackSkyTill);
	box-shadow: 0 0 10px var(--darkerGraySkyTill);
	border-radius: 10px;
	pointer-events: none;
	opacity: 1;
	filter: alpha(opacity=100);
	transition: all .1s ease;
}
.toolTipHourlyChart > h1 {
	margin: 0 0 15px 0;
	padding: 0;
	font-size: 12pt;
	text-align: center;
	color: var(--whiteSkyTill);
}
.toolTipHourlyChart > .containerTable {
	overflow: hidden;
	border-radius: 10px;
}
.toolTipHourlyChart > .containerTable > .table {
	margin: 0;
	padding: 0;
	border-collapse: collapse;
}
.toolTipHourlyChart > .containerTable > .table > .line:last-child {
	border-bottom: none;
}
.toolTipHourlyChart > .containerTable > .table > .line > td {
	padding: 7px 10px;
	text-align: right;
}
.toolTipHourlyChart > .containerTable > .table > .line > .title {
	width: 150px;
	text-align: left;
	font-size: 10pt;
	color: var(--whiteSkyTill);
}
.toolTipHourlyChart > .containerTable > .table > .line .value {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
}
.toolTipHourlyChart > .containerTable > .table > .line .value > .arrow {
	width: 14px;
	height: 14px;
	padding: 0 5px;
	display: none;
}
.toolTipHourlyChart > .containerTable > .table > .line .value > .arrow.down {
	transform: rotate(180deg);
	fill: var(--redSkyTill);
	display: block;
}
.toolTipHourlyChart > .containerTable > .table > .line .value > .arrow.up {
	fill: var(--greenSkyTill);
	display: block;
}
.toolTipHourlyChart > .containerTable > .table > .line .value > span.value {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 10pt;
	color: var(--whiteSkyTill);
}
.toolTipHourlyChart > .containerTable > .table > .line span.versus {
	display: inline-block;
	font-size: 10pt;
	color: var(--whitySkyTill);
}
.toolTipHourlyChart > .containerTable > .table > .line span.deltaValue {
	display: inline-block;
	margin: 0;
	padding: 2px 5px 0 0;
	font-size: 10pt;
	color: var(--whitySkyTill);
}
.toolTipHourlyChart > .containerTable > .table > .line span.deltaValue.more {
	color: var(--greenSkyTill);
}
.toolTipHourlyChart > .containerTable > .table > .line span.deltaValue.less {
	color: var(--redSkyTill);
}
.toolTipHourlyChart > .containerTable > .table > .line span.deltaPercent {
	display: inline-block;
	margin: 0;
	padding: 2px 5px;
	font-size: 10pt;
	color: var(--whiteSkyTill);
	background-color: var(--darkGraySkyTill);
	border-radius: 5px;
}
.toolTipHourlyChart > .containerTable > .table > .line span.deltaPercent.more {
	background-color: var(--greenSkyTill);
}
.toolTipHourlyChart > .containerTable > .table > .line span.deltaPercent.less {
	background-color: var(--redSkyTill);
}

@media only screen and (max-width: 1199px) {

}
@media only screen and (max-width: 640px) {
	.containerHourlyChart {
		display: none;
	}
}
@media print {
	.toolTipHourlyChart {
		display: none;
	}
}