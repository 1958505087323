.menu {
	position: fixed;
	z-index: 90;
	display: block;
	overflow: hidden;
	width: var(--widthMenu);
	height: 100%;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
	transition: width 0.15s;
}
.menu.store {
	background: linear-gradient(var(--darkerBlueSkyTill), var(--darkBlueSkyTill));
}
.menu.company {
	background: linear-gradient(var(--darkerMoreGraySkyTill), var(--darkerGraySkyTill));
}

.menu > .logo {
	display: block;
	width: 50px;
	height: 50px;
	margin: 25px auto;
	padding: 0;
}

.menu .cancelMenuMobile {
	display: none;
}

.menu .navMenu {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	height: calc(100% - 215px);
	margin: 0;
	padding: 0 0 15px 0;
}

.menu .listItem {
	display: block;
	width: calc(100% - 30px);
	margin: 0 15px;
	padding: 0;
}
.menu .listItem .item {
	overflow: hidden;
	display: block;
	width: calc(100% - 30px);
	padding: 0 15px;
	height: 35px;
	margin: 0;
	text-align: left;
	font-size: var(--fontSizeGeneralText);
	border-radius: 3px;
	background-color: transparent;
}
.menu .listItem .item span {
	display: block;
	line-height: 35px;
	color: var(--whiteAlphaSkyTill);
}

.menu .listSubItem {
	display: block;
	overflow: hidden;
	width: 100%;
	margin: 0 0 5px 0;
	padding: 0;
	transition: all 0.25s;
}
.menu .listSubItem .subItem {
	overflow: hidden;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
	margin: 0;
	padding: 10px 0;
	text-align: left;
	font-size: var(--fontSizeGeneralText);
	cursor: pointer;
	color: var(--whiteSkyTill);
	transition: all 0.15s;
}
.menu.store .listSubItem .subItem.selected {
	color: var(--blueSkyTill);
}
.menu.company .listSubItem .subItem.selected {
	color: var(--whiteSkyTill);
}
.menu .listSubItem .subItem.beta::after {
	content: " - BETA";
	font-size: var(--fontSizeGeneralText);
	color: var(--orangeSkyTill);
}
.menu.store .listSubItem .subItem:not(.selected):hover {
	color: var(--lightBlueSkyTill);
}
.menu.company .listSubItem .subItem:not(.selected):hover {
	color: var(--whiteSkyTill);
}
.menu .listSubItem .subItem > .containerTitle {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 0;
	padding: 0;
}
.menu .listSubItem .subItem > .containerTitle > svg {
	display: block;
	width: 16px;
	height: 16px;
	margin: 0 12px 0 25px;
	transition: all 0.15s;
}
.menu.store .listSubItem .subItem > .containerTitle > svg {
	fill: var(--whiteSkyTill);
}
.menu.company .listSubItem .subItem > .containerTitle > svg {
	fill: var(--whitySkyTill);
}
.menu.store .listSubItem .subItem.selected > .containerTitle > svg {
	fill: var(--blueSkyTill);
}
.menu.company .listSubItem .subItem.selected > .containerTitle > svg {
	fill: var(--whiteSkyTill);
}
.menu.store .listSubItem .subItem:not(.selected):hover > .containerTitle > svg {
	fill: var(--lightBlueSkyTill);
}
.menu.company .listSubItem .subItem:not(.selected):hover > .containerTitle > svg {
	fill: var(--whiteSkyTill);
}
.menu .listSubItem .subItem > .containerTitle > .title {
	margin: 0;
	padding: 0;
	line-height: 16px;
}
.menu .listSubItem .subItem > .titleShort {
	display: none;
	width: 40px;
	border-radius: 10px;
	background-color: var(--whiteSkyTill);
	color: var(--lightBlackSkyTill);
	font-size: 9pt;
	line-height: 20px;
	text-align: center;
	margin: 0 auto;
	padding: 0 5px;
}
.menu .listSubItem .subItem > .loader,
.menu .listSubItem .subItem > .titleShort > .loader {
	display: block;
	width: 16px;
	height: 16px;
	margin: 0;
	padding: 0;
}
.menu .listSubItem .subItem > .titleShort > .loader {
	margin: 2px auto;
}
.menu .listSubItem .subItem > .loader.hidden {
	display: none;
}

.menu .legalVersion {
	display: block;
	position: fixed;
	width: var(--widthMenu);
	bottom: 55px;
	left: 0;
	margin: 0;
	padding: 0;
	text-align: center;
	font-size: 8pt;
	color: var(--lightGrayAlphaSkyTill);
}

.menu .helpPart {
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	width: var(--widthMenu);
	height: 40px;
	margin: 0;
	padding: 0;
	text-align: left;
	font-size: var(--fontSizeGeneralText);
	line-height: 40px;
	color: var(--whiteSkyTill);
	background-color: var(--darkerBlueSkyTill);
	cursor: pointer;
	transition: all 0.15s;
}
.menu .helpPart.store {
	background-color: var(--darkerBlueSkyTill);
}
.menu .helpPart.company {
	background-color: var(--lightBlackSkyTill);
}
.menu .helpPart.store:hover {
	background-color: var(--darkLessBlueSkyTill);
}
.menu .helpPart.company:hover {
	background-color: var(--darkerGraySkyTill);
}
.menu .helpPart > svg {
	display: block;
	float: left;
	width: 16px;
	height: 16px;
	margin: 12px 12px 0 25px;
	transition: all 0.15s;
	fill: var(--whiteSkyTill);
}

@media only screen and (max-width: 1199px) {
	.menu {
		width: var(--widthMenuReduced);
	}
	
	.menu > .logo {
		display: block;
		width: 30px;
		height: 30px;
		margin: 25px auto;
		padding: 0;
	}
	
	.menu .navMenu {
		display: block;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		height: calc(100% - 120px);
		margin: 0;
		padding: 0 0 15px 0;
	}
	
	.menu .listItem {
		width: 100%;
		margin: 0;
		padding: 0;
	}
	.menu .listItem .item {
		width: calc(100% - 20px);
		padding: 0 10px;
		text-align: center;
	}
	
	.menu .listSubItem .subItem.beta::after {
		display: none;
	}
	.menu .listSubItem .subItem > .containerTitle > svg {
		display: block;
		clear: both;
		width: 16px;
		height: 16px;
		margin: 0 0 0 calc((var(--widthMenuReduced) - 16px) / 2);
	}
	.menu .listSubItem .subItem.stores > .containerTitle > svg {
		display: none;
	}
	.menu .listSubItem .subItem > .containerTitle > .title {
		display: none;
	}
	.menu .listSubItem .subItem.stores > .titleShort {
		display: block;
	}
	.menu .listSubItem .subItem > .loader {
		display: none;
	}
	
	.menu .legalVersion {
		display: none;
	}
	.menu .helpPart {
		width: var(--widthMenuReduced);
	}
	.menu .helpPart > svg {
		float: none;
		margin: 12px 0 0 calc((var(--widthMenuReduced) - 16px) / 2);
	}
	.menu .helpPart > span {
		display: none;
	}
}
@media only screen and (max-width: 640px) {
	.menu {
		display: none;
		width: 100vw;
		height: 100vh;
	}
	.menu.visible {
		display: block;
	}
	
	.menu > .logo {
		float: right;
		width: 24px;
		height: 24px;
		margin: 15px 15px;
	}
	
	.menu .cancelMenuMobile {
		display: block;
		float: left;
		width: 16px;
		height: 16px;
		padding: 20px;
		margin: 0;
		fill: var(--whiteSkyTill);
		transition: all .15s;
	}
	.menu .cancelMenuMobile:hover {
		fill: var(--lighterGraySkyTill);
	}
	
	.menu .navMenu {
		height: calc(100% - 200px);
		padding: 0 0 100px 0;
	}
	
	.menu .listItem {
		display: block;
		width: 100%;
		margin: 0 0 0 0;
		padding: 0;
	}
	.menu .listItem a {
		text-decoration: none;
		color: var(--whiteSkyTill);
		transition: all 0.15s;
	}
	.menu .listItem .item {
		overflow: hidden;
		display: block;
		width: calc(100% - 85px);
		padding: 0 10px 0 25px;
		height: 45px;
		margin: 5px 0 5px 10px;
		text-align: left;
		font-size: var(--fontSizeGeneralText);
		border-radius: 3px;
		background-color: transparent;
		cursor: pointer;
		transition: all 0.15s;
		background-size: 20px 20px;
		background-position: left 10px center;
		background-repeat: no-repeat;
	}
	.menu .listItem .item span {
		display: block;
		line-height: 45px;
		color: var(--whiteSkyTill);
	}
	.menu .listItem .item:not(.selected):hover {
		background-color: var(--mainColorAlphaHover);
		color: var(--whiteSkyTill);
	}
	
	.menu .listSubItem {
		display: block;
		overflow: hidden;
		width: calc(100% - 40px);
		margin: 0 0 0 20px;
		padding: 0;
		transition: all 0.25s;
	}
	.menu .listSubItem.selected {
		background-color: var(--mainColorAlpha);
	}
	.menu .listSubItem .subItem {
		overflow: hidden;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		flex-direction: row;
		width: calc(100% - 10px);
		height: 45px;
		margin: 0;
		padding: 0 10px 0 0;
		text-align: left;
		font-size: var(--fontSizeGeneralText);
		line-height: 45px;
		color: var(--whiteSkyTill);
		border-radius: 3px;
		background-color: transparent;
		cursor: pointer;
		transition: all 0.15s;
	}
	.menu .listSubItem .subItem.beta::after {
		content: " - BETA";
		font-size: var(--fontSizeGeneralText);
		color: var(--orangeSkyTill);
	}
	.menu .listSubItem:not(.selected) .subItem:hover {
		background-color: var(--mainColorAlphaHover);
		color: var(--whiteSkyTill);
	}
	.menu .listSubItem .subItem .puce {
		display: block;
		float: left;
		width: 12px;
		height: 12px;
		margin: 16px 16px 0 15px;
		background-color: var(--mainColorAlpha);
		border-radius: 6px;
		transition: background-color 0.15s;
	}
	.menu .listSubItem .subItem.selected .puce {
		background-color: var(--mainColorHover);
	}
	.menu .listSubItem .subItem:hover .puce {
		background-color: var(--mainColor);
	}
	.menu .listSubItem .subItem > .containerTitle > svg,
	.menu .listSubItem .subItem.stores > .containerTitle > svg {
		display: block;
		float: left;
		margin: 14px 15px 0 25px;
	}
	.menu .listSubItem .subItem > .containerTitle > .title {
		display: block;
		line-height: 45px;
	}
	.menu.store .listSubItem .subItem.selected > .containerTitle > svg,
	.menu.company .listSubItem .subItem.selected > .containerTitle > svg {
		fill: var(--blueSkyTill);
	}
	.menu .listSubItem .subItem.selected > .containerTitle > .title {
		color: var(--blueSkyTill);
	}
	.menu .listSubItem .subItem.stores > .titleShort {
		display: none;
	}
	.menu .listSubItem .subItem > .loader {
		display: block;
		width: 20px;
		height: 20px;
		margin: 12px 5px 0 0;
	}
	
	.menu .helpPart {
		width: 100vw;
	}
	.menu .helpPart > svg {
		float: left;
		margin: 12px 12px 0 25px;
	}
	.menu .helpPart > span {
		display: block;
	}
}
@media print {
	.menu {
		display: none;
	}
}
