.POS .basket {
    position: relative;
	display: block;
	min-height: 100%;
	max-height: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: #323232;
}
.POS.format43 .basket {
	width: 203px;
}
.POS.format169 .basket {
	width: 225px;
}
.POS .basket.left {
	float: left;
}
.POS .basket.right {
	float: right;
}

.POS .basket > .button {
    display: block;
	float: left;
	width: calc(50% - 8px);
    margin: 5px 0 0 5px;
    height: 22px;
	background-color: #555555;
	border-radius: 3px;
}

.POS .basket > .subtotal {
    display: block;
    margin: 17px 0 0 0;
    padding: 0 15px;
    font-size: 22pt;
    font-weight: normal;
    text-align: right;
    color: white;
}

.POS .basket > .pad {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
}

.POS .basket > .pad tr {
    height: 39px;
}

.POS .basket > .pad tr td {
    text-align: center;
    font-size: 12pt;
    line-height: 39px;
    font-weight: normal;
    color: white;
}
