.screenCard > .screenToolsBar {
	overflow: hidden;
	position: fixed;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: stretch;
	top: 0;
	left: 50%;
	border-radius: 0 0 10px 10px;
	background-color: #333333;
	transform: translate(-50%, 0);
	animation: slideDown 0.5s ease;
}
.screenCard > .screenToolsBar a {
	display: flex;
	align-items: stretch;
	margin: 0;
	padding: 0;
	text-decoration: inherit;
	color: inherit;
}
.screenCard > .screenToolsBar .item {
	margin: 0;
	padding: 10px 15px;
	font-size: 8pt;
	color: var(--whiteSkyTill);
	cursor: pointer;
	transition: all .15s;
}
.screenCard > .screenToolsBar .item.magicTool {
	background-color: #DA773E;
}
.screenCard > .screenToolsBar .item.selected {
	background-color: #4D4D4D;
}
.screenCard > .screenToolsBar .item:not(.close):not(.magicTool):hover {
	background-color: #4D4D4D;
}
.screenCard > .screenToolsBar .item.magicTool:hover {
	background-color: #FF985A;
}
.screenCard > .screenToolsBar .item.close:hover {
	background-color: var(--redHoverSkyTill);
}
.screenCard > .screenToolsBar .item > svg {
	display: block;
	width: 18px;
	height: 18px;
	margin: 0 auto;
	fill: #FFFFFF;
}
.screenCard > .screenToolsBar .item > .containerText {
	display: flex;
	align-items: center;
	height: calc(100% - 30px);
	margin: 12px 0 0 0;
	padding: 0;
}
.screenCard > .screenToolsBar .item > .containerText > span {
	font-family: Montserrat-Regular, Arial, sans-serif;
	font-size: 8pt;
	text-align: center;
}

@keyframes slideDown {
	from {
		top: -100px;
	}
	to {
		top: 0;
	}
}