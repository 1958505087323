.headerAccess {
	display: block;
	position: fixed;
	z-index: 5;
	width: 100%;
	height: 60px;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
	background-color: var(--backgroundColorHeader);
}

.headerAccess > .logo {
	display: block;
	float: left;
	height: 40px;
	margin: 10px 10px;
}

.subHeaderAccessFiller {
	display: block;
	margin: 0;
	padding: 60px 0 0 0;
}

.headerAccess .iconLogout {
	display: block;
	float: right;
	width: 26px;
	height: 26px;
	margin: 17px 20px 0 5px;
	padding: 0;
	cursor: pointer;
}
.headerAccess .iconLogout > svg {
	width: 26px;
	height: 26px;
	transition: 0.15s;
	fill: var(--lightBlackSkyTill);
}
.headerAccess .iconLogout:hover > svg {
	fill: var(--redHoverSkyTill);
}

@media (min-width: 641px) {}
@media (max-width: 640px) {}
