.overbox > .clearing > .navigation.product > .form > .defaultVatRate {
	display: block;
	float: left;
	width: 50%;
	max-width: 50%;
	margin: 0;
	padding: 0;
}
.overbox > .clearing > .navigation.product > .form > .defaultPrice {
	display: block;
	float: right;
	width: 50%;
	max-width: 50%;
	margin: 0;
	padding: 0;
}
.overbox > .clearing > .navigation.product > .form > .defaultVatRate > a,
.overbox > .clearing > .navigation.product > .form > .defaultPrice > a {
	text-decoration: none;
}
