.UserPreview {
	display: block;
	float: right;
	height: calc(var(--heightHeader) - 14px);
	margin: 7px 15px 0 0;
	padding: 0 15px;
	background-color: var(--lightGraySkyTill);
	border-radius: calc((var(--heightHeader) - 14px) / 2);
	cursor: pointer;
	transition: all .15s;
}
.UserPreview:hover {
	filter: brightness(90%);
}
.UserPreview > .hello {
	margin: 0;
	padding: 5px 0 2px 0;
	font-size: 7pt;
	color: var(--darkerGraySkyTill);
}
.UserPreview > .name {
	margin: 0;
	padding: 0;
	font-size: 8pt;
}
@media only screen and (max-width: 640px) {
	.UserPreview {
		display: none;
	}
}