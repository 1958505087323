.containerHistory .historyButton {
	display: block;
	overflow: hidden;
	width: 40px;
	height: 40px;
	max-height: 40px;
	margin: 0 0 0 15px;
	padding: 0;
	line-height: 40px;
	border-radius: 5px;
	border: none;
	outline: none;
	font-size: var(--fontSizeGeneralText);
	font-weight: bold;
	font-family: inherit;
	background-repeat: no-repeat;
	cursor: pointer;
	transition: all 0.15s;
	background-color: var(--lighterGraySkyTill);
	background-position: center center;
	background-size: 16px;
	background-image: url("../../image/ressource/history-dark-blue.svg");
	
}
.containerHistory .historyButton:hover {
	background-color: var(--lightBlueSkyTill);
}
.containerHistory .historyButton > span {
	margin: 0;
	padding: 0;
}

.containerHistory .list {
	position: absolute;
	z-index: 50;
	visibility: hidden;
	overflow-x: hidden;
	overflow-y: auto;
	width: 400px;
	max-height: 500px;
	margin: 40px 0 0 -347px;
	padding: 0;
	list-style-type: none;
	border-radius: 3px;
	border: 1px solid var(--lighterGraySkyTill);
	background-color: var(--whitySkyTill);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
	opacity: 0;
	filter: alpha(opacity=0);
	transition: 0.25s all;
}
.containerHistory .list.visible {
	visibility: visible;
	margin-top: 10px;
	opacity: 1;
	filter: alpha(opacity=100);
}
.containerHistory .list > li {
	margin: 0;
	padding: 10px;
	border-bottom: 1px solid var(--lightGraySkyTill);
	cursor: pointer;
	transition: all .15s;
}
.containerHistory .list > li:last-child {
	border-bottom: none;
}
.containerHistory .list > li.empty {
	font-size: var(--fontSizeGeneralText);
	color: var(--darkerGraySkyTill);
	text-align: center;
}
.containerHistory .list > li:hover {
	background-color: var(--lighterGraySkyTill);
}

.containerHistory .list > li > .author,
.containerHistory .list > li > .date,
.containerHistory .list > li > .datas {
	margin: 0;
	color: var(--darkGraySkyTill);
}

.containerHistory .list > li > .datas {
	padding-top: 10px;
	font-size: var(--fontSizeGeneralText);
	line-height: 22px;
	color: var(--darkGraySkyTill);
}

.containerHistory .list > li > .author,
.containerHistory .list > li > .date {
	padding: 0;
	font-size: var(--fontSizeGeneralText);
	line-height: 18px;
}

.containerHistory .list > li > .author strong,
.containerHistory .list > li > .date strong,
.containerHistory .list > li > .datas strong {
	font-weight: normal;
	color: var(--textColorList);
}
