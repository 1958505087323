.TableStats {
	overflow: hidden;
	width: calc(100% - 32px);
	margin: 30px auto;
	padding: 0;
	border-radius: 10px;
	border: 1px solid var(--lightGraySkyTill);
}
.TableStats.wrapKeys {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	border-radius: 0;
	border: none;
	margin-top: 15px;
	margin-bottom: 0;
}

.TableStats > table {
	display: table;
	width: 100%;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
}
.TableStats > table > tbody > tr {
	width: 100%;
	transition: all .15s;
}
.TableStats > table > tbody > tr.header {
	height: 40px;
}
.TableStats > table > tbody > tr:hover {
	background-color: var(--mainColorAlphaHover);
}
.TableStats > table > tbody > tr > th {
	padding: 0 15px;
	text-align: center;
}
.TableStats > table > tbody > tr > td {
	padding: 15px;
	text-align: center;
}
.TableStats > table > tbody > tr > th > span,
.TableStats > table > tbody > tr > td > span {
	padding: 10px 15px;
	text-align: center;
}
.TableStats > table > tbody > tr > th {
	font-size: 9pt;
	color: var(--darkerGraySkyTill);
	background-color: var(--lighterGraySkyTill);
}
.TableStats > table > tbody > tr > td {
	font-size: 10pt;
	color: var(--lightBlackSkyTill);
}
.TableStats > table > tbody > tr > td.rank {
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0 0 0 15px;
}
.TableStats > table > tbody > tr > th.left,
.TableStats > table > tbody > tr > td.left {
	text-align: left;
}
.TableStats > table > tbody > tr > th.right,
.TableStats > table > tbody > tr > td.right {
	text-align: right;
}

.TableStats > table > tbody > tr > td > .container {
	margin: 0;
	padding: 0;
	text-align: right;
}
.TableStats > table > tbody > tr > td > .container > .value {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
}
.TableStats > table > tbody > tr > td > .container > .value > .arrow {
	width: 14px;
	height: 14px;
	padding: 0 5px;
	display: none;
}
.TableStats > table > tbody > tr > td > .container > .value > .arrow.down {
	transform: rotate(180deg);
	fill: var(--redSkyTill);
	display: block;
}
.TableStats > table > tbody > tr > td > .container > .value > .arrow.up {
	fill: var(--greenSkyTill);
	display: block;
}
.TableStats > table > tbody > tr > td > .container > .value > span.value {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 10pt;
	color: var(--lightBlackSkyTill);
}
.TableStats > table > tbody > tr > td > .container > span.versus {
	display: block;
	margin: 0;
	padding: 0 0 5px 0;
	font-size: 10pt;
	color: var(--darkGraySkyTill);
}
.TableStats > table > tbody > tr > td > .container > .delta {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
}
.TableStats > table > tbody > tr > td > .container > .delta > span.deltaValue {
	display: block;
	margin: 0;
	padding: 2px 5px 0 0;
	font-size: 9pt;
	color: var(--darkGraySkyTill);
}
.TableStats > table > tbody > tr > td > .container > .delta > span.deltaValue.more {
	color: var(--greenSkyTill);
}
.TableStats > table > tbody > tr > td > .container > .delta > span.deltaValue.less {
	color: var(--redSkyTill);
}
.TableStats > table > tbody > tr > td > .container > .delta > span.deltaPercent {
	display: block;
	margin: 0;
	padding: 2px 5px;
	font-size: 9pt;
	color: var(--whiteSkyTill);
	background-color: var(--darkGraySkyTill);;
	border-radius: 5px;
}
.TableStats > table > tbody > tr > td > .container > .delta > span.deltaPercent.more {
	background-color: var(--greenSkyTill);
}
.TableStats > table > tbody > tr > td > .container > .delta > span.deltaPercent.less {
	background-color: var(--redSkyTill);
}

.TableStats > .containerInfoBlock {
	display: none;
}
.TableStats.wrapKeys > .containerInfoBlock {
	width: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.TableStats > .containerInfoBlock > .infoBlock {
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 15px;
	background-color: var(--lighterGraySkyTill);
	border-radius: 10px;
}
.TableStats.wrapKeys > .containerInfoBlock > .infoBlock {
	width: calc((100% / 3) - 40px);
}
.TableStats > .containerInfoBlock > .infoBlock > .name {
	margin: 0;
	padding: 0;
	font-size: 10pt;
}
.TableStats > .containerInfoBlock > .infoBlock > .address {
	margin: 0;
	padding: 0;
	font-size: 9pt;
	color: var(--darkGraySkyTill);
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 25px 0 0 0;
	padding: 0;
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column {
	margin: 0;
	padding: 0;
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .value {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .value > .arrow {
	width: 14px;
	height: 14px;
	padding: 5px;
	display: none;
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .value > .arrow.down {
	transform: rotate(180deg);
	fill: var(--redSkyTill);
	display: block;
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .value > .arrow.up {
	fill: var(--greenSkyTill);
	display: block;
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .value > span.value {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 15pt;
	color: var(--lightBlackSkyTill);
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > span.versus {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 12pt;
	color: var(--darkGraySkyTill);
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .delta {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: flex-end;
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .delta > span.deltaValue {
	display: block;
	margin: 0;
	padding: 3px 0 0 0;
	font-size: 11pt;
	text-align: right;
	color: var(--darkGraySkyTill);
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .delta > span.deltaValue.more {
	color: var(--greenSkyTill);
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .delta > span.deltaValue.less {
	color: var(--redSkyTill);
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .delta > span.deltaPercent {
	display: block;
	margin: 0;
	padding: 2px 5px;
	font-size: 9pt;
	color: var(--whiteSkyTill);
	background-color: var(--darkGraySkyTill);
	border-radius: 5px;
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .delta > span.deltaPercent.more {
	background-color: var(--greenSkyTill);
}
.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .delta > span.deltaPercent.less {
	background-color: var(--redSkyTill);
}

@media only screen and (max-width: 1199px) {

}
@media only screen and (max-width: 640px) {
	.TableStats {
		margin-top: 10px;
		border-radius: 0;
		border: 0;
	}
	
	.TableStats > table {
		display: none;
	}
	
	.TableStats > .containerInfoBlock {
		display: flex;
		flex-direction: column;
	}
	.TableStats.wrapKeys > .containerInfoBlock {
		flex-direction: column;
		justify-content: normal;
	}
	.TableStats > .containerInfoBlock > .infoBlock {
		margin: 0 0 20px 0;
	}
	.TableStats.wrapKeys > .containerInfoBlock > .infoBlock {
		width: calc(100% - 30px);
	}
	.TableStats > .containerInfoBlock > .infoBlock > .name {
		font-size: 11pt;
	}
	.TableStats > .containerInfoBlock > .infoBlock > .containerDatas {
		margin: 15px 0 0 0;
	}
	.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .value > .arrow {
		width: 22px;
		height: 22px;
		padding: 0 5px;
	}
	.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .value > span.value {
		font-size: 16pt;
	}
	.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > span.versus {
		font-size: 14pt;
	}
	.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .delta > span.deltaValue {
		font-size: 14pt;
	}
	.TableStats > .containerInfoBlock > .infoBlock > .containerDatas > .column > .delta > span.deltaPercent {
		font-size: 11pt;
	}
}