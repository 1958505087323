.main.sale .wrapList .list .number {
	width: 110px;
	min-width: 110px;
	max-width: 110px;
	text-align: left;
}
.main.sale .wrapList .list .seller {
	width: 100%;
	min-width: 150px;
	max-width: 150px;
	text-align: left;
}
.main.sale .wrapList .list .amount {
	width: 110px;
	min-width: 110px;
	max-width: 110px;
	text-align: right;
}
.main.sale .wrapList .list .article {
	width: 80px;
	min-width: 80px;
	max-width: 80px;
	text-align: center;
}
.main.sale .wrapList .list .source {
	width: 120px;
	min-width: 120px;
	max-width: 120px;
	text-align: left;
}
.main.sale .wrapList .list td.source {
	text-transform: uppercase;
}
.main.sale .wrapList .list .created_at {
	width: 180px;
	min-width: 180px;
	max-width: 180px;
	text-align: left;
}

@media (max-width: 445px) {
}
@media (max-width: 945px) {
	.main.sale .wrapList .list .created_at {
		display: none;
	}
}
@media (max-width: 1145px) {
	.main.sale .wrapList .list .seller {
		display: none;
	}
}
@media (max-width: 1300px) {
	.main.sale .wrapList .list .source {
		display: none;
	}
}
