.BackgroundTasks {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	align-items: center;
	float: right;
	height: calc(var(--heightHeader) - 16px);
	margin: 7px 10px 0 0;
	padding: 0 15px;
	background-color: transparent;
	border-radius: calc((var(--heightHeader) - 14px) / 2);
	border: 1px solid var(--lightGraySkyTill);
	cursor: pointer;
	transition: all .15s;
}
.BackgroundTasks:hover {
	filter: brightness(90%);
}
.BackgroundTasks > .text {
	margin: 0;
	padding: 0;
	font-size: 8pt;
	color: var(--darkerMoreGraySkyTill);
}
.BackgroundTasks > .iconState {
	width: 14px;
	height: 14px;
	margin: 0 0 0 15px;
	fill: var(--darkerMoreGraySkyTill);
}

@media only screen and (max-width: 640px) {
	.BackgroundTasks {
		display: none;
	}
}