.main.account .wrapList .list .name {
	width: 100%;
	min-width: 150px;
	max-width: 100%;
	text-align: left;
}
.main.account .wrapList .list .email {
	width: 300px;
	min-width: 300px;
	max-width: 300px;
	text-align: left;
}
.main.account .wrapList .list .rules {
	width: 300px;
	min-width: 300px;
	max-width: 300px;
	text-align: left;
}
.main.account .wrapList .list .state {
	width: 150px;
	min-width: 150px;
	max-width: 150px;
	text-align: left;
}
.main.account .wrapList .list .date {
	width: 200px;
	min-width: 200px;
	max-width: 200px;
	text-align: left;
}

@media (max-width: 720px) {
	.main.account .wrapList .list .rules {
		display: none;
	}
}
@media (max-width: 1100px) {
	.main.account .wrapList .list .date {
		display: none;
	}
}
