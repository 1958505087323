.ButtonAction {
	overflow: hidden;
	display: flex;
	flex-flow: nowrap;
	flex-direction: row;
	height: 40px;
	margin: 0 0 0 15px;
	padding: 0;
	border-radius: 10px;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ButtonAction > .mainAction {
	display: flex;
	flex-flow: nowrap;
	flex-direction: row;
	height: 40px;
	margin: 0;
	padding: 0;
	background-color: var(--lightBlueAlphaSkyTill);
	cursor: pointer;
	transition: all .2s;
}
.ButtonAction > .mainAction:hover {
	filter: brightness(70%);
}
.ButtonAction > .mainAction > .icon {
	display: block;
	width: 14px;
	height: 14px;
	margin: 13px 0 0 0;
	padding: 0 15px;
	fill: var(--darkBlueSkyTill);
	transition: all .2s;
}
.ButtonAction > .mainAction > .text {
	margin: 0;
	padding: 0 15px 0 0;
	font-size: 8pt;
	line-height: 40px;
	color: var(--darkBlueSkyTill);
}

.ButtonAction > .secondaryActions {
	height: 40px;
	margin: 0;
	padding: 0;
	background-color: var(--lightBlueAlphaSkyTill);
	cursor: pointer;
	transition: all .2s;
}
.ButtonAction > .secondaryActions:hover {
	filter: brightness(70%);
}
.ButtonAction > .secondaryActions > .down {
	width: 12px;
	height: 12px;
	margin: 0;
	padding: 14px 10px;
	fill: var(--darkBlueSkyTill);
	cursor: pointer;
	transition: all .15s;
}

.ButtonActionSubItemList {
	overflow: hidden;
	position: fixed;
	visibility: collapse;
	z-index: 100;
	width: 200px;
	padding: 0;
	border-radius: 10px;
	background-color: var(--lighterGraySkyTill);
	transition: opacity .2s, filter .2s;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ButtonActionSubItemList.visible {
	opacity: 1;
	filter: alpha(opacity=100);
}
.ButtonActionSubItemList > .secondaryAction {
	display: flex;
	flex-flow: nowrap;
	flex-direction: row;
	margin: 0;
	padding: 0;
	cursor: pointer;
	transition: all .2s;
}
.ButtonActionSubItemList > .secondaryAction:not(.blue):hover {
	background-color: var(--lightGraySkyTill);
}
.ButtonActionSubItemList > .secondaryAction.blue {
	display: flex;
	flex-flow: nowrap;
	flex-direction: row;
	margin: 0;
	padding: 0;
	cursor: pointer;
	transition: all .2s;
	background-color: var(--lightBlueAlphaSkyTill);
}
.ButtonActionSubItemList > .secondaryAction.blue:hover {
	filter: brightness(70%);
}
.ButtonActionSubItemList > .secondaryAction > .icon {
	display: block;
	width: 12px;
	height: 12px;
	margin: 14px 0 0 0;
	padding: 0 15px;
	fill: var(--darkerGraySkyTill);
	transition: all .2s;
}
.ButtonActionSubItemList > .secondaryAction > .text {
	margin: 0;
	padding: 0 10px 0 0;
	font-size: 8pt;
	line-height: 40px;
	color: var(--lightBlackSkyTill);
}
.ButtonActionSubItemList > .secondaryAction.blue > .icon {
	fill: var(--darkBlueSkyTill);
}
.ButtonActionSubItemList > .secondaryAction.blue > .text {
	color: var(--darkBlueSkyTill);
}