.form {
	display: block;
	margin: 0;
	padding: 15px;
}
.form.noPadding {
	padding: 0;
}
.form.noPaddingT {
	padding-top: 0;
}
.form.noPaddingTB {
	padding: 0 15px;
}
.form.noPaddingLR {
	padding: 15px 0;
}
.form.fullHeight {
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100% - 30px);
	min-height: calc(100% - 30px);
}
.form.overflowX {
	overflow-x: auto;
}
.form.fullHeightnoPadding {
	padding: 0;
	height: calc(100% - 30px);
	min-height: calc(100% - 30px);
}
.form.fullHeight.withSavingBar {
	height: calc(100% - 110px);
	min-height: calc(100% - 110px);
}

.form .row {
	display: block;
	margin: 0;
	padding: 0;
}

.wrapForm .titleForm,
.form .titleForm {
	overflow: hidden;
	display: block;
	margin: 0 0 15px 0;
	padding: 0;
	font-size: 11pt;
	line-height: 24px;
	font-weight: bold;
	color: var(--textColorInput);
}
.wrapForm .titleForm.center,
.form .titleForm.center {
	text-align: center;
}
.wrapForm .titleForm.marginTop,
.form .titleForm.marginTop {
	margin-top: 15px;
}
.wrapForm .titleForm.marginBottom,
.form .titleForm.marginBottom {
	margin-bottom: 30px;
}
.wrapForm .titleForm.white,
.form .titleForm.white {
	color: #FFFFFF;
}

.wrapForm .description,
.form .description {
	display: block;
	margin: 20px 0;
	padding: 0;
	font-size: 10pt;
	text-align: left;
	line-height: 20px;
	color: var(--darkerGraySkyTill);
}

.wrapForm .description > strong,
.form .description > strong {
	color: var(--redSkyTill);
	font-weight: bolder;
}

.form .inputLabelSecure {
	display: block;
	margin: 20px 0;
	padding: 0;
	font-size: 10pt;
	text-align: left;
	line-height: 22px;
	color: var(--textColorInput);
}
.form .inputLabelSecure > b {
	text-transform: uppercase;
	font-weight: bold;
	color: var(--redSkyTill);
}

.wrapForm .globalError,
.form .globalError {
	margin: 0 0 15px 0;
	padding: 10px 15px;
	font-size: 9pt;
	line-height: 20px;
	text-align: left;
	border-radius: 20px;
	color: rgba(255, 255, 255, 1);
	background-color: rgb(195, 48, 48);
}
.wrapForm .globalValid,
.form .globalValid {
	margin: 0 0 15px 0;
	padding: 10px 10px;
	font-size: 10pt;
	line-height: 20px;
	text-align: left;
	border-radius: 3px;
	color: rgba(255, 255, 255, 1);
	background-color: var(--validColor);
}

.form .checkPwd {
	display: block;
	margin: 20px 0 0 10px;
	padding: 0;
	list-style-type: none;
}
.form .checkPwd > .item {
	padding: 0 0 0 25px;
	font-size: 8pt;
	line-height: 20px;
	color: var(--darkerGraySkyTill);
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 14px 14px;
	background-image: url("../../image/ressource/list-none.svg");
	transition: all .15s;
}
.form .checkPwd > .item.nok {
	color: var(--redSkyTill);
	background-image: url("../../image/ressource/list-error.svg");
}
.form .checkPwd > .item.ok {
	color: var(--greenSkyTill);
	background-image: url("../../image/ressource/list-checked.svg");
}

.form .leftPart {
	display: block;
	float: left;
	width: calc(50% - 15px);
	min-height: 100%;
	height: 100%;
	margin: 0;
	padding: 0 15px 0 0;
}
.form .leftPart.border {
	width: calc(50% - 16px);
	border-right: var(--borderMenu);
}
.form .rightPart {
	display: block;
	float: right;
	width: calc(50% - 15px);
	min-height: 100%;
	height: 100%;
	margin: 0;
	padding: 0 0 0 15px;
}
.form .rightPart.border {
	width: calc(50% - 16px);
	border-left: var(--borderMenu);
}

.form .preview {
	width: calc(100% - 30px);
	height: 39px;
	margin: 5px 0 0 0;
	padding: 0 15px;
	color: rgb(70, 70, 70);
	line-height: 37px;
	text-align: center;
	cursor: pointer;
	transition: all 0.15s;
}
.form .preview.marginLR {
	width: calc(100% - 60px);
	margin-left: 15px;
	margin-right: 15px;
}
.form .preview.empty {
	color: rgb(150, 150, 150);
}
.form .preview.high {
	font-size: 12pt;
}
.form .preview.normal {
	font-size: 11pt;
}
.form .preview.left {
	text-align: left;
}
.form .preview.right {
	text-align: right;
}
.form .preview:hover {
	color: rgb(30, 30, 30);
	background-color: rgb(245, 245, 245);
}
.form .preview .edit {
	opacity: 0;
	filter: alpha(opacity=0);
	width: 16px;
	height: 16px;
	margin: 11px 0 0 15px;
	padding: 0;
}
.form .preview .edit.right {
	float: right;
}
.form .preview:hover .edit {
	opacity: 1;
	filter: alpha(opacity=100);
}

.form .preview.postalCode {
	float: left;
	width: calc(40% - 30px);
}
.form .preview.city {
	float: right;
	width: calc(60% - 40px);
}

.form .part {
	width: 100%;
	margin: 15px 0;
	padding: 0;
    font-size: 10pt;
	line-height: 30px;
	text-transform: uppercase;
	color: rgb(200, 200, 200);
	border-bottom: 1px solid rgb(220, 220, 220);
}
.form .part.noMarginTop {
	margin-top: 0;
}

.form .label {
	display: block;
	height: 18px;
	margin: 15px 0 0 0;
	padding: 0 0 0 10px;
	font-size: 9pt;
	text-align: left;
	color: rgb(100, 100, 100);
}
.form .label.clear {
	clear: both;
}
.form .label.marginBottom {
	margin-bottom: 10px;
}
.form .label.noMarginTop {
	margin-top: 0;
}
.form .label.white {
	color: #FFFFFF;
}

.form .label.postalCode {
	float: left;
	width: calc(40% - 15px);
}
.form .label.city {
	float: right;
	width: calc(60% - 25px);
}

.form .action {
	margin: 0;
	padding: 10px 15px;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	font-size: 9pt;
	color: var(--darkBlueSkyTill);
	background-color: transparent;
	border: none;
	cursor: pointer;
	transition: all .15s;
}
.form .action:hover {
	text-decoration: underline;
	color: var(--darkerBlueSkyTill);
}

.form .popupForm {
	display: none;
	position: fixed;
	z-index: 104;
	min-width: 250px;
	max-width: 350px;
	padding: 0;
	list-style-type: none;
	border-radius: 3px;
	border: 1px solid var(--lighterGraySkyTill);
	background-color: var(--whitySkyTill);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
.form .popupForm.visible {
	display: block;
}
.form .popupForm > li {
	font-size: var(--fontSizeGeneralText);
	line-height: 18px;
	margin: 0;
	padding: 12px 15px;
	cursor: pointer;
	transition: all .15s;
}
.form .popupForm > li:hover {
	background-color: var(--lightGraySkyTill);
}

.form .noInput {
	position: relative;
	overflow: hidden;
	display: block;
	width: calc(100% - 20px);
	margin: 5px 0 0 0;
	padding: var(--paddingInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	cursor: pointer;
	transition: all 0.25s;
}
.form .noInput.noEdit  {
	cursor: auto;
}
.form .noInput.noMarginTop  {
	margin-top: 0;
}
.form .noInput.noBackground  {
	border: none;
}
.form .noInput.marginLR {
	margin-left: 5px;
	margin-right: 5px;
}
.form .noInput.left {
	float: left;
	width: calc(50% - 30px);
}
.form .noInput.right {
	float: right;
	width: calc(50% - 30px);
}
.form .noInput.clear {
	clear: both;
}
.form .noInput.productPrice, .form .noInput.productVat {
	padding-top: 10px;
	padding-bottom: 10px;
}
.form .noInput:not(.noEdit):hover {
	background-color: var(--backgroundColorNoInputHover);
}
.form .noInput .textInput {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	width: calc(100% - 37px);
	margin: 0;
	padding: 0;
	line-height: 39px;
	font-size: 9pt;
	font-weight: bold;
	color: var(--textColorInput);
	transition: all 0.15s;
}
.form .noInput .textInput.color {
	border-radius: 5px;
	padding: 0 15px;
	width: calc(100% - 30px);
	color: var(--whiteSkyTill);
}
.form .noInput .textInput.color.empty {
	width: 100%;
	padding: 0;
	color: var(--darkerGraySkyTill);
}
.form .noInput.whiteForeground .textInput {
	color: var(--whiteSkyTill);
}
.form .noInput.leftAlign .textInput {
	text-align: left;
}
.form .noInput.rightAlign .textInput {
	width: 100%;
	text-align: right;
}
.form .noInput.centerAlign .textInput {
	width: 100%;
	text-align: center;
}
.form .noInput.high .textInput {
	font-size: 11pt;
}
.form .noInput .textInput > .devise, .form .noInput .textInput > .devise {
	padding-left: 10px;
	color: #6D6D6D;
	font-size: 10pt;
}
.form .noInput.productPrice .textInput, .form .noInput.productVat .textInput {
	font-size: 20pt;
	line-height: 30px;
	text-transform: uppercase;
	color: var(--textColor);
}
.form .noInput.productPrice .textInput > .devise, .form .noInput.productVat .textInput > .devise {
	padding-left: 10px;
	color: #333333;
	font-size: 14pt;
}
.form .noInput.centerAlign:hover .textInput {
	width: calc(100% - 37px);
}
.form .noInput.centerAlign:hover .textInput, .form .noInput.rightAlign:hover .textInput {
	width: calc(100% - 37px);
}
.form .noInput .textInput > .indice {
	display: block;
	float: left;
	margin: 7px 0 0 0;
	padding: 0 10px;
	line-height: 25px;
	font-size: 9pt;
	font-weight: bold;
	color: var(--textColorInput);
	border-radius: 3px;
	background-color: var(--lightGrayAlphaSkyTill);
}
.form .noInput .textInput > .value {
	display: block;
	float: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin: 0;
	padding: 0 15px;
	line-height: 39px;
	font-size: 9pt;
	font-weight: bold;
	color: var(--textColorInput);
}
.form .noInput .icons {
	position: absolute;
	display: block;
	width: 37px;
	height: 37px;
	right: -37px;
	margin: 0;
	padding: 0;
	transition: all 0.15s;
}
.form .noInput:hover .icons {
	right: 0;
}
.form .noInput.color:hover .icons {
	right: 15px;
}
.form .noInput .icons a {
	border: none;
}
.form .noInput .icons svg {
	display: block;
	float: right;
	width: 19px;
	height: 19px;
	margin: 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
}
.form .noInput.whiteForeground .icons svg,
.form .noInput.color .icons svg {
	fill: var(--whiteSkyTill);
}
.form .noInput.productPrice .icons svg, .form .noInput.productVat .icons svg {
	padding: 7px 9px;
}
.form .noInput .icons svg:hover, .form .inputText svg:hover {
	fill: var(--mainColor);
}
.form .noInput .icons svg.bounce {
	animation: bounce 1s ease;
}
.form .noInput .quill > .ql-container > .ql-editor {
	padding-left: 0;
	padding-right: 0;
}

.form .noInputComplex {
	overflow: hidden;
	display: block;
	width: calc(100% - 30px);
	margin: 5px 0 0 0;
	padding: var(--paddingInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	transition: all 0.25s;
}
.form .noInputComplex.left {
	float: left;
	width: calc(50% - 40px);
}
.form .noInputComplex.right {
	float: right;
	width: calc(50% - 40px);
}
.form .noInputComplex.clear {
	clear: both;
}
.form .noInputComplex:hover {
	background-color: var(--backgroundColorNoInputHover);
}
.form .noInputComplex .textInput {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	float: left;
	width: calc(100% - 151px);
	margin: 0;
	padding: 0;
	line-height: 39px;
	font-size: 10pt;
	font-weight: bold;
	color: var(--textColorInput);
}
.form .noInputComplex .icons {
	position: relative;
	display: block;
	float: right;
	width: 151px;
	height: 37px;
	margin: 0 -166px 0 0;
	padding: 0;
	transition: all 0.25s;
}
.form .noInputComplex:hover .icons {
	margin-right: 0;
}
.form .noInputComplex .icons a {
	border: none;
}
.form .noInputComplex .icons svg {
	display: block;
	float: right;
	width: 19px;
	height: 19px;
	margin: 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
}
.form .noInputComplex .icons svg:hover, .form .inputText svg:hover {
	fill: var(--mainColor);
}
.form .noInputComplex .icons svg.bounce {
	animation: bounce 1s ease;
}
.form .noInputComplex .contTags {
	display: block;
	clear: both;
	width: 100%;
	margin: 0;
	padding: 0 0 10px 0;
}
.form .noInputComplex .contTags .tag {
	display: block;
	float: left;
	margin: 0 5px 0 0;
	padding: 5px 8px;
	border-radius: 3px;
	font-size: 10pt;
	font-weight: bold;
	background-color: var(--mainColor);
}
.form .noInputComplex .contTags .tag.skytillAccount {
	background-color: rgb(27, 83, 115);
}

.form .noInputComboBox {
	position: relative;
	overflow: hidden;
	display: block;
	height: 40px;
	margin: 0 15px 0 0;
	padding: var(--paddingInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
	cursor: pointer;
	transition: all 0.25s;
}
.form .noInputComboBox:hover {
	background-color: var(--backgroundColorNoInputHover);
}
.form .noInputComboBox .comboBox {
	height: 100%;
	padding: 0 40px 0 0;
	margin: 0;
	background-position: right center;
	background-size: 14px 14px;
	background-repeat:  no-repeat;
	background-image: url(../../image/form/down-arrow.svg);
}
.form .noInputComboBox .comboBox > .mainText {
	display: block;
	margin: 0;
	padding: 0;
	font-size: var(--fontSizeGeneralText);
	line-height: 40px;
}
.form .noInputComboBox .comboBox > .mainText.withSecondary {
	padding: 6px 0 0 0;
	line-height: 15px;
}
.form .noInputComboBox .comboBox > .secondaryText {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 7pt;
	color: var(--textColorSecondary);
	line-height: 15px;
}

.form input, .form select {
	outline: none;
	transition: all 0.25s;
}
.form input.center, .form select.center {
	text-align: center;
}
.form input.left, .form select.left {
	text-align: left;
}
.form input.right, .form select.right {
	text-align: right;
}
.form input[type="text"],
.form input[type="password"],
.formLogin input[type="password"],
.form select,
.form textarea {
	display: block;
	width: calc(100% - 22px);
	height: 37px;
	margin: 5px 0 0 0;
	padding: var(--paddingInputText);
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}
.form.tabBar select {
	height: 30px;
	margin: 0;
}
.form .inputText.noBackground input[type="text"],
.form .inputText.noBackground input[type="password"],
.form .inputText.noBackground select,
.form .inputText.noBackground textarea {
	border-radius: 0;
	border: none;
	background-color: transparent;
}
.form .inputText.whiteForeground input[type="text"],
.form .inputText.whiteForeground input[type="password"],
.form .inputText.whiteForeground select,
.form .inputText.whiteForeground textarea {
	color: var(--whiteSkyTill);
}
.form .inputText.high input[type="text"],
.form .inputText.high input[type="password"],
.form .inputText.high select,
.form .inputText.high textarea {
	font-size: 11pt;
}
.form input[type="text"]:hover,
.form input[type="password"]:hover,
.formLogin input[type="password"]:hover,
.form select:hover,
.form textarea:hover {
	border-color: var(--darkBlueSkyTill);
	background-color: var(--backgroundColorInputHover);
}
.form input[type="checkbox"] {
	margin: 0 10px 0 0;
}
.form input:focus, .form select:focus, .form textarea:focus, .form .inputText .tox-tinymce:focus {
	outline: none;
	border-color: var(--borderColorInputTextFocus);
	background-color: var(--backgroundColorInputHover);
}
.form input.wrong, form select.wrong, form textarea.wrong, .form .inputText .tox-tinymce.wrong {
	border-color: rgb(195, 48, 48);
}

.form input[type="text"].postalCode {
	float: left;
	width: calc(40% - 34px);
}
.form input[type="text"].city {
	float: right;
	width: calc(60% - 44px);
}

.form > .ticket {
	display: block;
	filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.15));
	max-width: 520px;
	margin: 15px auto 0 auto;
	padding: 4px 0;
	background: linear-gradient(135deg, transparent 5.68px, var(--whiteSkyTill) 5.69px) linear-gradient(45deg, var(--whiteSkyTill) 2.8px, transparent 2.81px) linear-gradient(135deg, var(--whiteSkyTill) 2.8px, transparent 2.81px) linear-gradient(45deg, transparent 5.68px, var(--whiteSkyTill) 5.69px) repeat-x top left top left bottom left bottom left;
	background-size: 8px 4px;
}

.form .inputText.inline input:focus,
.form .inputText.inline select:focus {
	background-color: var(--lightBlueAlphaSkyTill);
}

.form .inputText {
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
}
.form.tabBar .inputText {
	height: 30px;
	margin: 0;
}
.form .inputText.marginLR {
	margin-left: 5px;
	margin-right: 5px;
}
.form .inputText.left {
	float: left;
	width: calc(50% - 10px);
}
.form .inputText.right {
	float: right;
	width: calc(50% - 10px);
}
.form .inputText.widthAuto {
	width: auto;
}
.form .inputText.oneTiers {
	width: calc(25% - 5px);
}
.form .inputText.twoTiers {
	width: calc(75% - 5px);
}
.form .inputText.clear {
	clear: both;
}
.form .inputText.marginLeft {
	margin-left: 15px;
}
.form .inputText.marginBottom {
	margin-bottom: 20px;
}
.form .inputText.marginTop {
	margin-top: 15px;
}
.form .inputText.noMarginTop {
	margin-top: 0;
}
.form .inputText.value {
	width: 153px;
	margin-left: 10px;
}
.form .inputText.sign,
.form .inputText.type {
	width: 83px;
}
.form .inputText.displayText {
	background-color: #282828;
	margin: 0;
	padding: 0;
	border: none;
}
.form .inputText.headerText {
	background-color: var(--whiteSkyTill);
	border: none;
	margin: 0;
	padding: 0;
}
.form .inputText.headerText.interTicket::before {
	content: "";
	display: block;
	padding: 20px 0;
	background-size: 20px 20px;
	background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 15%, #FFFFFF 15%, #FFFFFF 35%, rgba(0, 0, 0, 0.1) 35%, rgba(0, 0, 0, 0.1) 65%, #FFFFFF 65%, #FFFFFF 85%, rgba(0, 0, 0, 0.1) 85%) 0px 0px;
}
.form .inputText.pricelist {
	width: calc(100% - 30px);
	margin-top: 0;
}
.form .inputText.pricelist.extend {
	width: 100%;
}
.form .inputText.border {
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
}
.form .inputText.flex {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}
.form .inputText input[type="text"],
.form .inputText input[type="password"] {
	float: left;
	width: calc(100% - 64px);
	margin-top: 0;
	font-family: inherit;
	font-size: var(--fontSizeInputText);
}
.form .inputText.displayText input[type="text"] {
	clear: both;
	padding: 10px 0;
	color: var(--whiteSkyTill);
	background-color: transparent;
	font-size: 18pt;
	border: none;
	text-align: center;
	font-family: Calculator, Montserrat-SemiBold, Arial, sans-serif;
	font-weight: bold;
}
.form .inputText.headerText input[type="text"] {
	clear: both;
	padding: 5px 0;
	color: var(--blackSkyTill);
	font-size: 16pt;
	border: none;
	text-align: center;
	font-family: Merchant, Arial, sans-serif;
}
.form .inputText.headerText input[type="text"].bold {
	font-weight: bold;
}
.form .inputText.productPrice input[type="text"],
.form .inputText.productPrice input[type="password"],
.form .inputText.productVat input[type="text"],
.form .inputText.productVat input[type="password"] {
	height: 53px;
	font-size: 14pt;
}
.form .inputText.border input[type="text"] {
	border: none;
}
.form .inputText.flex input[type="text"] {
	clear: both;
}
.form .inputText .tox-tinymce {
	float: left;
	width: calc(100% - 43px);
	margin-top: 0;
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
}
.form .inputText.uppercase input[type="text"] {
	text-transform: uppercase;
}
.form .inputText.center input[type="text"] {
	text-align: center;
}
.form .inputText input[type="text"].extend,
.form .inputText input[type="password"].extend {
	width: calc(100% - 22px);
}
.form .inputText input[type="text"].variablePrice {
	width: calc(100% - 262px);
	padding-right: 250px;
}
.form .inputText select {
	float: left;
	width: calc(100% - 42px);
	height: 39px;
	padding-right: 45px;
	margin-top: 0;
	cursor: pointer;
	font-family: inherit;
	font-size: var(--fontSizeInputText);
	background-position: right 15px center;
	background-size: 14px 14px;
	background-repeat:  no-repeat;
	background-image: url(../../image/ressource/down-arrow-dark-blue.svg);
}
.form .inputText.pricelist input {
	clear: both;
	float: none;
	margin: 0;
	height: 39px;
	border: none;
	text-align: center;
}
.form .inputText.pricelist select {
	clear: both;
	float: none;
	margin: 0 15px;
	height: 39px;
	border: none;
}
.form .inputText.productPrice select,
.form .inputText.productVat select {
	height: 53px;
	font-size: 14pt;
}
.form .inputText select.extend {
	width: 100%;
}
.form .inputText > .quill {
}
.form .inputText > .quill > .ql-toolbar {
	border-radius: 3px 3px 0 0;
}
.form .inputText > .quill > .ql-container {
	border-radius: 0 0 3px 3px;
}
.form .inputText > .quill > .ql-toolbar,
.form .inputText > .quill > .ql-container {
	border-color: var(--lightGraySkyTill);
}
.form .inputText > .quill svg {
	padding: 0;
}
.form .inputText svg:not(.loader) {
	display: block;
	float: right;
	width: 19px;
	height: 19px;
	margin: -39px 5px 0 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
	fill: var(--darkBlueSkyTill);
}
.form .inputText svg:not(.loader):hover {
	fill: var(--blueSkyTill);
}
.form .inputText.whiteForeground svg {
	fill: var(--whiteSkyTill);
}
.form .inputText.productPrice svg,
.form .inputText.productVat svg {
	margin-top: 8px;
}
.form .inputText .searchInput {
	display: block;
	float: right;
	margin-top: -39px;
	margin-right: 5px;
}
.form .inputText .searchInput.decal {
	margin-right: 40px;
}
.form .inputText .toUppercase {
	display: block;
	float: right;
	margin-top: -39px;
	margin-right: 5px;
	transition: all .15s;
}
.form .inputText .toUppercase.decal {
	margin-right: 40px;
}
.form .inputText .toBold {
	display: block;
	float: right;
	margin-top: -39px;
	margin-right: 5px;
	transition: all .15s;
}
.form .inputText.headerText .toBold {
	width: 18px;
	float: left;
	margin-top: -39px;
	margin-left: 5px;
	fill: var(--darkGraySkyTill);
}
.form .inputText.headerText .toBold:hover {
	fill: var(--darkerGraySkyTill);
}
.form .inputText > .containerVariablePrice {
	display: flex;
	justify-content: space-between;
	float: right;
	width: 220px;
	height: 37px;
	margin: -38px 1px 0 0;
	padding: 0 10px;
	border-radius: 0 3px 3px 0;
	background: var(--lightGraySkyTill);
}
.form .inputText > .containerVariablePrice > p {
	display: block;
	height: 18px;
	margin: 0;
	padding: 0;
	font-size: 9pt;
	text-align: left;
	line-height: 37px;
	color: var(--darkerGraySkyTill);
	cursor: pointer;
}
.form .inputText > .containerVariablePrice > .react-switch {
	margin: 8px 0 0 0;
	padding: 0;
}

.form .inputText > .containerCode {
	display: flex;
	float: left;
	width: calc(100% - 45px);
	flex-flow: nowrap;
	flex-direction: row;
	justify-content: space-between;
	margin: 0;
	padding: 0;
}
.form .inputText > .containerCode.extend {
	float: none;
	width: auto;
}
.form .inputText > .containerCode > .code {
	flex: auto;
	margin: 0 10px;
	padding: 0;
	border-radius: 5px;
	font-size: var(--fontSizeInputText);
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
	transition: all .15s;
}
.form .inputText > .containerCode > .code:first-child {
	margin-left: 0;
}
.form .inputText > .containerCode > .code:last-child {
	margin-right: 0;
}
.form .inputText.inline > .containerCode > .code {
	border-radius: 0;
	margin: 0;
	border-right: 1px solid var(--lighterGraySkyTill);
}
.form .inputText.inline > .containerCode > .code:last-child {
	border-right: 0;
}

.form .inputText.inline {
	width: 100%;
	height: 40px;
	margin: 0;
}
.form .inputText.inline input,
.form .inputText.inline select {
	height: 40px;
	border-radius: 0;
	border: none;
}
.form .inputText.inline input[type="text"].extend, .form .inputText input[type="password"].extend {
	width: calc(100% - 20px);
}
.form .inputText.inline select {
	width: 100%;
}
.form .inputText.inline input.error,
.form .inputText.inline select.error {
	background-color: var(--redAlphaSkyTill);
}

.form .inputText > .smartList {
	display: none;
	z-index: 104;
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 250px;
	margin: 10px 0 0 0;
	padding: 0;
	list-style-type: none;
	background-color: var(--lighterGraySkyTill);
	border-radius: 3px;
	transition: all 0.1s;
	box-shadow: 0 2px 3px var(--darkerGraySkyTill);
}
.form .inputText > input:focus ~ .smartList,
.form .inputText > .smartList:hover {
	display: block;
}
.form .inputText > .smartList > li {
	display: block;
	margin: 0;
	padding: 12px 15px;
	font-size: var(--fontSizeGeneralText);
	line-height: 20px;
	word-break: break-word;
	cursor: pointer;
	transition: all 0.1s;
}
.form .inputText > .smartList > li:hover {
	background-color: var(--lightGraySkyTill);
}

.form .inputText .maxChars {
	display: block;
	float: right;
	margin: -25px 0 0 0;
	padding: 0 15px 0 0;
	font-size: 8pt;
}
.form .inputText.displayText .maxChars {
	color: var(--whiteSkyTill);
}
.form .inputText.headerText .maxChars {
	color: var(--darkGraySkyTill);
}

.form .inputColor {
	display: block;
	width: 100%;
	min-width: 200px;
	margin: 0;
	padding: 0;
}
.form .inputColor.left {
	float: left;
	width: calc(50% - 10px);
}
.form .inputColor.right {
	float: right;
	width: calc(50% - 10px);
}
.form .inputColor.clear {
	clear: both;
}
.form .inputColor.inline,
.form .inputColor.inline > .color {
	margin: 0;
	border-radius: 0;
	border: none;
	height: 38px;
}
.form .inputColor > .color {
	display: block;
	position: relative;
	float: left;
	width: calc(100% - 39px);
	margin: 5px 0 0 0;
	padding: 0 37px 0 0;
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	line-height: 37px;
	text-transform: uppercase;
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--whiteSkyTill);
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	transition: all .25s;
}
.form .inputColor:not(.inline) > .color:hover {
	border-color: var(--darkBlueSkyTill);
	background-color: var(--backgroundColorInputHover);
}
.form .inputColor > .color.select {
	outline: none;
	border-color: var(--borderColorInputHover);
	background-color: var(--backgroundColorInputHover);
}
.form .inputColor > .color.wrong {
	border-color: rgb(195, 48, 48);
}
.form .inputColor > .color > .text {
	height: 37px;
	margin: 0;
	padding: 0;
}
.form .inputColor > .color > .text > p {
	display: block;
	float: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: calc(100% - 70px);
	height: 100%;
	margin: 0;
	padding: 0 0 0 15px;
	font-size: var(--fontSizeGeneralText);
	line-height: 37px;
}
.form .inputColor > .color > .text > .puce {
	display: block;
	overflow: hidden;
	position: absolute;
	right: 45px;
	top: 8px;
	width: 18px;
	height: 18px;
	margin: 0;
	padding: 0;
	border-radius: 12px;
	border: 1px solid var(--darkGraySkyTill);
	cursor: pointer;
}
.form .inputColor > .color > .text > .trash {
	display: block;
	overflow: hidden;
	position: absolute;
	right: 10px;
	top: 8px;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;
	cursor: pointer;
	transition: all .15s;
	fill: var(--redSkyTill);
}
.form .inputColor > .color > .text > .trash:hover {
	fill: var(--redHoverSkyTill);
}
.form .inputColor > .color > .palette {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin: 10px 15px;
	padding: 0;
}
.form .inputColor.inline > .color > .palette {
	display: none;
}
.form .inputColor > .color > .palette > .puce {
	width: 18px;
	height: 18px;
	border-radius: 10px;
	margin: 7px 10px 0 0;
	cursor: pointer;
	border: 1px solid var(--whiteSkyTill);
	transition: all .25s;
}
.form .inputColor > .color > .palette > .puce.radial {
	background: conic-gradient(
			hsl(360, 100%, 50%),
			hsl(315, 100%, 50%),
			hsl(270, 100%, 50%),
			hsl(225, 100%, 50%),
			hsl(180, 100%, 50%),
			hsl(135, 100%, 50%),
			hsl(90, 100%, 50%),
			hsl(45, 100%, 50%),
			hsl(0, 100%, 50%)
	);
}
.form .inputColor > .color > .palette > .puce:hover {
	border-color: var(--darkerGraySkyTill);
	filter: brightness(60%);
}
.form .inputColor svg {
	display: block;
	float: left;
	width: 19px;
	height: 19px;
	margin: 5px 0 0 0;
	padding: 10px 9px;
	cursor: pointer;
	transition: all 0.15s;
	fill: var(--blueSkyTill);
}
.form .inputColor svg:hover {
	fill: var(--darkBlueSkyTill);
}
.form .inputColor > .popover {
	position: fixed;
	z-index: 102;
	margin-top: 45px;
}
.form .inputColor > .popover > .cover {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.form .inputLibrary {
	display: block;
	width: 100%;
	height: 39px;
	margin: 0;
	padding: 0;
}
.form .inputLibrary.left {
	float: left;
	width: calc(50% - 10px);
}
.form .inputLibrary.right {
	float: right;
	width: calc(50% - 10px);
}
.form .inputLibrary.clear {
	clear: both;
}
.form .inputLibrary > .textIcon {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	position: relative;
	width: calc(100% - 52px);
	height: 37px;
	margin: 5px 0 0 0;
	padding: 0 37px 0 15px;
	border-radius: var(--borderRadiusInputText);
	background-color: #DCDCDC;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	transition: all 0.15s;
}
.form .inputLibrary > .textIcon:hover {
	background-color: var(--backgroundColorInputHover);
}
.form .inputLibrary > .textIcon.select {
	outline: none;
	background-color: #DCDCDC;
}
.form .inputLibrary > .textIcon.wrong {
	border-color: rgb(195, 48, 48);
}
.form .inputLibrary > .textIcon > span {
	cursor: pointer;
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	line-height: 37px;
}
.form .inputLibrary > .textIcon.placeholder > span {
	color: var(--colorTextInputPlaceholder);
}
.form .inputLibrary > .textIcon > .icon {
	display: block;
	width: 20px;
	height: 20px;
	margin: 8px 12px 0 0;
	padding: 0;
	border-radius: 10px;
	box-shadow: 0 0 1px rgb(100, 100, 100);
	cursor: pointer;
}
.form .inputLibrary > .textIcon > .trash {
	display: block;
	overflow: hidden;
	position: absolute;
	right: 10px;
	top: 8px;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;
	cursor: pointer;
	transition: all .15s;
	fill: var(--redSkyTill);
}
.form .inputLibrary > .textIcon > .trash:hover {
	fill: var(--redHoverSkyTill);
}
.form .inputLibrary > .popover {
	position: absolute;
	z-index: 101;
}
.form .inputLibrary > .popover > .cover {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.form .inputLibrary > .popover > .library {
	position: absolute;
	z-index: 101;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 0;
	max-height: 190px;
	margin-top: -3px;
	padding: 10px 0 0 10px;
	background-color: #DCDCDC;
	border-radius: var(--borderRadiusInputLibrary);
	transition: all 0.15s;
	animation: opacityUp 0.15s ease;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
}
.form .inputLibrary > .popover > .library > .itemLib {
	overflow: hidden;
	width: calc(100% / 4 - 10px);
	height: 70px;
	margin: 0 10px 10px 0;
	padding: 0;
	cursor: pointer;
	border-radius: 5px;
	transition: all 0.15s;
}
.form .inputLibrary > .popover > .library > .itemLib:hover {
	background-color: #C8C8C8;
}
.form .inputLibrary > .popover > .library > .itemLib > img {
	display: block;
	width: 40px;
	height: 40px;
	margin: 15px auto 0 auto;
	padding: 0;
}
@keyframes opacityUp {
	from {
		opacity: 0;
		filter: alpha(opacity=0);
	}
	to {
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

.form .inputQuantifier {
	display: flex;
	margin: 0;
	padding: 0;
}
.form .inputQuantifier.marginTop {
	margin-top: 30px;
}
.form .inputQuantifier.marginBottom {
	margin-bottom: 30px;
}
.form .inputQuantifier .text {
	flex: auto;
	width: calc(100% - 160px);
	margin: 0;
	padding: 0;
	font-size: 10pt;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
}
.form .inputQuantifier .text strong {
	font-size: 24pt;
	line-height: 50px;
}
.form .inputQuantifier .down, .form .inputQuantifier .up {
	flex: auto;
	width: 80px;
	margin: 0;
	padding: 0;
}
.form .inputQuantifier .down svg, .form .inputQuantifier .up svg {
	display: block;
	width: 20px;
	height: 20px;
	margin: 15px auto;
	cursor: pointer;
	transition: all .1s;
}
.form .inputQuantifier .down svg:hover, .form .inputQuantifier .up svg:hover {
	fill: #111111;
}

.form .inputSwitch {
	height: 30px;
	margin: 0;
}
.form .inputSwitch.marginLR {
	margin-left: 10px;
	margin-right: 10px;
}
.form .inputSwitch.marginTop {
	margin-top: 30px;
}
.form .inputSwitch.marginBottom {
	margin-bottom: 30px;
}
.form .inputSwitch.marginTB {
	margin-top: 0;
	margin-bottom: 20px;
}
.form .inputSwitch > label {
	display: block;
	width: 100%;
	margin: 15px 0 0 0;
	padding: 0;
	cursor: pointer;
}
.form .inputSwitch.inline > label {
	margin: 0 auto;
}
.form .inputSwitch > label > span {
	display: block;
	float: left;
	width: calc(75% - 10px);
	margin: 0;
	padding: 5px 0 0 10px;
	font-size: 9pt;
	text-align: left;
	line-height: 20px;
	color: rgb(100, 100, 100);
}
.form .inputSwitch.inline > label > span {
	display: none;
}
.form .inputSwitch > label > span.checked {
	font-family: Montserrat-Black, Arial, sans-serif;
}
.form .inputSwitch > label > .react-switch {
	float: right;
	margin: 4px 15px 0 0;
	padding: 0;
}
.form .inputSwitch.inline > label > .react-switch {
	margin: 4px 30px;
	padding: 0;
}

.form .inputMultiLinesChoices {
	overflow-x: hidden;
	overflow-y: auto;
	width: calc(100% - 2px);
	height: 200px;
	margin: 0;
	padding: 0;
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
}
.form .inputMultiLinesChoices.minimalHeight {
	height: 120px;
	max-height: 120px;
}
.form .inputMultiLinesChoices.noMarginTop {
	margin-top: 5px;
}
.form .inputMultiLinesChoices.auto {
	height: auto;
}
.form .inputMultiLinesChoices > .wrap {
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	height: 200px;
	max-height: 200px;
	margin: 0;
	padding: 0;
	border-top: var(--borderInput);
}
.form .inputMultiLinesChoices.minimalHeight > .wrap {
	height: 120px;
	max-height: 120px;
}
.form .inputMultiLinesChoices > .wrap.search {
	height: 163px;
	max-height: 163px;
}
.form .inputMultiLinesChoices.minimalHeight > .wrap.search {
	height: 83px;
	max-height: 83px;
}
.form .inputMultiLinesChoices .choiceMultiLine {
	display: block;
	clear: both;
	width: 100%;
	height: 50px;
	margin: 0;
	padding: 0;
	transition: all 0.15s;
	border-bottom: var(--borderBottomListChoicesChoice);
	cursor: pointer;
}
.form .inputMultiLinesChoices .choiceMultiLine.selected {
	background-color: var(--lightBlueAlphaSkyTill);
}
.form .inputMultiLinesChoices > input[type="text"] {
	border: none;
	margin: 0;
	width: calc(100% - 20px);
	border-radius: 0;
	border-bottom: 1px solid var(--lightGraySkyTill);
}
.form .inputMultiLinesChoices .choiceMultiLine:last-child {
	border-bottom: none;
}
.form .inputMultiLinesChoices .choiceMultiLine:not(.selected):hover {
	background-color: var(--backgroundColorInputHover);
}
.form .inputMultiLinesChoices .choiceMultiLine .contChecboxChoice {
	float: left;
	overflow: hidden;
	width: 40px;
	height: 50px;
	margin: 0;
	padding: 0;
}
.form .inputMultiLinesChoices .choiceMultiLine .contChecboxChoice .checkboxChoice {
	width: 18px;
	height: 18px;
	margin: 16px 0 0 -20px;
	padding: 0;
	fill: var(--mainColor);
	transition: all 0.10s;
}
.form .inputMultiLinesChoices .choiceMultiLine .contChecboxChoice .checkboxChoice.selected {
	margin-left: 10px;
}
.form .inputMultiLinesChoices .choiceMultiLine .contTextChoice {
	float: left;
	width: calc(100% - 40px);
	height: 50px;
	margin: 0;
	padding: 0;
}
.form .inputMultiLinesChoices .choiceMultiLine .contTextChoice > .primary {
	display: block;
	width: 100%;
	margin: 0;
	padding: 7px 0 0 0;
	text-align: left;
	font-size: 10pt;
	line-height: 20px;
	color: var(--textColor);
}
.form .inputMultiLinesChoices .choiceMultiLine .contTextChoice > .primary.middle {
	padding: 0;
	line-height: 50px;
}
.form .inputMultiLinesChoices .choiceMultiLine .contTextChoice .secondary {
	width: 100%;
	margin: 0;
	padding: 0;
	text-align: left;
	font-size: var(--fontSizeGeneralText);
	color: var(--textColor);
}

.form .inputPricelist {
	display: block;
	margin: 0;
	padding: 0;
}
.form .inputPricelist .header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height: 60px;
	margin: 0 0 0 151px;
	padding: 0;
	background-color: var(--lightGraySkyTill);
	border: 1px solid var(--lightGraySkyTill);
	border-radius: 5px 5px 0 0;
}
.form .inputPricelist.byItem .header {
	margin-left: 201px;
}
.form .inputPricelist .header.small {
	height: 35px;
}
.form .inputPricelist .header > .contPriceTitles {
	display: flex;
	flex-direction: column;
	width: calc(100% - 160px);
	margin: 0;
	padding: 0;
}
.form .inputPricelist .header > .contPriceTitles > .contSubPriceTitles {
	display: flex;
	flex-direction: row;
	height: 25px;
	margin: 0;
	padding: 0;
}
.form .inputPricelist .header > .vatTitle,
.form .inputPricelist .header > .contPriceTitles > .priceTitle,
.form .inputPricelist .header > .contPriceTitles > .contSubPriceTitles > .ruleTitle,
.form .inputPricelist .header > .contPriceTitles > .contSubPriceTitles > .unitPriceTitle {
	margin: 0;
	padding: 0;
	font-size: var(--fontSizeGeneralText);
	font-weight: bold;
	color: var(--darkerGraySkyTill);
}
.form .inputPricelist .header > .vatTitle {
	width: 160px;
	text-align: center;
	line-height: 60px;
	border-right: 1px solid var(--lighterGraySkyTill);
}
.form .inputPricelist.byItem .header.small > .vatTitle {
	width: 120px;
}
.form .inputPricelist .header.small > .vatTitle {
	line-height: 35px;
}
.form .inputPricelist .header > .contPriceTitles > .priceTitle {
	height: 35px;
	text-align: center;
	line-height: 35px;
	border-bottom: 1px solid var(--lighterGraySkyTill);
}
.form .inputPricelist .header.small > .contPriceTitles > .priceTitle {
	border-bottom: none;
}
.form .inputPricelist .header > .contPriceTitles > .contSubPriceTitles > .ruleTitle,
.form .inputPricelist .header > .contPriceTitles > .contSubPriceTitles > .unitPriceTitle {
	text-align: center;
	line-height: 25px;
}
.form .inputPricelist .header > .contPriceTitles > .contSubPriceTitles > .ruleTitle {
	width: calc(100% - 101px);
	border-right: 1px solid var(--lighterGraySkyTill);
}
.form .inputPricelist .header > .contPriceTitles > .contSubPriceTitles > .unitPriceTitle {
	width: 100px;
}
.form .inputPricelist > .contScroll {
	overflow-x: auto;
	overflow-y: auto;
	width: calc(100% - 2px);
	height: calc(100% - 140px);
	min-height: calc(100% - 140px);
	max-height: calc(100% - 140px);
	margin: 0;
	border-radius: 5px 0 5px 5px;
	border: 1px solid var(--lightGraySkyTill);
	border-top: none;
}
.form .inputPricelist > .contScroll.noBorder {
	border: none;
	border-radius: 5px;
}
.form .inputPricelist .lineList {
	display: block;
	overflow: hidden;
	height: 100px;
	min-height: 100px;
	border-right: 1px solid var(--lightGraySkyTill);
	transition: background-color 0.15s, height 0.25s;
}
.form .inputPricelist .lineList.first {
	border-radius: 5px 0 0 0;
}
.form .inputPricelist .lineList.last {
	border-bottom: 1px solid var(--lightGraySkyTill);
	border-radius: 0 0 5px 5px;
}
.form .inputPricelist .lineList.noBorder {
	border: none;
}
.form .inputPricelist .lineList:hover {
	background-color: var(--whiteAlphaSkyTill);
}
.form .inputPricelist .lineList .contStores {
	margin: 0;
	padding: 0;
}
.form .inputPricelist .lineList .contLineList {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	border-top: 1px solid var(--lightGraySkyTill);
	font-size: var(--fontSizeGeneralText);
}
.form .inputPricelist .lineList .contLineList > .color {
	min-width: 10px;
	max-width: 10px;
	width: 10px;
}
.form .inputPricelist .lineList .contLineList .name {
	position: relative;
	display: block;
	overflow: hidden;
	width: 140px;
	min-width: 140px;
	max-width: 140px;
	padding: 0;
	margin: 0;
	border-left: 1px solid var(--lightGraySkyTill);
	border-right: 1px solid var(--lightGraySkyTill);
}
.form .inputPricelist.byItem .lineList .contLineList .name {
	width: 200px;
	min-width: 200px;
	max-width: 200px;
}
.form .inputPricelist .lineList .contLineList .name.noBorderL {
	border-left: none;
}
.form .inputPricelist .lineList .contLineList .name > .value {
	padding: 0 15px 0 10px;
	margin: 32px 0 0 0;
	text-align: right;
	text-overflow: ellipsis;
	font-weight: bold;
	line-height: 20px;
}
.form .inputPricelist .lineList .contLineList .name > .value.solo {
	display: table-cell;
	width: 125px;
	height: 100px;
	vertical-align: middle;
	margin: 0;
	text-align: right;
}
.form .inputPricelist .lineList .contLineList .name > .desc {
	padding: 0 15px 0 10px;
	margin: 0;
	text-align: right;
	text-overflow: ellipsis;
	font-style: italic;
	font-size: 7pt;
	color: var(--darkerGraySkyTill);
	transition: all .15s;
}
.form .inputPricelist .lineList .contLineList .name > .desc.margin {
	margin-top: 5px;
}
.form .inputPricelist .lineList .contLineList .name > .desc:not(.store) {
	cursor: pointer;
}
.form .inputPricelist .lineList .contLineList .name > .desc:not(.store):hover {
	text-decoration: underline;
	color: var(--blackSkyTill);
}
.form .inputPricelist .lineList .contLineList .name > .groupBy {
	position: absolute;
	width: 140px;
	padding: 0;
	margin: 5px 0 0 0;
	text-align: center;
	text-overflow: ellipsis;
	font-style: italic;
	font-size: 7pt;
	color: var(--darkerGraySkyTill);
	cursor: pointer;
	transition: all .15s;
}
.form .inputPricelist .lineList .contLineList .name > .groupBy:hover {
	text-decoration: underline;
	color: var(--blackSkyTill);
}
.form .inputPricelist .lineList .contLineList > .containerPerStore {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	width: 350px;
	padding: 0;
	margin: 0;
}
.form .inputPricelist .lineList .contLineList > .containerPerStore.unlimited {
	width: 100%;
}
.form .inputPricelist .lineList .contLineList .containerVertical {
	margin: 0;
	padding: 0;
}
.form .inputPricelist .lineList .contLineList .containerVertical > .top,
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom {
	flex-direction: row;
	margin: 0;
	padding: 0;
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom.flex {
	display: flex;
}
.form .inputPricelist .lineList .contLineList .containerVertical > .top {
	height: 55px;
	padding: 15px 0 0 0;
}
.form .inputPricelist .lineList .contLineList .containerVertical > .top.rowspan {
	height: 70px;
	padding: 30px 0 0 0;
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom {
	height: 29px;
	background-color: var(--light2GraySkyTill);
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom p {
	display: block;
	float: left;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: calc(100% - 40px);
	margin: 0;
	padding: 0 10px 0 6px;
	line-height: 30px;
	font-size: 7pt;
	text-align: left;
	color: var(--redSkyTill);
	text-decoration: line-through;
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom p.right {
	float: right;
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom p.extend {
	max-width: calc(100% - 16px);
	text-decoration: none;
	color: var(--textColor);
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom .many {
	display: block;
	float: right;
	width: 0;
	height: 0;
	margin: 3px 5px 0 0;
	padding: 12px;
	background-image: url(../../image/ressource/down-arrow.svg);
	background-repeat: no-repeat;
	background-size: 10px 10px;
	background-position: center center;
	cursor: pointer;
	border: none;
	border-radius: 20px;
	transition: all .15s;
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom .many.revert {
	transform: rotate(180deg);
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom .many:hover {
	background-color: var(--darkGraySkyTill);
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom svg {
	display: block;
	float: right;
	width: 18px;
	height: 18px;
	margin: 6px 6px 0 0;
	padding: 0;
	cursor: pointer;
	transition: all .15s;
	transform: rotate(90deg);
}
.form .inputPricelist .lineList .contLineList .containerVertical > .bottom svg:hover {
	fill: var(--blueSkyTill);
}
.form .inputPricelist .lineList .contLineList .vatRate {
	min-width: 159px;
	max-width: 159px;
	width: 159px;
	text-align: center;
	border-right: 1px solid var(--lightGraySkyTill);
}
.form .inputPricelist.byItem .lineList .contLineList .vatRate {
	min-width: 119px;
	max-width: 119px;
	width: 119px;
}
.form .inputPricelist .lineList .contLineList .rule {
	width: calc(100% - 1px);
	border-right: 1px solid var(--lightGraySkyTill);
}
.form .inputPricelist .lineList .contLineList .rule.noBorderR {
	border-right: none;
}
.form .inputPricelist .lineList .contLineList > .containerPerStore.unlimited > .rule {
	border-right: none;
}
.form .inputPricelist .lineList .contLineList .rule.locked {
	background: repeating-linear-gradient(
			45deg,
			#E6E6E6,
			#E6E6E6 10px,
			#C8C8C8 10px,
			#C8C8C8 20px
	);
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	align-content: space-between;
	width: calc(100% - 30px);
	height: 39px;
	margin: 0 auto;
	padding: 0;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator {
	display: block;
	width: 84px;
	margin: 0;
	padding: 0;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .value {
	display: block;
	width: calc(100% - 203px);
	margin: 0 10px;
	padding: 0;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type {
	display: block;
	width: 56px;
	margin: 0;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator.alone {
	display: block;
	width: 28px;
	margin-right: 0;
	margin-left: 56px;
	visibility: hidden;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator.perCategories {
	width: 56px;
	margin-left: 28px;
	visibility: visible;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type.alone {
	display: block;
	width: 28px;
	margin-left: 0;
	margin-right: 28px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .reinit,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .remove {
	display: block;
	width: 28px;
	margin-left: 15px;
	margin-right: 0;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .add {
	display: block;
	width: 28px;
	margin: auto;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type {
	margin-top: 6px;
	height: 28px;
	background-color: var(--lightGraySkyTill);
	border-radius: 14px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator > .texts,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type > .texts {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	align-content: stretch;
	height: 28px;
	margin: -28px 0 0 0;
	padding: 0;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator > .texts {
	width: 84px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type > .texts {
	width: 56px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator > .texts > p,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type > .texts > p {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 9pt;
	line-height: 28px;
	text-align: center;
	cursor: pointer;
	border-radius: 14px;
	transition: all .15s;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator > .texts > p.notAllowed,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type > .texts > p.notAllowed {
	cursor: not-allowed;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator > .texts > p:not(.notAllowed):hover,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type > .texts > p:not(.notAllowed):hover {
	font-weight: bolder;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator > .texts > p {
	width: calc(100% / 3);
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type > .texts > p {
	width: calc(100% / 2);
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator > .bubble,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type > .bubble {
	display: block;
	position: relative;
	width: 26px;
	height: 26px;
	margin: 0;
	padding: 0;
	border-radius: 14px;
	border-width: 1px;
	border-style: solid;
	transition: all .2s;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator > .bubble.two,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type > .bubble.two {
	margin-left: 28px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator > .bubble.three,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .type > .bubble.three {
	margin-left: 56px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator.perCategories > .bubble.two {
	margin-left: 0px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .variationOperator.perCategories > .bubble.three {
	margin-left: 28px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .reinit > .button,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .remove > .button {
	display: block;
	overflow: hidden;
	width: 28px;
	height: 28px;
	margin: 5px 0 0 0;
	padding: 0;
	border-radius: 14px;
	cursor: pointer;
	background-color: var(--lightGraySkyTill);
	transition: all .15s;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .add > .button {
	display: block;
	overflow: hidden;
	width: 28px;
	height: 28px;
	margin: 5px 0 0 0;
	padding: 0;
	border-radius: 14px;
	cursor: pointer;
	background-color: var(--darkBlueSkyTill);
	transition: all .15s;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .reinit > .button:hover {
	background-color: var(--darkBlueSkyTill);
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .remove > .button:hover {
	background-color: var(--redSkyTill);
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .add > .button:hover {
	background-color: var(--blueSkyTill);
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .reinit > .button > svg {
	display: block;
	width: 12px;
	height: 12px;
	margin: 0;
	padding: 8px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .remove > .button > svg {
	display: block;
	width: 10px;
	height: 10px;
	margin: 0;
	padding: 9px;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .add > .button > svg {
	display: block;
	width: 11px;
	height: 11px;
	margin: 0;
	padding: 9px;
	fill: #ffffff;
}
.form .inputPricelist .lineList .contLineList .rule .variationContainer .reinit > .button:hover > svg,
.form .inputPricelist .lineList .contLineList .rule .variationContainer .remove > .button:hover > svg {
	fill: #FFFFFF;
}
.form .inputPricelist .lineList .contLineList .price {
	min-width: 100px;
	max-width: 100px;
	width: 100px;
}
.form .inputPricelist .lineList .contLineList .price > .value {
	display: inline-block;
	height: 39px;
	width: calc(100% - 60px);
	margin: 30px 15px 0 15px;
	padding: 0 15px;
	font-size: var(--fontSizeGeneralText);
	text-align: center;
	line-height: 39px;
	border-radius: 3px;
	cursor: not-allowed;
}

.form .inputTag {
	display: flex;
	width: calc(100% - 2px);
	min-height: 37px;
	margin: 0;
	padding: 0;
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderInput);
	background-color: var(--backgroundColorInput);
}
.form .inputTag > .containerTag {
	display: block;
	height: 100%;
	margin: 0;
	padding: 0 0 5px 5px;
}
.form .inputTag > .containerTag.buttonWithTitle {
	width: calc(100% - 75px);
}
.form .inputTag > .containerTag.buttonSvg {
	width: calc(100% - 25px);
}
.form .inputTag > .containerTag > .tag {
	display: block;
	float: left;
	margin: 5px 5px 0 0;
	padding: 0 10px;
	border-radius: 3px;
	background-color: var(--darkBlueSkyTill);
}
.form .inputTag > .containerTag > .tag > p {
	display: block;
	float: left;
	margin: 0;
	padding: 0;
	font-size: 9pt;
	line-height: 25px;
	color: var(--whiteSkyTill);
}
.form .inputTag > .containerTag > .tag > svg {
	float: left;
	width: 10px;
	height: 10px;
	margin: 8px 0 0 15px;
	padding: 0;
	fill: var(--whiteSkyTill);
	cursor: pointer;
	transition: all .15s;
}
.form .inputTag > .containerTag > .tag > svg:hover {
	transform: rotate(90deg);
}
.form .inputTag > .containerTag input[type="text"] {
	display: block;
	float: left;
	width: 100px;
	height: 27px;
	margin: 0;
	background-color: transparent;
	border-radius: 0;
	border: none;
	font-size: 9pt;
	text-align: left;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}
.form .inputTag > .button {
	display: block;
	width: 70px;
	height: 25px;
	margin: 5px 5px 0 0;
	padding: 0;
	font-size: 9pt;
	line-height: 25px;
	text-align: center;
	background-color: var(--darkerGraySkyTill);
	color: var(--whiteSkyTill);
	border-radius: 3px;
	transition: all .15s;
	cursor: pointer;
}
.form .inputTag > svg {
	display: block;
	width: 20px;
	height: 20px;
	margin: 7px 5px 0 0;
	padding: 0;
	fill: var(--darkerGraySkyTill);
	transition: all .15s;
	cursor: pointer;
}
.form .inputTag > .button:hover {
	background-color: var(--darkGraySkyTill);
}
.form .inputTag > svg:hover {
	fill: var(--darkGraySkyTill);
}
.form .listTag {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.form .listTag > li.empty {
	padding-top: 15px;
	font-size: 10pt;
	color: var(--darkGraySkyTill);
}
.form .listTag > li.choice {
	font-size: 10pt;
}

.form .inputBidirectionalList {
	overflow: hidden;
	display: flex;
	margin: 0;
	padding: 0;
	transition: all .1s;
}
.form .inputBidirectionalList > .contList {
	width: calc((100% / 2) - 8px);
	margin: 0;
	padding: 0;
}
.form .inputBidirectionalList > .contList:first-child {
	margin-right: 8px;
}
.form .inputBidirectionalList > .contList:last-child {
	margin-left: 8px;
}
.form .inputBidirectionalList > .contList > ul {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	min-height: calc(100% - 100px);
	max-height: calc(100% - 100px);
	list-style-type: none;
	margin: 0;
	padding: 0;
	border: 1px solid var(--light2GraySkyTill);
}
.form .inputBidirectionalList > .contList > ul > li {
	margin: 0;
	padding: 12px 15px;
	font-size: var(--fontSizeGeneralText);
	text-align: left;
	line-height: 22px;
	border-bottom: 1px solid var(--light2GraySkyTill);
	cursor: pointer;
	transition: all .15s;
}
.form .inputBidirectionalList > .contList > ul > li.red {
	background-color: var(--redAlphaSkyTill);
}
.form .inputBidirectionalList > .contList > ul > li.empty {
	color: var(--darkGraySkyTill);
}
.form .inputBidirectionalList > .contList > ul > li:last-child {
	border-bottom: none;
}
.form .inputBidirectionalList > .contList > ul > li:not(.empty):hover {
	background-color: var(--lighterBlueSkyTill);
}
.form .inputBidirectionalList > .contList > ul > li.red:hover {
	background-color: var(--redHoverSkyTill);
}
.form .inputBidirectionalList > .contList > .button {
	display: block;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	width: calc(100% - 32px);
	height: 38px;
	margin: 15px 0 0 0;
	padding: 0 15px;
	font-size: var(--fontSizeGeneralText);
	line-height: 38px;
	text-align: center;
	background-color: var(--light2GraySkyTill);
	border: 1px solid var(--lightGraySkyTill);
	border-radius: 3px;
	cursor: pointer;
	transition: all .15s;
}
.form .inputBidirectionalList > .contList > .button:hover {
	background-color: var(--lightGraySkyTill);
}

.form .inputBarcode {
	display: block;
	margin: 0;
	padding: 0;
	transition: all .25s;
}
.form .inputBarcode > ul {
	clear: both;
	display: block;
	list-style-type: none;
	margin: 15px 0 0 0;
	padding: 0;
	border: 1px solid var(--light2GraySkyTill);
	border-radius: 3px;
}
.form .inputBarcode > ul > li {
	margin: 0;
	padding: 12px 15px;
	font-size: var(--fontSizeGeneralText);
	text-align: left;
	line-height: 22px;
	border-bottom: 1px solid var(--light2GraySkyTill);
	transition: all .15s;
}
.form .inputBarcode > ul > li.red {
	background-color: var(--redAlphaSkyTill);
}
.form .inputBarcode > ul > li.empty {
	color: var(--darkGraySkyTill);
}
.form .inputBarcode > ul > li:last-child {
	border-bottom: none;
}
.form .inputBarcode > ul > li:not(.empty):hover {
	background-color: var(--lighterBlueSkyTill);
}
.form .inputBarcode > ul > li.red:hover {
	background-color: var(--redHoverSkyTill);
}
.form .inputBarcode > ul > li > .wrapBarcodePicture {
	display: block;
	float: left;
	width: 130px;
	height: 50px;
	margin: 0;
	padding: 10px 15px;
	border: 1px solid var(--lightGraySkyTill);
	border-radius: 3px;
	background-color: var(--whiteSkyTill);
}
.form .inputBarcode > ul > li > .wrapBarcodePicture > svg {
	width: 100%;
	height: 100%;
}
.form .inputBarcode > ul > li > .wrapBarcodeText {
	display: block;
	float: left;
	margin: 0 15px;
	padding: 0;
}
.form .inputBarcode > ul > li > .wrapBarcodeText > .barcode {
	margin: 18px 0 0 0;
	padding: 0;
	font-size: 10pt;
	line-height: 20px;
	font-weight: bold;
}
.form .inputBarcode > ul > li > .wrapBarcodeText > .date {
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 20px;
	color: var(--darkGraySkyTill);
}
.form .inputBarcode > ul > li > svg {
	display: block;
	float: right;
	width: 24px;
	height: 24px;
	margin: 23px 10px 0 0;
	fill: var(--redSkyTill);
	cursor: pointer;
	transition: all .15s;
}
.form .inputBarcode > ul > li > svg:hover {
	fill: var(--redHoverSkyTill);
}
.form .inputBarcode > .inputText {
	display: block;
	float: left;
	width: calc(100% - 100px);
	margin: 5px 0 0 0;
	padding: 0;
}
.form .inputBarcode > .inputText > input[type="text"] {
	border: 1px solid var(--darkGraySkyTill)
}
.form .inputBarcode > .inputText > input[type="text"]:hover,
.form .inputBarcode > .inputText > input[type="text"]:focus {
	border: 1px solid var(--darkBlueSkyTill)
}
.form .inputBarcode > svg {
	display: block;
	float: right;
	width: 20px;
	height: 20px;
	margin: 15px 10px 0 20px;
	fill: var(--darkBlueSkyTill);
	cursor: pointer;
	transition: all .15s;
}
.form .inputBarcode > svg:hover {
	fill: var(--blueSkyTill);
}

.form .belowLogin {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 20px 0 0 0;
}

.form .labelCheckbox {
	display: flex;
	margin: 0;
	padding: 0;
}
.form .labelCheckbox > #rememberMe {
	display: block;
	margin: 0 10px 0 0;
}
.form .labelCheckbox > p {
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 20px;
	text-align: left;
	color: var(--darkerGraySkyTill);
	cursor: pointer;
	transition: all .2s;
}
.form .labelCheckbox:hover > p {
	color: var(--blackSkyTill);
}
.form .labelCheckbox > input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.form .labelCheckbox > .checkmarkRememberMe {
	height: 22px;
	width: 22px;
	margin: -2px 10px 0 0;
	background-color: var(--whitySkyTill);
	border-radius: 5px;
	transition: all .15s;
}
.form .labelCheckbox:hover > input ~ .checkmarkRememberMe {
	background-color: var(--blueSkyTill);
}
.form .labelCheckbox > input:checked ~ .checkmarkRememberMe {
	background-color: var(--darkBlueSkyTill)
}
.form .labelCheckbox > .checkmarkRememberMe:after {
	content: "";
	display: none;
}
.form .labelCheckbox > input:checked ~ .checkmarkRememberMe:after {
	display: block;
}
.form .labelCheckbox .checkmarkRememberMe:after {
	margin: 4px 0 0 8px;
	width: 4px;
	height: 8px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.form .forgot {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 20px;
	text-align: right;
	color: var(--darkerGraySkyTill);
	text-decoration: none;
	transition: all 0.15s;
}
.form .forgot:hover {
	color: var(--blackSkyTill);
	text-decoration: underline;
}

.form .error, .form .error, .form .error {
	margin: 0;
	padding: 5px 10px 0 10px;
	font-size: var(--fontSizeGeneralText);
	text-align: left;
	color: rgb(195, 48, 48);
}
.form .error.left, .form .error.left, .form .error.left {
	float: left;
	width: calc(50% - 20px);
}
.form .error.right, .form .error.right, .form .error.right {
	float: right;
	width: calc(50% - 20px);
}

.wrapForm .cancel,
.form .cancel {
	display: block;
	float: right;
	margin: 20px 15px 0 0;
	padding: 0 20px;
	color: rgb(100, 100, 100);
	border-radius: 20px;
	border: none;
	cursor: pointer;
	font-size: var(--fontSizeGeneralText);
	text-transform: capitalize;
	font-weight: bold;
	line-height: 40px;
	background-color: transparent;
	transition: all 0.15s;
}
.wrapForm .cancel.left,
.form .cancel.left {
	float: left;
}
.wrapForm .cancel.noMarginTop,
.form .cancel.noMarginTop {
	margin-top: 0;
}
.wrapForm .cancel.noMarginRight,
.form .cancel.noMarginRight {
	margin-right: 0;
}
.wrapForm .cancel.solo,
.form .cancel.solo {
	margin: 20px 0 15px 0;
}
.wrapForm .cancel.align,
.form .cancel.align {
	margin-top: 30px;
}
.wrapForm .cancel.hide,
.form .cancel.hide {
	display: none;
}
.wrapForm .cancel.block,
.form .cancel.block {
	clear: both;
	float: none;
	width: 100%;
	margin: 0;
}
.wrapForm .cancel.block.margin,
.form .cancel.block.margin {
	margin: 15px 0 0 0;
}
.wrapForm .cancel.block.marginBottom,
.form .cancel.block.marginBottom {
	margin-bottom: 15px;
}
.wrapForm .cancel:hover,
.form .cancel:hover {
	color: rgb(30, 30, 30);
	background-color: var(--lightGraySkyTill);
}
.wrapForm .cancel:focus,
.form .cancel:focus {
	outline: none;
}

.form .submit {
	display: block;
	clear: both;
	float: right;
	margin: 30px 0 0 0;
	padding: 0 20px;
	line-height: 40px;
	background-color: var(--mainColor);
	color: white;
	border-radius: 20px;
	border: none;
	font-size: var(--fontSizeGeneralText);
	font-weight: bold;
	transition: all 0.15s;
}
.form .submit.marginRight {
	margin-right: 15px;
}
.form .submit.noMarginBottom {
	margin-bottom: 0;
}
.form .submit.gray {
	background-color: var(--darkGraySkyTill);
}
.form .submit.red {
	background-color: var(--backgroundColorDelete);
}
.form .submit.locked {
	opacity: 0.5;
	filter: alpha(opacity=50);
	zoom: 1;
}
.form .submit:not(.locked):hover {
	cursor: pointer;
	background-color: var(--mainColorHover);
}
.form .submit.gray:not(.locked):hover {
	background-color: var(--darkerGraySkyTill);
}
.form .submit.red:not(.locked):hover {
	background-color: var(--backgroundColorDeleteHover);
}
.form .submit:not(.locked):focus {
	outline: none;
}

.form .delete {
	display: block;
	clear: both;
	width: calc(100% - 20px);
	margin: 30px 0 0 0;
	padding: var(--paddingInputText);
	line-height: 40px;
	background-color: var(--backgroundColorDelete);
	color: white;
	border-radius: var(--borderRadiusInputText);
	border: none;
	font-size: var(--fontSizeGeneralText);
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	transition: all 0.15s;
}
.form .delete.locked {
	opacity: 0.5;
	filter: alpha(opacity=50);
	zoom: 1;
}
.form .delete:not(.locked):hover {
	cursor: pointer;
	background-color: var(--backgroundColorDeleteHover);
}
.form .delete:not(.locked):focus {
	outline: none;
}

.form .horizontalContButtons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0;
	padding: 0;
}

.form .buttonChoice {
	display: block;
	width: 100%;
	margin: 10px 0 10px 0;
	padding: 0 20px;
	line-height: 40px;
	background-color: var(--backgroundColorSubmit);
	color: var(--textColorSubmit);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	font-size: var(--fontSizeGeneralText);
	font-weight: bold;
	text-transform: uppercase;
	transition: all 0.15s;
}
.form .buttonChoice.red {
	background-color: var(--redSkyTill);
}
.form .buttonChoice.spaceTop {
	margin-top: 30px;
}
.form .buttonChoice:hover {
	background-color: var(--backgroundColorSubmitHover);
}
.form .buttonChoice:focus {
	outline: none;
}

.form .horizontalContButtons > .buttonChoice {
	line-height: 20px;
	font-size: 10pt;
	text-align: center;
	padding: 15px;
}
.form .horizontalContButtons > .buttonChoice.icon {
	padding-top: 100px;
	background-repeat: no-repeat;
	background-size: 50px 50px;
	background-position: center top 15px;
}
.form .horizontalContButtons > .buttonChoice.two {
	width: calc(50% - 8px);
}

.form .comboBoxChoice {
	display: block;
	width: calc(100% - 30px);
	margin: 0;
	padding: 3px 15px;
	border: none;
	border-bottom: 1px solid var(--lightGraySkyTill);
	cursor: pointer;
	background-color: var(--lighterGraySkyTill);
	transition: all 0.15s;
}
.form .comboBoxChoice.last {
	border-bottom: none;
}
.form .comboBoxChoice:hover {
	background-color: var(--lightGraySkyTill);
}
.form .comboBoxChoice > .puce {
	display: block;
	float: left;
	width: 16px;
	height: 16px;
	margin: 15px 15px 15px 0;
	border-radius: 8px;
	background-color: var(--whiteSkyTill);
	transition: all 0.15s;
}
.form .comboBoxChoice:hover > .puce {
	background-color: var(--mainColor);
}
.form .comboBoxChoice > .contText {
	display: block;
	float: left;
	width: calc(100% - 31px);
	height: 25px;
	margin: 0;
	padding: 0;
}
.form .comboBoxChoice > .contText > .mainTextDictionary {
	margin: 0;
	padding: 5px 0 0 0;
	line-height: 20px;
	font-size: var(--fontSizeGeneralText);
	font-weight: bold;
}
.form .comboBoxChoice > .contText > .mainTextDictionary.middle {
	padding: 0;
	line-height: 45px;
}
.form .comboBoxChoice > .contText > .secondTextDictionary {
	margin: 0;
	padding: 0;
	font-size: var(--fontSizeGeneralText);
	color: var(--darkerGraySkyTill);
}

.form .manyForm {
	display: table;
	width: 100%;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
}
.form .manyForm > tbody > tr.header {
	height: 40px;
}
.form .manyForm > tbody > tr > th {
	padding: 0 10px;
	text-transform: uppercase;
	font-size: var(--fontSizeGeneralText);
	color: var(--darkGraySkyTill);
	text-align: left;
}
.form .manyForm > tbody > tr > td {
	padding: 0;
	border: 1px solid var(--lightGraySkyTill);
	vertical-align: middle;
}
.form .manyForm > tbody > tr > th.validate,
.form .manyForm > tbody > tr > td.validate {
	border: none;
	width: 75px;
}
.form .manyForm > tbody > tr > td.validate > p {
	display: block;
	float: right;
	width: 80px;
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 40px;
	font-weight: bold;
	text-align: center;
	border-radius: 20px;
	background-color: var(--darkBlueSkyTill);
	color: var(--whiteSkyTill);
	cursor: pointer;
	transition: all 0.15s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.form .manyForm > tbody > tr > td.validate > p.disable {
	opacity: .5;
	filter: alpha(opacity=50);
	cursor: not-allowed;
}
.form .manyForm > tbody > tr > td.validate > p:hover {
	filter: brightness(80%);
}
.form .manyForm > tbody > tr > td.validate svg {
	display: block;
	float: right;
	width: 18px;
	height: 18px;
	margin: 0 31px 0 0;
	padding: 0;
	cursor: pointer;
	fill: var(--darkBlueSkyTill);
}
.form .manyForm > tbody > tr > td.name {
	min-width: 150px;
}
.form .manyForm > tbody > tr > td.category {
	min-width: 120px;
}
.form .manyForm > tbody > tr > td.catalog {
	width: 200px;
}
.form .manyForm > tbody > tr > td.vatRate {
	width: 160px;
}
.form .manyForm > tbody > tr > td.variablePrice {
	width: 100px;
	max-width: 100px;
}
.form .manyForm > tbody > tr > td.price {
	min-width: 100px;
	max-width: 100px;
}
.form .manyForm > tbody > tr > td.gender {
	min-width: 100px;
}
.form .manyForm > tbody > tr > td.reference {
	width: 250px;
}
.form .manyForm > tbody > tr > td.color {
	width: 150px;
}
.form .manyForm > tbody > tr > td.code {
	max-width: 150px;
}
.form .manyForm > tbody > tr > td.code {
	max-width: 150px;
}

.form .inputGrid {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
}
.form .inputGrid > .labelGrid {
	margin: 0;
	padding: 7px 10px 0 10px;
	font-size: 10pt;
	color: #FFFFFF;
}
.form .inputGrid > .labelGrid.left {
	text-align: right;
}
.form .inputGrid > .containerQuantifier {
	width: 30px;
	margin: 0;
	padding: 0 15px;
}
.form .inputGrid > .containerQuantifier > .value {
	margin: 0;
	padding: 0;
	color: #FFFFFF;
	font-size: 18pt;
	text-align: center;
}
.form .inputGrid > .containerQuantifier > .value.disable {
	opacity: 0.5;
	filter: alpha(opacity=50);
}
.form .inputGrid > .containerQuantifier > svg {
	width: 16px;
	margin: 0 7px;
	padding: 0;
	cursor: pointer;
	fill: #FFFFFF;
	transition: all .15s;
}
.form .inputGrid > .containerQuantifier > svg.disable {
	opacity: 0.5;
	filter: alpha(opacity=50);
	cursor: auto;
}
.form .inputGrid > .containerQuantifier > svg:hover {
	fill: #E8E8E8;
}

.datepickerHide {
	display: block;
}

.storeCatalogs {
	display: block;
	margin: 15px 0 5px 0;
	padding: 0;
	font-size: 9pt;
	color: var(--textColor);
	text-align: left;
}
.listCatalogs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;
	padding: 0 0 15px 0;
}
.listCatalogs > .empty {
	display: block;
	margin: 0;
	padding: 10px 0 0 0;
	font-size: 8pt;
	color: var(--darkGraySkyTill);
}
.listCatalogs > .catalog {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	margin: 10px 15px 0 0;
	padding: 0 10px 0 0;
	border-width: 1px;
	border-style: solid;
	border-radius: 5px;
	cursor: pointer;
	transition: all .15s;
}
.listCatalogs > .license,
.listCatalogs > .store {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	margin: 10px 15px 0 0;
	padding: 0 10px 0 0;
	border-radius: 5px;
	background-color: var(--lightGraySkyTill);
	cursor: pointer;
	transition: all .15s;
}
.listCatalogs > .license.selected,
.listCatalogs > .store.selected {
	background-color: var(--darkBlueSkyTill);
}
.listCatalogs > .catalog:hover {
	filter: brightness(60%);
}
.listCatalogs > .license:not(.selected):hover,
.listCatalogs > .store:not(.selected):hover {
	filter: brightness(80%);
}
.listCatalogs > .catalog > .color {
	display: block;
	width: 15px;
	height: 36px;
	margin: 0;
	padding: 0;
}
.listCatalogs > .catalog > .text,
.listCatalogs > .license > .text,
.listCatalogs > .store > .text {
	display: block;
	margin: 0;
	padding: 0 0 0 10px;
	font-size: 9pt;
	line-height: 36px;
}
.listCatalogs > .license.selected > .text,
.listCatalogs > .store.selected > .text {
	color: white;
}

.form .inputBarcodePrefix {
	display: block;
	margin: 0 0 0 30px;
	padding: 0;
}
.form .inputBarcodePrefix.marginTop {
	margin-top: 20px;
}
.form .inputBarcodePrefix > .line {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: stretch;
	align-items: stretch;
	width: 100%;
	height: 40px;
	margin: 0;
	padding: 0;
}
.form .inputBarcodePrefix > .line > .inputText {
	width: 50px;
}
.form .inputBarcodePrefix > .line > .inputText > input {
	text-align: center;
}
.form .inputBarcodePrefix > .line > p {
	width: calc(100% - 80px);
	height: 40px;
	text-align: left;
	margin: 0;
	padding: 0 15px;
	font-size: 9pt;
	line-height: 40px;
}

.form .inputPhone .containerClass {
	width: calc(100% - 2px);
	border-radius: var(--borderRadiusInputText);
	border: 1px solid var(--lightGraySkyTill);
	transition: all .2s;
}
.form .inputPhone .containerClass:hover {
	border: 1px solid var(--lightGraySkyTill);
	border-color: var(--darkBlueSkyTill);
}
.form .inputPhone .inputClass {
	width: 100%;
	height: 39px;
	border-radius: var(--borderRadiusInputText);
	border: none;
}
.form .inputPhone .buttonClass {
	border: none;
	transition: all .2s;
}
.form .inputPhone .buttonClass,
.form .inputPhone .buttonClass > .selected-flag {
	border-radius: var(--borderRadiusInputText) 0 0 var(--borderRadiusInputText);
}
.form .inputPhone .buttonClass > .selected-flag {
	width: 50px;
	padding: 0 15px;
}
.form .inputPhone .buttonClass > .selected-flag:hover {
	background-color: var(--lightBlueAlphaSkyTill);
}
.form .inputPhone .inputClass {
	padding-left: 60px;
}

.form .inputDate {
	width: calc(100% - 2px);
	height: 37px;
	border-radius: var(--borderRadiusInputText);
	border: 1px solid var(--lightGraySkyTill);
	transition: all .2s;
}
.react-date-picker {
	display: flex;
	height: 37px;
	padding: 0 10px;
}
.react-date-picker__wrapper {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	border: none;
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  	stroke: var(--darkBlueSkyTill);
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  	stroke: var(--darkGraySkyTill);
}
.react-calendar {
	width: 350px;
	max-width: 100%;
	color: var(--textColorInput);
	background: var(--lighterGraySkyTill);
	border: 1px solid var(--darkGraySkyTill);
	border-radius: 10px;
	line-height: 1.125em;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.form > .seeMore {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	margin: 15px auto 0 auto;
	padding: 10px 0;
	border-radius: 10px;
	transition: all .15s;
	cursor: pointer;
}
.form > .seeMore:hover {
	background-color: var(--lightBlueAlphaSkyTill);
}
.form > .seeMore > svg {
	width: 12px;
	height: 12px;
	fill: var(--darkerGraySkyTill);
	margin-top: 1px;
	transition: all .15s;
}
.form > .seeMore:hover > svg {
	fill: var(--darkBlueSkyTill);
}
.form > .seeMore > p {
	margin: 0 0 0 10px;
	padding: 0;
	font-size: 9pt;
	transition: all .15s;
}
.form > .seeMore:hover > p {
	color: var(--darkBlueSkyTill);
}

.form .inputSelectTag {
	display: flex;
	height: 37px;
	border-radius: var(--borderRadiusInputText);
	border: 1px solid var(--lightGraySkyTill);
	margin: 0;
	padding: 0 10px;
	transition: all .2s;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.form .inputSelectTag.marginTop {
	margin-top: 15px;
}
.form .inputSelectTag.marginLeft {
	margin-left: 15px;
}
.form .inputSelectTag:hover {
	background-color: var(--lighterGraySkyTill);
	border-color: var(--darkBlueSkyTill);
}
.form .inputSelectTag > svg {
	width: 18px;
	height: 18px;
	margin: 9px 0 0 0;
	fill: var(--darkGraySkyTill);
	transition: all .2s;
}
.form .inputSelectTag:hover > svg {
	fill: var(--darkBlueSkyTill);
}
.form .inputSelectTag > p {
	height: 37px;
	margin: 0;
	padding: 0 0 0 10px;
	font-size: 9pt;
	line-height: 37px;
	transition: all .2s;
	color: var(--darkerGraySkyTill);
}
.form .inputSelectTag:hover > p {
	color: var(--textColor);
}
.form .listTags {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
}
.form .listTags.inSearchInput {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 4px 0 0 0;
	padding: 0;
	height: 29px;
}
.form .listTags > .tag {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow: hidden;
	margin: 0 10px 10px 0;
	padding: 0 15px 0 15px;
	border-radius: 23px;
	transition: all .15s;
	cursor: pointer;
	border: 3px solid transparent;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.form .listTags.inSearchInput > .tag {
	border: none;
	cursor: auto;
	margin: 0 0 0 5px;
	padding: 0 10px;
	border-radius: 15px;
}
.form .listTags > .tag.selected {
	border-color: var(--blueSkyTill);
}
.form .listTags:not(.inSearchInput) > .tag:hover {
	filter: brightness(80%);
}
.form .listTags > .tag > .icon {
	width: 14px;
	height: 14px;
	margin: 9px 0 0 0;
	padding: 0;
}
.form .listTags.inSearchInput > .tag > .icon {
	margin-top: 7px;
}
.form .listTags > .tag > .icon svg {
	width: 14px;
	height: 14px;
	display: block;
	clear: both;
	margin: 0;
	padding: 0;
	cursor: auto;
}
.form .listTags > .tag > p {
	margin: 0;
	padding: 0 0 0 12px;
	font-size: 9pt;
	line-height: 34px;
}
.form .listTags.inSearchInput > .tag > p {
	margin: 0;
	padding: 0 0 0 8px;
	font-size: 8pt;
	line-height: 29px;
}