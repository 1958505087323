@font-face {
	font-family: Montserrat-Black; src: local("Montserrat Black"), local("Montserrat-Black"), url(../font/Montserrat/Montserrat-Black.ttf);
}
@font-face {
	font-family: Montserrat-BlackItalic; src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url(../font/Montserrat/Montserrat-BlackItalic.ttf);
}
@font-face {
	font-family: Montserrat-Bold; src: local("Montserrat Bold"), local("Montserrat-Bold"), url(../font/Montserrat/Montserrat-Bold.ttf);
}
@font-face {
	font-family: Montserrat-BoldItalic; src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url(../font/Montserrat/Montserrat-BoldItalic.ttf);
}
@font-face {
	font-family: Montserrat-ExtraBold; src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url(../font/Montserrat/Montserrat-ExtraBold.ttf);
}
@font-face {
	font-family: Montserrat-ExtraBold; src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url(../font/Montserrat/Montserrat-ExtraBoldItalic.ttf);
}
@font-face {
	font-family: Montserrat-ExtraLight; src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url(../font/Montserrat/Montserrat-ExtraLight.ttf);
}
@font-face {
	font-family: Montserrat-ExtraLight; src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url(../font/Montserrat/Montserrat-ExtraLightItalic.ttf);
}
@font-face {
	font-family: Montserrat-Italic; src: local("Montserrat Italic"), local("Montserrat-Italic"), url(../font/Montserrat/Montserrat-Italic.ttf);
}
@font-face {
	font-family: Montserrat-Light; src: local("Montserrat Light"), local("Montserrat-Light"), url(../font/Montserrat/Montserrat-Light.ttf);
}
@font-face {
	font-family: Montserrat-LightItalic; src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url(../font/Montserrat/Montserrat-LightItalic.ttf);
}
@font-face {
	font-family: Montserrat-Medium; src: local("Montserrat Medium"), local("Montserrat-Medium"), url(../font/Montserrat/Montserrat-Medium.ttf);
}
@font-face {
	font-family: Montserrat-MediumItalic; src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url(../font/Montserrat/Montserrat-MediumItalic.ttf);
}
@font-face {
	font-family: Montserrat-Regular; src: local("Montserrat Regular"), local("Montserrat-Regular"), url(../font/Montserrat/Montserrat-Regular.ttf);
}
@font-face {
	font-family: Montserrat-SemiBold; src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(../font/Montserrat/Montserrat-SemiBold.ttf);
}
@font-face {
	font-family: Montserrat-SemiBoldItalic; src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url(../font/Montserrat/Montserrat-SemiBoldItalic.ttf);
}
@font-face {
	font-family: Montserrat-Thin; src: local("Montserrat Thin"), local("Montserrat-Thin"), url(../font/Montserrat/Montserrat-Thin.ttf);
}
@font-face {
	font-family: Montserrat-ThinItalic; src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url(../font/Montserrat/Montserrat-ThinItalic.ttf);
}
@font-face {
	font-family: Calculator; src: local("Calculator"), local("Calculator"), url(../font/Calculator/Calculator.ttf);
}
@font-face {
	font-family: Merchant; src: local("Merchant"), local("Merchant"), url(../font/Merchant/Merchant.ttf);
}
@font-face {
	font-family: Merchant-Wide; src: local("Merchant-Wide"), local("Merchant-Wide"), url(../font/Merchant/Merchant-Wide.ttf);
}
@font-face {
	font-family: Merchant-Doublesize; src: local("Merchant-Doublesize"), local("Merchant-Doublesize"), url(../font/Merchant/Merchant-Doublesize.ttf);
}

html, body {
	height: 100%;
	min-width: 280px;
	margin: 0;
	padding: 0;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.wrapLoader {
	display: flex;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	flex-direction: column;
	justify-content: center;
}

.wrapCenterLoader {
	display: block;
	width: 100%;
	height: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
}
.wrapCenterLoader.page {
	height: calc(100% - var(--heightHeader));
	min-height: calc(100% - var(--heightHeader));
}
.wrapCenterLoader.withButtons {
	height: calc(100% - 65px);
	min-height: calc(100% - 65px);
}
.wrapCenterLoader.padding {
	padding: 25px 0;
}

.loader {
	display: block;
}
.loader.hide {
	display: none;
}
.loader.logInit {
	width: 70px;
	height: 70px;
	margin: 0 auto;
}
.loader.logWait {
	width: 40px;
	height: 40px;
	margin: 20px auto 20px auto;
}
.loader.logWait.smaller {
	width: 30px;
	height: 30px;
}
.loader.logWait.marginTop {
	margin-top: 40px;
}
.loader.submitLogin {
	clear: both;
	float: right;
	width: 20px;
	height: 20px;
	margin: 9px 0 0 15px;
	padding: 0;
}
.loader.submitLogout {
	float: right;
	width: 20px;
	height: 20px;
	margin: 0 30px 0 0;
	padding: 0;
}
.loader.restGET {
	display: block;
	float: right;
	width: 30px;
	height: 30px;
	margin: 15px 10px 0 0;
	padding: 0;
}
.loader.restPagination {
	display: block;
	float: right;
	width: 24px;
	height: 24px;
	margin: 18px 10px 0 0;
	padding: 0;
}
.loader.restInLinePrice {
	display: block;
	width: 20px;
	height: 20px;
	margin: 10px 45px;
	padding: 0;
}
.loader.restInLineVat {
	display: block;
	width: 20px;
	height: 20px;
	margin: 10px 25px;
	padding: 0;
}
.loader.inDeploymentButton {
	display: block;
	width: 20px;
	height: 20px;
	margin: 5px 10px 0 10px;
	padding: 0;
}
.loader.submitForm {
	clear: both;
	float: right;
	width: 20px;
	height: 20px;
	margin: 9px 0 0 15px;
	padding: 0;
}
.loader.searchBarLoading {
	float: right;
	width: 24px;
	height: 24px;
	margin: -32px 15px 0 0;
	padding: 0;
}
.loader.searchBarLoadingClosing {
	float: left;
	width: 24px;
	height: 24px;
	margin: 12px 0 0 15px;
	padding: 0;
}
.loader.inputMultiLinesChoicesLoading {
	float: right;
	width: 24px;
	height: 24px;
	margin: -32px 12px 0 0;
	padding: 0;
}
.loader.loaderButtonArrow {
	width: 18px;
	height: 18px;
	margin: 10px auto;
}
.loader.loaderHisto {
	width: 30px;
	height: 30px;
	margin: 15px auto;
}
.loader.inputForm {
	float: right;
	width: 20px;
	height: 20px;
	margin: -30px 45px 0 0;
	padding: 0;
}
.loader.inputForm.noMarginRight {
	margin-left: 40px;
}
.loader.hButton {
	float: right;
	width: 16px;
	height: 16px;
	margin: 7px 0 0 15px;
	padding: 0;
}

.bubbleHover {
	overflow: hidden;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0 20px;
	border-radius: 15px;
	background-color: var(--darkerMoreGraySkyTill);
	opacity: 0;
	filter: alpha(opacity=0);
	transition: opacity .2s, filter .2s;
}
.bubbleHover.visible {
	opacity: 1;
	filter: alpha(opacity=100);
}
.bubbleHover > p {
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 30px;
	color: var(--whiteSkyTill);
}

.wrapCenterLoader > .loader.restGETInForm {
	position: absolute;
	width: 40px;
	height: 40px;
	top: calc(50% - 20px);
	left: calc(50% - 20px);
	margin: 0;
	padding: 0;
}
.wrapCenterLoader > .loader.restGETInMain {
	position: absolute;
	width: 60px;
	height: 60px;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	margin: 0;
	padding: 0;
}
.wrapCenterLoader > .loader.restGETPreview {
	width: 40px;
	height: 40px;
	margin: 50px auto;
	padding: 0;
}
.wrapCenterLoader > .loader.downloadArchive {
	float: right;
	width: 20px;
	height: 20px;
	transition: all .15s;
	cursor: wait;
}

.overlayer {
	position: fixed;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	transition: all 0.15s;
	animation: opacityUp 0.3s ease;
}
.overlayer.hover {
	z-index: 102;
}

.wrapOverbox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100vh;
	max-height: 100%;
	margin: 0;
	padding: 0;
	transition: all 0.15s;
}

.overbox {
	z-index: 101;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: calc(100vh - 100px);
	margin: 0;
	padding: 0;
	background-color: #FFFFFF;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
	transition: all 0.15s;
	animation: boxUp 0.3s ease;
}
.overbox.hover {
	z-index: 103;
}
.overbox.validation {
	width: 450px;
	height: auto;
	padding: 15px;
}
.overbox.choices {
	position: fixed;
	width: 400px;
	margin: 0 auto;
}
.overbox.choices.extend {
	width: 500px;
}
.overbox.choices.extended {
	width: 550px;
}
.overbox.variationPrice {
	width: 375px;
	margin: 0 auto;
}
.overbox.manyForm {
	overflow-x: hidden;
	overflow-y: scroll;
	width: calc(100vw - 100px);
	max-height: calc(100vh - 100px);
	margin: 0 auto;
	padding: 15px;
}
.overbox.import {
	overflow-x: hidden;
	overflow-y: scroll;
	width: calc(100vw - 100px);
	height: calc(100vh - 100px);
	max-height: calc(100vh - 100px);
	margin: 0 auto;
	padding: 15px 0 15px 15px;
}
.overbox.accountList {
	overflow-x: hidden;
	overflow-y: scroll;
	width: calc(100vw - 50px);
	height: calc(100vh - 70px);
	max-height: calc(100vh - 30px);
	margin: 0 auto;
	padding: 0;
}
.overbox.sheet {
	overflow: visible;
	min-width: 800px;
	width: 800px;
	height: calc(100vh - 100px);
	margin: 0 auto;
	padding: 0;
}
.overbox.deploy {
	overflow: visible;
	min-width: 600px;
	width: 600px;
	max-height: calc(100vh - 100px);
	margin: 0 auto;
	padding: 15px;
}
.overbox.date {
	overflow: visible;
	min-width: 500px;
	width: 500px;
	margin: 0 auto;
	padding: 15px;
}
.overbox.start {
	overflow-x: hidden;
	overflow-y: scroll;
	width: 500px;
	min-height: 100px;
	height: auto;
	margin: 0 auto;
	padding: 5px 10px;
}
.overbox.subList {
	overflow-x: hidden;
	overflow-y: scroll;
	width: 450px;
	height: auto;
	margin: 0 auto;
	padding: 0;
}
.overbox.previewSale {
	overflow: hidden;
	min-width: 400px;
	width: 400px;
	height: auto;
	margin: 0 auto;
	padding: 0;
}

.overbox .title {
	margin: 15px 0;
	padding: 0;
	color: var(--textColor);
	text-align: center;
	font-size: 11pt;
	font-weight: bold;
	text-transform: uppercase;
}
.overbox .title.red {
	color: var(--redSkyTill);
}
.overbox .title.noMarginTop {
	margin-top: 0;
}
.overbox .text {
	margin: 10px 0;
	padding: 0 15px;
	font-size: 9pt;
	line-height: 20px;
	font-weight: bold;
	color: var(--textColor);
}
.overbox .listDetails {
	margin: 20px 0 10px 0;
	padding: 0;
	list-style-type: none;
}
.overbox .listDetails > li {
	padding: 5px 0;
	font-size: 9pt;
	line-height: 18px;
	color: var(--darkerMoreGraySkyTill);
}
.overbox .error {
	margin: 0 0 15px 0;
	padding: 10px 15px;
	font-size: 9pt;
	line-height: 20px;
	text-align: left;
	border-radius: 3px;
	color: rgba(255, 255, 255, 1);
	background-color: rgb(195, 48, 48);
}
.overbox .warning {
	width: calc(100% - 30px);
	height: auto;
	margin: 15px 0;
	padding: 15px;
	background-color: var(--yellowSkyTill);
	border-radius: 10px;
	font-size: 8pt;
	line-height: 18px;
	font-style: italic;
	color: var(--darkerGraySkyTill);
}
.overbox .warning > strong {
	text-decoration: underline;
}

.overbox .listItemInOverbox {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 500px;
	margin: 0;
	padding: 0;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.overbox .listItemInOverbox > .containerItem {
	margin: 0;
	padding: 0;
}
.overbox .listItemInOverbox > .containerItem > .containerSubItem {
	margin: 0;
	padding: 0;
}
.overbox .listItemInOverbox > .containerItem > .item,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem {
	margin: 5px 0 0 0;
	padding: 0 15px;
	font-size: 9pt;
	line-height: 34px;
	cursor: pointer;
	border-radius: 17px;
	transition: all .2s;
}
.overbox .listItemInOverbox > .containerItem > .item.actu,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem.actu {
	background-color: var(--mainColorAlphaHover);
}
.overbox .listItemInOverbox > .containerItem > .item.actu.red,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem.actu.red {
	background-color: var(--redAlphaSkyTill);
}
.overbox .listItemInOverbox > .containerItem > .item.selected,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem.selected {
	background-color: var(--orangeLightSkyTill);
}
.overbox .listItemInOverbox > .containerItem > .item.actu::before,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem.actu::before {
	display: block;
	float: left;
	content: "";
	width: 8px;
	height: 8px;
	margin: 13px 10px 0 0;
	padding: 0;
	background-color: var(--darkBlueSkyTill);
	border-radius: 4px;
}
.overbox .listItemInOverbox > .containerItem > .item.actu.red::before,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem.actu.red::before {
	background-color: var(--redSkyTill);
}
.overbox .listItemInOverbox > .containerItem > .item.selected::before,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem.selected::before {
	display: block;
	float: left;
	content: "";
	width: 8px;
	height: 8px;
	margin: 13px 10px 0 0;
	padding: 0;
	background-color: var(--orangeSkyTill);
	border-radius: 4px;
}
.overbox .listItemInOverbox > .containerItem > .item.actu:not(.noSuffix)::after,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem.actu:not(.noSuffix)::after {
	content: "actuelle";
	margin: 0 0 0 10px;
	padding: 3px 10px;
	font-size: 9px;
	line-height: 9px;
	border-radius: 12px;
	background-color: var(--darkBlueSkyTill);
	color: var(--whiteSkyTill);
}
.overbox .listItemInOverbox > .containerItem > .item.selected::after,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem.selected::after {
	content: "destination";
	margin: 0 0 0 10px;
	padding: 3px 10px;
	font-size: 9px;
	line-height: 9px;
	border-radius: 12px;
	background-color: var(--orangeSkyTill);
	color: var(--whiteSkyTill);
}
.overbox .listItemInOverbox > .containerItem > .item:not(.actu):not(.selected):hover,
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem:not(.actu):not(.selected):hover {
	background-color: var(--lighterGraySkyTill);
}
.overbox .listItemInOverbox > .containerItem > .item:not(.actu):not(.selected):hover {
	padding-left: 20px;
}
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem {
	padding-left: 40px;
}
.overbox .listItemInOverbox > .containerItem > .containerSubItem > .subItem:not(.actu):not(.selected):hover {
	padding-left: 45px;
}

.overbox .wrapForm {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: calc(100vh - 100px);
	min-height: calc(100vh - 100px);
	max-height: calc(100vh - 100px);
}
.overbox .wrapForm.alone {
	height: 100%;
	min-height: 100%;
	max-height: 100%;
}
.overbox .wrapForm.justButtonBar {
	height: 100%;
	min-height: 100%;
	max-height: calc(100vh - 30px);
}

.overbox .wrapForm .headerSheet {
	overflow: hidden;
	height: 70px;
	margin: 0;
	padding: 0 15px;
	background-color: var(--darkBlueSkyTill);
}
.overbox .wrapForm .headerSheet > .infos {
	display: block;
	float: left;
	width: 65%;
	margin: 0;
	padding: 0;
}
.overbox .wrapForm .headerSheet > .infos > .text {
	margin: 0;
	padding: 0;
	color: white;
	font-size: 10pt;
	line-height: 70px;
}
.overbox .wrapForm .headerSheet > .infos > .titleSheet {
	margin: 15px 0 0 0;
	padding: 0;
	color: white;
	font-size: 10pt;
	line-height: 20px;
	text-align: left;
}
.overbox .wrapForm .headerSheet > .infos > .titleSheet.alone {
	margin: 0;
	line-height: 70px;
}
.overbox .wrapForm .headerSheet > .infos > .ariane {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 5px 0 0 0;
	padding: 0;
}
.overbox .wrapForm .headerSheet > .infos > .ariane > .link {
	margin: 0 5px 0 0;
	padding: 0;
	font-size: 8pt;
	line-height: 14px;
	color: var(--whitySkyTill);
	cursor: pointer;
	transition: all .2s;
}
.overbox .wrapForm .headerSheet > .infos > .ariane > .link.disable {
	cursor: auto;
}
.overbox .wrapForm .headerSheet > .infos > .ariane > .link:not(.disable):hover {
	color: var(--whiteSkyTill);
	text-decoration: underline;
}
.overbox .wrapForm .headerSheet > .infos > .ariane > .text {
	margin: 0 5px 0 0;
	padding: 0;
	font-size: 8pt;
	line-height: 14px;
	color: var(--whitySkyTill);
}
.overbox .wrapForm .headerSheet > .actions {
	display: block;
	float: right;
	width: 35%;
	margin: 0;
	padding: 0;
}
.overbox .wrapForm .headerSheet > .actions .item {
	display: block;
	float: right;
	margin: 26px 20px 0 0;
	padding: 0;
	cursor: pointer;
}
.overbox .wrapForm .headerSheet > .actions .item.remove {
	margin-top: 25px;
}
.overbox .wrapForm .headerSheet > .actions .item:first-child {
	margin-right: 0;
}
.overbox .wrapForm .headerSheet > .actions .item > svg {
	width: 18px;
	height: 18px;
	transition: all 0.15s;
}
.overbox .wrapForm .headerSheet > .actions .item:hover > svg {
	fill: var(--blueSkyTill);
}
.overbox .wrapForm .headerSheet > .actions .item.remove > svg {
	width: 20px;
	height: 20px;
}
.overbox .wrapForm .headerSheet > .actions .item.remove:hover > svg {
	fill: var(--redHoverSkyTill);
}

.overbox .wrapForm .sheetError {
	overflow: hidden;
	height: 40px;
	max-height: 40px;
	margin: 0;
	padding: 0 15px 0 50px;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: var(--fontSizeGeneralText);
	line-height: 40px;
	color: var(--whiteSkyTill);
	background-color: var(--redSkyTill);
	background-repeat: no-repeat;
	background-image: url(../image/ressource/error-white.svg);
	background-size: 16pt 16pt;
	background-position: left 15px center;
}

.overbox .wrapForm .tabulate {
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	align-content: stretch;
	width: calc(100% - 30px);
	height: 25px;
	margin: 15px 0 0 15px;
	padding: 0;
}
.overbox .wrapForm .tabulate > a {
	text-decoration: inherit;
	color: inherit;
}
.overbox .wrapForm .tabulate .tab {
	margin: 0;
	padding: 0 25px;
	font-size: var(--fontSizeGeneralText);
	line-height: 25px;
	text-align: center;
	color: var(--lightBlackSkyTill);
	background-color: var(--lightGraySkyTill);
	cursor: pointer;
	transition: all .15s;
}
.overbox .wrapForm .tabulate .tab.readonly {
	cursor: not-allowed;
}
.overbox .wrapForm .tabulate > a:first-child > .tab,
.overbox .wrapForm .tabulate > .tab:first-child {
	border-radius: 5px 0 0 0;
}
.overbox .wrapForm .tabulate > a:last-child > .tab,
.overbox .wrapForm .tabulate > .tab:last-child {
	border-radius: 0 5px 0 0;
}
.overbox .wrapForm .tabulate > a > .tab.select,
.overbox .wrapForm .tabulate > .tab.select {
	background-color: var(--lighterGraySkyTill);
	cursor: auto;
}
.overbox .wrapForm .tabulate > a > .tab:not(.select):hover,
.overbox .wrapForm .tabulate > .tab:not(.select):hover {
	background-color: var(--lighterGraySkyTill);
}

.overbox .wrapForm .scrollview {
	overflow-x: auto;
	overflow-y: visible;
	width: 100%;
	height: calc(100% - 140px);
	min-height: calc(100% - 140px);
	max-height: calc(100% - 140px);
	margin: 0;
	padding: 0;
	background-color: var(--lighterGraySkyTill);
}
.overbox .wrapForm .scrollview.whiteBg {
	background-color: var(--whiteSkyTill);
}
.overbox .wrapForm.tab .scrollview {
	margin: 0 15px 5px 15px;
	width: calc(100% - 30px);
	height: calc(100% - 185px);
	min-height: calc(100% - 185px);
	max-height: calc(100% - 185px);
	background-color: var(--lighterGraySkyTill);
	border-radius: 0 5px 5px 5px;
}
.overbox .wrapForm.tab .scrollview.noTab {
	margin-top: 15px;
	height: calc(100% - 160px);
	min-height: calc(100% - 160px);
	max-height: calc(100% - 160px);
	border-radius: 5px;
}

.overbox .wrapForm .scrollview.withError {
	height: calc(100% - 180px);
	min-height: calc(100% - 180px);
	max-height: calc(100% - 180px);
}
.overbox .wrapForm.tab .scrollview.withError {
	height: calc(100% - 240px);
	min-height: calc(100% - 240px);
	max-height: calc(100% - 240px);
}
.overbox .wrapForm.tab .scrollview.withError.noTab {
	height: calc(100% - 205px);
	min-height: calc(100% - 205px);
	max-height: calc(100% - 205px);
}

.overbox .wrapForm .savingBar {
	display: block;
	width: calc(100% - 50px);
	height: 30px;
	margin: 15px 15px 0 15px;
	padding: 10px;
	background-color: var(--darkerGraySkyTill);
	border-radius: 5px;
}
.overbox .wrapForm .savingBar.wizz {
	animation: wizz 1.2s linear;
}
.overbox .wrapForm .savingBar > .text {
	display: block;
	float: left;
	width: 50%;
	margin: 0;
	padding: 0;
	text-transform: none;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	color: var(--whiteSkyTill);
}
.overbox .wrapForm .savingBar > .reinit {
	display: block;
	float: right;
	margin: 0;
	padding: 0;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	color: var(--whiteSkyTill);
	font-weight: bold;
	cursor: pointer;
	transition: all 0.15s;
}
.overbox .wrapForm .savingBar > .save {
	display: block;
	float: right;
	margin: 0 0 0 15px;
	padding: 0 15px;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	font-weight: bold;
	background-color: var(--mainColor);
	color: var(--whiteSkyTill);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	transition: all 0.15s;
}
.overbox .wrapForm .savingBar > .reinit:hover {
	color: var(--whiteAlphaSkyTill);
}
.overbox .wrapForm .savingBar > .save:hover {
	background-color: var(--mainColorHover);
}
@keyframes wizz {
	4% {
		margin-left: 25px;
		background-color: var(--redSkyTill);
	}
	8% {
		margin-left: 5px;
	}
	12% {
		margin-left: 25px;
	}
	16% {
		margin-left: 5px;
	}
	20% {
		margin-left: 15px;
	}
}

.overbox .wrapButtons {
	width: 100%;
	margin: 0;
	padding: 0;
}

.overbox .wrapButtons .submit {
	display: block;
	clear: both;
	float: right;
	margin: 15px 0 0 0;
	padding: 0 20px;
	line-height: 40px;
	background-color: var(--mainColor);
	color: white;
	border-radius: 20px;
	border: none;
	font-size: var(--fontSizeGeneralText);
	font-weight: bold;
	transition: all 0.15s;
}
.overbox .wrapButtons .submit.red {
	background-color: var(--backgroundColorDelete);
}
.overbox .wrapButtons .submit.locked {
	opacity: 0.5;
	filter: alpha(opacity=50);
	zoom: 1;
}
.overbox .wrapButtons .submit:not(.locked):hover {
	cursor: pointer;
	background-color: var(--mainColorHover);
}
.overbox .wrapButtons .submit.red:not(.locked):hover {
	background-color: var(--backgroundColorDeleteHover);
}
.overbox .wrapButtons .submit:not(.locked):focus {
	outline: none;
}
.overbox .wrapButtons .cancel {
	display: block;
	float: right;
	margin: 15px 15px 0 0;
	padding: 0 20px;
	color: rgb(100, 100, 100);
	border-radius: 20px;
	border: none;
	cursor: pointer;
	font-size: var(--fontSizeGeneralText);
	text-transform: capitalize;
	font-weight: bold;
	line-height: 40px;
	background-color: transparent;
	transition: all 0.15s;
}
.overbox .wrapButtons .cancel:hover {
	color: rgb(30, 30, 30);
	background-color: var(--lightGraySkyTill);
}
.overbox .wrapButtons .cancel:focus {
	outline: none;
}

.clearing {
	display: block;
	clear: both;
	margin: 0;
	padding: 0;
}
.clearing.fiftyFifty {
	width: 50%;
}
.clearing.fullWidth {
	width: 100%;
}
.clearing.fullHeight {
	height: calc(100% - 30px);
	min-height: calc(100% - 30px);
}

::-webkit-scrollbar {
	width: 0;
	height: 0;
}

@keyframes opacityUp {
	from {
		opacity: 0;
		filter: alpha(opacity=0);
	}
	to {
		opacity: 1;
		filter: alpha(opacity=100);
	}
}
@keyframes boxUp {
	from {margin-top: 30px;}
	to   {margin-top: 0;}
}

@media (max-width: 830px) {
	.overlayer {
		background-color: transparent;
	}
	
	.wrapOverbox {
		display: flex;
		align-items: flex-start;
	}
	
	.overbox {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
		height: 100vh;
		max-height: 100vh;
		box-shadow: none;
		animation: opacityUp 0.3s ease;
	}
	.overbox.validation {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
	}
	.overbox.choices {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
	}
	.overbox.choices.extend {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
	}
	.overbox.choices.extended {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
	}
	.overbox.variationPrice {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
	}
	.overbox.manyForm {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
		height: 100vh;
		max-height: 100vh;
	}
	.overbox.import {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
		height: 100vh;
		max-height: 100vh;
	}
	.overbox.accountList {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
		height: 100vh;
		max-height: 100vh;
	}
	.overbox.sheet {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
		height: 100vh;
		max-height: 100vh;
	}
	.overbox.deploy {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
		height: 100vh;
		max-height: 100vh;
	}
	.overbox.date {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
	}
	.overbox.start {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
		height: 100vh;
		max-height: 100vh;
	}
	.overbox.subList {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
	}
	.overbox.previewSale {
		width: 100vw;
		max-width: 100vw;
		min-width: auto;
	}
}
