.SwitchItem {
	overflow: hidden;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	height: 40px;
	margin: 0;
	padding: 0;
	border-radius: 10px;
	background-color: var(--lighterGraySkyTill);
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.SwitchItem > .overlay {
	position: absolute;
	transition: all .25s;
	background-color: var(--mainColorHover);
	opacity: .2;
	filter: alpha(opacity=20);
	cursor: pointer;
}
.SwitchItem > .item {
	margin: 0;
	padding: 0;
	cursor: pointer;
	transition: all .15s;
}
.SwitchItem > .item:not(.selected):hover {
	background-color: var(--lightGraySkyTill);
}
.SwitchItem > .item > .label {
	font-size: 8pt;
	line-height: 40px;
	margin: 0;
	padding: 0 15px;
	transition: all .25s;
}
.SwitchItem > .item.selected > .label {
	color: var(--blackSkyTill);
}

@media only screen and (max-width: 640px) {
	.SwitchItem {
		margin: 0 auto;
	}
}