.backoffice {
	width: 100%;
	margin: 0;
	padding: var(--heightHeader) 0 0 0;
}

.main {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	width: calc(100% - var(--widthMenu));
	margin: 0 0 0 var(--widthMenu);
	padding: 0;
}
.main.paddingBottom {
	padding-bottom: 15px;
}
.main.lightPaddingTop {
	padding-top: 15px;
}
.main.noPaddingTop {
	padding-top: 0;
}

.backoffice .inForm {
	display: block;
	margin: 25px 0 0 0;
	padding: 0;
}

.toolsBar {
	margin: 15px 15px 20px 15px;
	padding: 0;
}
.toolsBar.noMarginTop {
	margin-top: 0;
}
.toolsBar.noMarginBottom {
	margin-bottom: 0;
}
.toolsBar > .titleToolsBar {
	float: left;
	text-transform: uppercase;
	margin: 10px 0 0 10px;
	padding: 0;
	color: var(--textColor);
	text-align: left;
	font-size: 11pt;
	font-weight: bold;
}
.toolsBar a {
	text-decoration: none;
	color: inherit;
	font-family: inherit;
}
.toolsBar .button,
.backoffice .inForm .button {
	display: block;
	overflow: hidden;
	float: right;
	width: auto;
	height: 40px;
	max-height: 40px;
	margin: 0 0 0 15px;
	padding: 0 15px 0 40px;
	line-height: 40px;
	border-radius: 10px;
	border: none;
	outline: none;
	font-size: var(--fontSizeGeneralText);
	font-family: inherit;
	background-position: left 15px center;
	background-repeat: no-repeat;
	background-size: 12px;
	cursor: pointer;
	transition: all 0.15s;
}
.toolsBar .button.left,
.backoffice .inForm .button.left {
	float: left;
	margin: 0;
}
.toolsBar .button.add,
.toolsBar .button.blue {
	color: var(--darkBlueSkyTill);
	background-color: var(--lightBlueAlphaSkyTill);
}
.toolsBar .button.searchAlone,
.backoffice .inForm .button.unlock {
	background-color: var(--lightGraySkyTill);
}
.toolsBar .button.add {
	background-image: url("../../image/ressource/cross-dark-blue.svg");
}
.toolsBar .button.export {
	padding: 0 15px 0 45px;
	background-size: 16px;
	background-image: url("../../image/ressource/export-dark-blue.svg");
}
.toolsBar .button.searchAlone {
	background-image: url("../../image/ressource/search.svg");
}
.backoffice .inForm .button.unlock {
	background-image: url("../../image/ressource/unlock.svg");
}
.toolsBar .button.add:hover,
.toolsBar .button.export:hover {
	background-color: var(--lightBlueSkyTill);
}
.toolsBar .button.searchAlone:hover,
.backoffice .inForm .button.unlock:hover {
	background-color: var(--darkGraySkyTill);
}
.toolsBar .button > span,
.backoffice .inForm .button > span {
	margin: 0;
	padding: 0;
}
.toolsBar .history {
	display: block;
	overflow: hidden;
	float: right;
	margin: 0 0 0 15px;
	padding: 0;
}

.toolsBar > .form {
	display: flex;
	justify-content: space-between;
}
.toolsBar > .form > .inputText.select {
	min-width: 230px;
	width: auto;
}

.toolsBar > .optionButton {
	display: block;
	float: right;
	width: 45px;
	height: 40px;
	margin: 0;
	border-radius: 0 10px 10px 0;
	border-left: 1px solid var(--lighterBlueSkyTill);
	cursor: pointer;
	transition: all 0.15s;
	background-position: center center;
	background-size: 12px;
	background-repeat: no-repeat;
}
.toolsBar > .optionButton.add {
	color: var(--darkBlueSkyTill);
	background-color: var(--lightBlueAlphaSkyTill);
	background-image: url("../../image/ressource/down-arrow-dark-blue.svg");
}
.toolsBar > .optionButton:hover {
	background-color: var(--lightBlueSkyTill);
}

.main .manySelectedActions {
	position: absolute;
	z-index: 10;
	display: none;
	opacity: 0;
	filter: alpha(opacity=0);
	width: calc(100% - 30px);
	height: 62px;
	margin: -60px 0 0 15px;
	padding: 0;
	border-radius: 3px 3px 0 0;
	background-color: var(--lightBlueSkyTill);
	transition: all .15s;
}
.main .manySelectedActions.visible {
	display: block;
	opacity: 1;
	filter: alpha(opacity=100);
}
.main .manySelectedActions .cancel {
	display: block;
	float: left;
	width: 22px;
	height: 22px;
	margin: 20px 15px 0 15px;
	padding: 0;
	border-radius: 3px;
	transition: all .15s;
	cursor: pointer;
	fill: var(--darkBlueSkyTill);
}
.main .manySelectedActions .cancel:hover {
	fill: var(--blueSkyTill);
}
.main .manySelectedActions .nbSelected {
	display: block;
	float: left;
	margin: 21px 0 0 0;
	padding: 0;
	color: var(--darkBlueSkyTill);
	font-size: 12pt;
}
.main .manySelectedActions .button {
	display: block;
	overflow: hidden;
	clear: none;
	float: right;
	width: auto;
	height: 40px;
	max-height: 40px;
	margin: 10px 10px 0 0;
	padding: 0 10px 0 35px;
	line-height: 40px;
	border-radius: 5px;
	border: none;
	outline: none;
	font-size: var(--fontSizeGeneralText);
	font-weight: bold;
	font-family: inherit;
	background-color: var(--lighterBlueSkyTill);
	background-position: left 10px center;
	background-repeat: no-repeat;
	background-size: 15px 15px;
	cursor: pointer;
	transition: all 0.15s;
}
.main .manySelectedActions .button.update {
	color: var(--darkBlueSkyTill);
	background-color: var(--lighterBlueSkyTill);
	background-image: url("../../image/ressource/pencil-dark-blue.svg");
}
.main .manySelectedActions .button.delete {
	color: var(--redSkyTill);
	background-color: var(--lighterBlueSkyTill);
	background-image: url("../../image/ressource/trash-red.svg");
}
.main .manySelectedActions .button.update:hover {
	background-color: var(--lightGrayAlphaSkyTill);
}
.main .manySelectedActions .button.delete:hover {
	background-color: var(--redAlphaSkyTill);
}
.main .manySelectedActions .button > span {
	margin: 0;
	padding: 0;
}

.main > .wrapList {
	overflow: hidden;
	margin: 20px 15px;
	padding: 0;
	border: 1px solid var(--borderColorWrapList);
	border-radius: 10px;
}
.main > .wrapList.noMarginTop {
	margin-top: 0;
}
.main > .wrapList.noBorder {
	border: none;
	border-radius: 0;
}

.main > .wrapList .pagination {
	display: block;
	width: 100%;
	height: 60px;
	margin: 0;
	padding: 0;
}
.main > .wrapList .pagination > .nbTotal {
	display: block;
	float: left;
	width: calc(50% - 20px);
	margin: 0;
	padding: 0 10px;
	font-size: 12pt;
	line-height: 60px;
	text-align: left;
}
.main > .wrapList .pagination > .nbTotal > span {
	font-size: var(--fontSizeGeneralText);
	font-weight: normal;
	padding-left: 5px;
	text-transform: uppercase;
	color: var(--darkerGraySkyTill);
}
.main > .wrapList .pagination > .index {
	display: block;
	float: right;
	width: calc(50% - 20px);
	margin: 0;
	padding: 0 10px;
}
.main > .wrapList .pagination > .index .buttonArrow {
	display: block;
	float: right;
	width: 40px;
	height: 38px;
	margin: 10px 0 0 5px;
	padding: 2px 0 0 0;
	border: 1px solid var(--lightGraySkyTill);
	background-color: var(--whiteSkyTill);
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.1s;
}
.main > .wrapList .pagination > .index .buttonArrow.disabled {
	opacity: 0.5;
	filter: alpha(opacity=50);
	cursor: auto;
}
.main > .wrapList .pagination > .index .buttonArrow > svg {
	fill: var(--darkerGraySkyTill);
}
.main > .wrapList .pagination > .index .buttonArrow:not(.disabled):hover {
	background-color: var(--lightGraySkyTill);
}
.main > .wrapList .pagination > .index .nbItems {
	display: block;
	float: right;
	margin: 10px 5px 0 0;
	padding: 0 10px;
	font-size: var(--fontSizeGeneralText);
	line-height: 40px;
	font-weight: normal;
	color: var(--darkerGraySkyTill);
	border-radius: 3px;
	cursor: pointer;
	transition: all 0.1s;
}
.main > .wrapList .pagination > .index .nbItems:hover {
	background-color: var(--lightGraySkyTill);
}

.main > .wrapList .list {
	width: 100%;
	margin: 0;
	padding: 0;
	border: none;
	border-collapse: collapse;
	background-color: var(--whiteSkyTill);
}
.main > .wrapList .list > thead > tr,
.main > .wrapList .list > tbody > tr {
	width: 100%;
}
.main > .wrapList .list > tbody.scrollable {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
}
.main > .wrapList .list tr {
	overflow: hidden;
	height: 55px;
	border-bottom: 1px solid var(--lighterGraySkyTill);
	background-color: transparent;
	transition: all 0.3s;
}
.main > .wrapList .list tr.waiting {
	filter: alpha(opacity=50);
	opacity: .5;
}
.main > .wrapList .list tr.priceable {
	height: 30px;
	border-bottom: none;
	background-color: transparent;
}
.main > .wrapList .list tr:not(.priceable).checked,
.main > .wrapList .list tr:not(.header).checked {
	background-color: var(--lightBlueAlphaSkyTill);
}
.main > .wrapList .list tr:last-child {
	border-bottom: none;
	transition: all 0.3s;
}
.main > .wrapList .list tr:not(.header):not(.priceable):not(.checked):hover {
	background-color: #FAFAFA;
}
.main > .wrapList .list th {
	margin: 0;
	padding: 0 10px;
	font-family: Montserrat-Bold, Arial, sans-serif;
	font-size: 7.5pt;
	text-transform: uppercase;
	font-weight: normal;
	color: var(--darkGraySkyTill);
	background-color: var(--lighterGraySkyTill);
}
.main > .wrapList .list th span {
	cursor: pointer;
}
.main > .wrapList .list th.unsortable span {
	cursor: auto;
}
.main > .wrapList .list th.sorted {
	color: var(--darkBlueSkyTill);
}
.main > .wrapList .list th svg {
	fill: var(--darkBlueSkyTill);
	width: 12px;
	height: 12px;
	margin: 0 0 0 15px;
	padding: 0;
	transition: all 0.3s;
}
.main > .wrapList .list th:not(.sorted) svg {
	display: none;
}
.main > .wrapList .list th.desc svg {
	transform: rotate(180deg);
}
.main > .wrapList .list td {
	margin: 0;
	padding: 0 10px;
	font-size: var(--fontSizeGeneralText);
	font-weight: normal;
	color: var(--textColorList);
}
.main > .wrapList .list td.empty {
	text-align: center;
	color: var(--darkerGraySkyTill);
}

.main .wrapList .list tr.priceable > td.pricelistFilter {
	border-radius: 5px 5px 0 0;
}
.main .wrapList .list tr.priceable > td.pricelistFilter > select {
	width: 100%;
	height: 30px;
	margin: 0;
	padding: 0 45px 0 10px;
	cursor: pointer;
	font-family: inherit;
	font-size: var(--fontSizeInputText);
	color: var(--whiteSkyTill);
	background-position: right center;
	background-size: 14px 14px;
	background-repeat:  no-repeat;
	background-image: url(../../image/ressource/down-arrow.svg);
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	transition: all .25s;
	outline: none;
	background-color: transparent;
	border: none;
	border-radius: 0;
}
.main .wrapList .list tr.priceable > td.pricelistFilter > select option {
	color: var(--blackSkyTill) !important;
}

.main .wrapList .list th.access,
.main .wrapList .list td.access {
	width: auto;
}
.main .wrapList .list td.access a {
	text-decoration: none;
	color: inherit;
	font-family: inherit;
}
.main .wrapList .list td.access button {
	height: 40px;
	padding: 0 15px 0 45px;
	margin: 0;
	color: var(--darkBlueSkyTill);
	font-family: inherit;
	font-size: var(--fontSizeGeneralText);
	outline: 0;
	border: 0;
	border-radius: 20px;
	background-color: var(--lightBlueAlphaSkyTill);
	background-image: url("../../image/ressource/see-dark-blue.svg");
	background-position: left 15px center;
	background-repeat: no-repeat;
	background-size: 18px;
	cursor: pointer;
	transition: all 0.15s;
}
.main .wrapList .list td.access button:hover {
	background-color: var(--lightBlueSkyTill);
}
.main .wrapList .list td.access button > span {
	margin: 0;
	padding: 0;
}

.main .wrapList .list th.updated_at,
.main .wrapList .list td.updated_at {
	min-width: 150px;
	text-align: left;
}
.main .wrapList .list td.updated_at > p {
	margin: 0;
	padding: 0;
	text-transform: capitalize;
}
.main .wrapList .list td.updated_at > p > span {
	font-size: 7pt;
	color: var(--darkGraySkyTill);
	text-transform: lowercase;
}

.main .wrapList .list td.input {

}
.main .wrapList .list td.input > .noInput {
	padding: 3px 10px;
	border-radius: 3px;
	cursor: pointer;
	transition: all .15s;
}
.main .wrapList .list td.input > .noInput:hover {
	background-color: var(--lightBlueAlphaSkyTill);
}
.main .wrapList .list td.input > .noInput.leftAlign .textInput {
	text-align: left;
}
.main .wrapList .list td.input > .noInput.rightAlign .textInput {
	width: 100%;
	text-align: right;
}
.main .wrapList .list td.input > .noInput.centerAlign .textInput {
	width: 100%;
	text-align: center;
}
.main .wrapList .list td.input > .noInput .textInput > .devise {
	padding-left: 5px;
	color: #6D6D6D;
	font-size: var(--fontSizeGeneralText);
}
.main .wrapList .list td.input > .noInput .icons {
	display: none;
}

.containerCircle {
	position: absolute;
	overflow: hidden;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
}
.containerCircle > span.ripple {
	position: absolute; /* The absolute position we mentioned earlier */
	border-radius: 50%;
	transform: scale(0);
	animation: ripple 600ms linear;
}
.containerCircle > span.ripple.gray {
	background-color: var(--lightGraySkyTill);
}
.containerCircle > span.ripple.blue {
	background-color: var(--lightBlueSkyTill);
}
@keyframes ripple {
	to {
		transform: scale(4);
		opacity: 0;
	}
}

@media only screen and (max-width: 1199px) {
	.main {
		width: calc(100% - var(--widthMenuReduced));
		margin: 0 0 0 var(--widthMenuReduced);
	}
}
@media only screen and (max-width: 1000px) {
	.main .wrapList .list th.access,
	.main .wrapList .list td.access {
		width: 40px;
		min-width: 40px;
		max-width: 40px;
	}
	.main .wrapList .list td.access button {
		padding-left: 28px;
		background-position: center center;
	}
	.main .wrapList .list td.access button > span {
		display: none;
	}
}
@media only screen and (max-width: 640px) {
	.main {
		width: 100%;
		margin: 0;
		min-height: calc(100vh - 95px);
		max-height: calc(100vh - 95px);
		height: calc(100vh - 95px);
	}
	.main.account {
		min-height: calc(100vh - 137px);
		max-height: calc(100vh - 137px);
		height: calc(100vh - 137px);
	}
	
	.toolsBar .button {
		max-width: 45px;
		width: 45px;
		padding: 0;
		background-position: center center;
	}
	.toolsBar .button span {
		display: none;
	}
	
	.main .manySelectedActions .button {
		max-width: 45px;
		width: 45px;
		padding: 0;
		background-position: center center;
	}
	.main .manySelectedActions .button span {
		display: none;
	}
	
	.main > .wrapList .pagination > .index .nbItems {
		display: none;
	}
}
@media print {
	.main {
		width: 100%;
		margin: 0;
		padding: 0;
	}
}