.main.subcategory > .toolsBar .button {
	clear: none;
	border-radius: 10px 0 0 10px;
}

@media (max-width: 640px) {
	.main.subcategory > .toolsBar .button.add {
		border-radius: 10px;
	}
	
	.main.subcategory > .toolsBar .optionButton {
		display: none;
	}
}
