.importer {
	height: 100%;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.importer > .form {
	display: block;
	width: calc(100% - 15px);
	height: calc(100% - 60px);
	margin: 0;
	padding: 0 15px 0 0;
}
.importer > .form > input[type="file"] {
	display: none;
}
.importer > .form > .inputFile {
	overflow: auto;
	display: block;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: var(--lighterGraySkyTill);
	cursor: pointer;
}
.importer > .form > .inputFile.dragActive {
	background-color: var(--lighterBlueSkyTill);
}
.importer > .form > .inputFile > div {
	margin: 0;
	padding: 0;
	transform: translate(0%, calc(50vh - 145px));
}
.importer > .form > .inputFile > div > svg {
	display: block;
	width: 80px;
	height: 80px;
	margin: 0 auto;
}
.importer > .form > .inputFile > div > .mainText {
	margin: 50px 0 0 0;
	text-align: center;
	font-size: 12pt;
	color: var(--darkerLessGraySkyTill);
}
.importer > .form > .inputFile > div > .secondaryText {
	margin: 5px 0 0 0;
	text-align: center;
	font-size: 9pt;
	color: var(--darkGraySkyTill);
}
#drag-file-element {
	position: absolute;
	width: 100%;
	height: 100%;;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.importer > .interface {
	width: 100%;
	height: calc(100% - 60px);
	margin: 0;
	padding: 0;
}
.importer > .interface > .header {
	width: calc(100%- 15px);
	height: 40px;
	margin: 0;
	padding: 0 15px 0 0;
}
.importer > .interface > .header > .nbLines {
	float: left;
	margin: 0;
	padding: 0;
	font-size: 12pt;
	line-height: 40px;
	text-align: left;
}
.importer > .interface > .header > .refresher {
	display: block;
	float: right;
	height: 40px;
	margin: 0;
	padding: 0;
	border-radius: 20px;
	background-color: var(--lighterGraySkyTill);
	cursor: pointer;
	transition: .2s all;
}
.importer > .interface > .header > .refresher:hover {
	background-color: var(--lightGraySkyTill);
}
.importer > .interface > .header > .refresher > input[type="file"] {
	display: none;
}
.importer > .interface > .header > .refresher > .inputFile {
	overflow: auto;
	display: block;
	margin: 0;
	padding: 0;
	cursor: pointer;
}
.importer > .interface > .header > .refresher > .inputFile > .icon {
	display: block;
	float: left;
	width: 20px;
	height: 20px;
	margin: 10px 15px;
	padding: 0;
}
.importer > .interface > .header > .refresher > .inputFile > .text {
	display: block;
	float: left;
	color: var(--darkerGraySkyTill);
	font-size: 9pt;
	line-height: 20px;
	margin: 10px 15px 0 0;
	padding: 0;
}

.importer > .interface > .core {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	align-content: flex-start;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: var(--darkBlueSkyTill) white;
	height: calc(100% - 55px);
	max-height: calc(100% - 55px);
	margin: 15px 0 0 0;
	padding: 0;
}
.importer > .interface > .core > .column {
	width: calc((100% / 5) - 15px);
	max-height: 300px;
	margin: 0 15px 15px 0;
	padding: 0;
	background-color: var(--light2GraySkyTill);
	border-radius: 10px;
}
.importer > .interface > .core > .column.toDefine {
	background-color: var(--orangeLightSkyTill);
}
.importer > .interface > .core > .column > .inputText {
	width: 100%;
	margin: 0;
	padding: 0;
}
.importer > .interface > .core > .column > .inputText > select {
	width: 100%;
	height: 39px;
	padding: 0 45px 0 10px;
	margin-top: 0;
	cursor: pointer;
	font-family: inherit;
	font-size: var(--fontSizeInputText);
	border: none;
	border-bottom: 1px solid var(--lightGraySkyTill);
	background-color: transparent;
	background-position: right 15px center;
	background-size: 14px 14px;
	background-repeat:  no-repeat;
	background-image: url(../../image/ressource/down-arrow-dark-blue.svg);
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none;
}
.importer > .interface > .core > .column > .listValues {
	max-height: calc(100% - 40px);
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: none;
	list-style-type: none;
	margin: 0;
	padding: 0;
	border-radius: 0 0 10px 10px;
}
.importer > .interface > .core > .column.disable > .listValues {
	opacity: .7;
	filter: alpha(opacity=70);
	background-color: var(--whiteSkyTill);
	overflow-y: hidden;
}
.importer > .interface > .core > .column > .listValues > .value {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 0;
	padding: 5px 10px;
	cursor: pointer;
	transition: .15s all;
}
.importer > .interface > .core > .column.disable > .listValues > .value {
	cursor: auto;
}
.importer > .interface > .core > .column:not(.disable) > .listValues > .value:hover {
	background-color: var(--lightGraySkyTill);
}
.importer > .interface > .core > .column > .listValues > .value > .cell {
	width: 15%;
	margin: 0;
	padding: 0;
	font-size: 9pt;
	line-height: 16px;
	color: var(--darkGraySkyTill);
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
.importer > .interface > .core > .column > .listValues > .value > .text {
	margin: 0;
	padding: 0;
	font-size: 9pt;
	line-height: 16px;
	color: var(--darkerGraySkyTill);
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
.importer > .interface > .core > .column.disable > .listValues > .value > .cell,
.importer > .interface > .core > .column.disable > .listValues > .value > .text {
	color: var(--lightGraySkyTill);
}

.importer > .cancelButton {
	display: block;
	float: right;
	margin: 20px 15px 0 0;
	padding: 0 20px;
	color: rgb(100, 100, 100);
	border-radius: 20px;
	border: none;
	cursor: pointer;
	font-size: var(--fontSizeGeneralText);
	text-transform: capitalize;
	font-weight: bold;
	line-height: 40px;
	background-color: transparent;
	transition: all 0.15s;
}
.importer > .cancelButton:hover {
	color: rgb(30, 30, 30);
	background-color: var(--lightGraySkyTill);
}
.importer > .cancelButton:focus {
	outline: none;
}
.importer > .submitButton {
	display: block;
	clear: both;
	float: right;
	margin: 20px 15px 0 0;
	padding: 0 20px;
	line-height: 40px;
	background-color: var(--mainColor);
	color: white;
	border-radius: 20px;
	border: none;
	font-size: var(--fontSizeGeneralText);
	font-weight: bold;
	transition: all 0.15s;
}
.importer > .submitButton.locked {
	opacity: 0.5;
	filter: alpha(opacity=50);
	zoom: 1;
}
.importer > .submitButton:not(.locked):hover {
	cursor: pointer;
	background-color: var(--mainColorHover);
}
.importer > .submitButton:not(.locked):focus {
	outline: none;
}

@media (min-width: 641px) {

}
@media (max-width: 640px) {

}