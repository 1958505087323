.screenCard {
	display: block;
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.9);
	transition: all 0.15s;
	animation: opacityUp 0.5s ease;
	user-select: none;
}

.actionDefined {
	display: block;
	position: fixed;
	z-index: 102;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.9);
	transition: all 0.15s;
	animation: opacityUp 0.5s ease;
	user-select: none;
}
.actionDefined > .box {
	display: block;
	position: fixed;
	z-index: 101;
	overflow: hidden;
	left: 50%;
	top: 50%;
	width: 500px;
	max-height: calc(100vh - 100px);
	margin: 0;
	padding: 0;
	background-color: #333333;
	border-radius: 10px;
	box-shadow: 0 0 6px rgba(0, 0, 0, .5);
	transform: translate(-50%, -50%);
	animation: appearDefineBox 0.15s ease;
}
.actionDefined > .box > .title {
	margin: 0;
	padding: 25px 25px 25px 25px;
	color: white;
	font-size: 10pt;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
}
.actionDefined > .box > .nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: calc(100% - 50px);
	margin: 0;
	padding: 0 25px 25px 25px;
	list-style-type: none;
}
.actionDefined > .box > .nav.double {
	justify-content: space-between;
}
.actionDefined > .box > .nav > .choice {
	width: 170px;
	margin: 10px 0 0 0;
	padding: 0 25px;
	color: white;
	font-size: 9pt;
	line-height: 42px;
	font-family: Montserrat-Regular, Arial, sans-serif;
	text-align: center;
	background-color: var(--darkerGraySkyTill);
	border-radius: 10px;
	cursor: pointer;
	transition: all .2s;
}
.actionDefined > .box > .nav.double > .choice {
	width: calc(50% - 62px);
}
.actionDefined > .box > .nav.double > .choice.disable {
	cursor: auto;
	opacity: 0.4;
	filter: alpha(opacity=40);
}
.actionDefined > .box > .nav > .choice:not(.disable):hover {
	background-color: var(--darkerLessGraySkyTill);
}
.actionDefined > .box > .nav > .choice > svg {
	width: 30px;
	height: 30px;
	margin: 25px auto 10px auto;
	fill: #FFFFFF;
}
.actionDefined > .box > .nav > .choice > .title {
	margin: 0;
	padding: 0 0 7px 0;
	text-align: center;
	font-size: 10pt;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
}
.actionDefined > .box > .nav > .choice > .description {
	margin: 0;
	padding: 0 0 20px 0;
	text-align: center;
	font-size: 9pt;
	line-height: 18px;
}
.actionDefined > .box > .close {
	margin: 15px 0 0 0;
	padding: 0 0 25px 0;
	color: var(--lightGraySkyTill);
	text-align: center;
	font-size: 9pt;
	font-family: Montserrat-Regular, Arial, sans-serif;
	cursor: pointer;
	transition: all .2s;
}
.actionDefined > .box > .close:hover {
	color: white;
}
.actionDefined > .box > .buttonsContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	margin: 15px;
	padding: 0;
}
.actionDefined > .box > .buttonsContainer > .cancel {
	display: block;
	margin: 30px 15px 0 0;
	padding: 0 20px;
	color: rgb(100, 100, 100);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	font-size: var(--fontSizeGeneralText);
	line-height: 40px;
	text-transform: uppercase;
	font-weight: bold;
	background-color: transparent;
	transition: all 0.15s;
}
.actionDefined > .box > .buttonsContainer > .cancel:hover {
	color: rgb(50, 50, 50);
	text-decoration: underline;
}
.actionDefined > .box > .buttonsContainer > .cancel:focus {
	outline: none;
}
.actionDefined > .box > .buttonsContainer > .submit {
	display: block;
	clear: both;
	float: right;
	margin: 30px 0 0 0;
	padding: 0 20px;
	line-height: 40px;
	background-color: var(--mainColor);
	color: white;
	border-radius: 5px;
	border: none;
	font-size: var(--fontSizeGeneralText);
	font-weight: bold;
	transition: all 0.15s;
}
.actionDefined > .box > .buttonsContainer > .submit.locked {
	opacity: 0.5;
	filter: alpha(opacity=50);
	zoom: 1;
}
.actionDefined > .box > .buttonsContainer > .submit:not(.locked):hover {
	cursor: pointer;
	background-color: var(--mainColorHover);
}
.actionDefined > .box > .buttonsContainer > .submit:not(.locked):focus {
	outline: none;
}
.actionDefined input[type="text"] {
	text-align: center;
}
@keyframes appearDefineBox {
	from {
		opacity: 0;
		filter: alpha(opacity=0);
	}
	to {
		opacity: 1;
		filter: alpha(opacity=100);
	}
}

.screenCard > .savingBar {
	position: fixed;
	z-index: 101;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 450px;
	height: 30px;
	left: 50%;
	bottom: -60px;
	margin: 0;
	padding: 10px;
	background-color: #333333;
	border-radius: 5px;
	transform: translate(-50%, 0);
	box-shadow: 0 0 6px rgba(0, 0, 0, .5);
	transition: all .2s;
}
.screenCard > .savingBar.visible {
	bottom: 10px;
}
.screenCard > .savingBar > .text {
	display: block;
	margin: 0;
	padding: 0 15px 0 5px;
	text-transform: none;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	color: var(--whiteSkyTill);
}
.screenCard > .savingBar > .save {
	display: block;
	margin: 0 0 0 15px;
	padding: 0 15px;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	font-weight: bold;
	background-color: var(--orangeSkyTill);
	color: var(--whiteSkyTill);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	transition: all 0.15s;
}
.screenCard > .savingBar > .save:hover {
	background-color: var(--orangeHoverSkyTill);
}

.screenCard > .errorBar {
	position: fixed;
	z-index: 101;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 400px;
	height: 30px;
	left: 50%;
	bottom: -60px;
	margin: 0;
	padding: 10px;
	background-color: var(--redDarkSkyTill);
	border-radius: 5px;
	transform: translate(-50%, 0);
	box-shadow: 0 0 6px rgba(0, 0, 0, .5);
	transition: all .2s;
}
.screenCard > .errorBar.visible {
	bottom: 10px;
}
.screenCard > .errorBar > .text {
	display: block;
	margin: 0;
	padding: 0 15px 0 5px;
	text-transform: none;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	color: var(--whiteSkyTill);
}
.screenCard > .errorBar > .refresh {
	display: block;
	margin: 0 0 0 15px;
	padding: 0 15px;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	font-weight: bold;
	background-color: var(--redSkyTill);
	color: var(--whiteSkyTill);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	transition: all 0.15s;
}
.screenCard > .errorBar > .refresh:hover {
	background-color: var(--redHoverSkyTill);
}

.screenCard > .warningBar {
	position: fixed;
	z-index: 101;
	width: 400px;
	height: 30px;
	left: 50%;
	bottom: -60px;
	margin: 0;
	padding: 10px;
	background-color: var(--orangeSkyTill);
	border-radius: 5px;
	transform: translate(-50%, 0);
	box-shadow: 0 0 6px rgba(0, 0, 0, .5);
	transition: all .2s;
}
.screenCard > .warningBar.visible {
	bottom: 10px;
}
.screenCard > .warningBar > .text {
	display: block;
	margin: 0;
	padding: 0 5px 0 5px;
	text-transform: none;
	text-align: center;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	color: var(--whiteSkyTill);
}

.screenCard > .globalApplyBar {
	position: fixed;
	z-index: 101;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 510px;
	height: 30px;
	left: 50%;
	bottom: -60px;
	margin: 0;
	padding: 10px;
	background-color: #333333;
	border-radius: 5px;
	transform: translate(-50%, 0);
	box-shadow: 0 0 6px rgba(0, 0, 0, .5);
	transition: all .2s;
}
.screenCard > .globalApplyBar.visible {
	bottom: 10px;
}
.screenCard > .globalApplyBar > .text {
	display: block;
	margin: 0;
	padding: 0 15px 0 5px;
	text-transform: none;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	color: var(--whiteSkyTill);
}
.screenCard > .globalApplyBar > .apply {
	display: block;
	margin: 0 0 0 15px;
	padding: 0 15px;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	font-weight: bold;
	background-color: var(--greenSkyTill);
	color: var(--whiteSkyTill);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	transition: all 0.15s;
}
.screenCard > .globalApplyBar > .apply:hover {
	background-color: var(--greenHoverSkyTill);
}