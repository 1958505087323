.main.sale > .searchBar {
	display: block;
	width: calc(100% - 52px);
	height: 37px;
	margin: 20px 15px;
	font-family: inherit;
	padding: var(--paddingInputText);
	font-size: var(--fontSizeInputText);
	color: var(--textColorInput);
	border-radius: var(--borderRadiusInputText);
	border: var(--borderSearchBar);
	background-color: var(--backgroundColorInput);
	transition: all 0.15s;
}
.main.sale > .searchBar:hover {
	background-color: var(--backgroundColorInputHover);
}
.main.sale > .searchBar:focus {
	outline: none;
	border-color: rgba(0, 40, 122, 0.98);
}

.main.sale > .wrapList > .list th.number {
	text-align: left;
}
.main.sale > .wrapList > .list th.seller {
	text-align: left;
}
.main.sale > .wrapList > .list th.amount {
	text-align: right;
}
.main.sale > .wrapList > .list th.article {
	text-align: center;
}
.main.sale > .wrapList > .list th.source {
	text-align: left;
}
.main.sale > .wrapList > .list th.date {
	text-align: left;
}
