.overbox.FormProductInformation {
    overflow: visible;
	width: calc(100vw - 100px);
    height: calc(100vh - 100px);
	margin: 0 auto;
	padding: 0;
}
.overbox.FormProductInformation.start {
	width: 500px;
    height: auto;
	padding: 15px;
}
.overbox.FormProductInformation.pricelist {
	width: calc(100vw - 200px);
	height: calc(100vh - 200px);
	padding: 15px;
}

.overbox.FormProductInformation .wrapPricelist {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	padding: 0;
}

.overbox.FormProductInformation .wrapPricelist > .pricelist {
	display: block;
	margin: 0 0 15px 0;
	padding: 0;
	background-color: #DDDDDD;
}
.overbox.FormProductInformation .wrapPricelist > .pricelist.default {
	background-color: var(--backgroundColorDefaultPricelist);
}

.overbox.FormProductInformation .wrapPricelist > .pricelist > .title {
	margin: 0;
	padding: 10px 15px 0 15px;
	color: var(--textColor);
	text-transform: uppercase;
	font-size: 11pt;
	line-height: 30px;
	text-align: left;
}

.overbox.FormProductInformation .wrapPricelist > .pricelist > .price {
	margin: 0;
	padding: 15px;
}



@media only screen and (max-width: 1000px) {
	.overbox.FormProductInformation .wrapPricelist > .pricelist {
		width: 100%;
	}
}
@media only screen and (min-width: 1001px) {
	.overbox.FormProductInformation .wrapPricelist > .pricelist {
		width: calc(50% - 7.5px);
	}
}
