.main.catalog .wrapList .list .checkbox {
	text-align: center;
	width: 30px;
	min-width: 30px;
	max-width: 30px;
}
.main.catalog .wrapList .list .withWaiting {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 0;
	padding: 0;
}
.main.catalog .wrapList .list .withWaiting > .waiting {
	width: 24px;
	height: 24px;
	margin: 7px 10px 0 0;
	padding: 0;
}

.main.catalog .wrapList .list .name {
	width: 50%;
	min-width: 150px;
	max-width: 80%;
	text-align: left;
}
.main.catalog .wrapList .list .name > .withWaiting > div,
.main.catalog .wrapList .list .name > div:not(.withWaiting) {
	float: left;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	margin: 0;
	padding: 0 10px 0 0;
	border-width: 1px;
	border-style: solid;
	border-radius: 10px;
}
.main.catalog .wrapList .list .name > .withWaiting > div > .color,
.main.catalog .wrapList .list .name > div:not(.withWaiting) > .color {
	display: block;
	width: 15px;
	height: 36px;
	margin: 0;
	padding: 0;
}
.main.catalog .wrapList .list .name > .withWaiting > div > .color {
	height: 100%;
}
.main.catalog .wrapList .list .name > .withWaiting > div > .text,
.main.catalog .wrapList .list .name > div:not(.withWaiting) > .text {
	display: block;
	margin: 0;
	padding: 0 0 0 10px;
	font-size: 9pt;
	line-height: 36px;
}

.main.catalog .wrapList .list .licenses {
	width: 50%;
	min-width: 250px;
	max-width: 50%;
	text-align: left;
}
.main.catalog .wrapList .list .licenses > .wrapLicenses {
	display: flex;
	flex-flow: row;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0;
	padding: 0;
}
.main.catalog .wrapList .list .licenses > .wrapLicenses > .list {
	width: auto;
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 25px;
	background-color: transparent;
}
.main.catalog .wrapList .list .licenses > .wrapLicenses > .button {
	display: block;
	width: 70px;
	height: 25px;
	margin: 0 0 0 15px;
	padding: 0;
	font-size: 8pt;
	line-height: 25px;
	text-align: center;
	background-color: var(--darkerGraySkyTill);
	color: var(--whiteSkyTill);
	border-radius: 3px;
	transition: all .15s;
	cursor: pointer;
}
.main.catalog .wrapList .list .licenses > .wrapLicenses > .button.locked {
	cursor: not-allowed;
	opacity: .5;
	filter: alpha(opacity=50);
}
.main.catalog .wrapList .list .licenses > .wrapLicenses > .button:not(.locked):hover {
	background-color: var(--darkGraySkyTill);
}

.main.catalog .wrapList .list .origin {
	min-width: 200px;
	text-align: left;
	color: var(--darkerGraySkyTill);
}

@media (max-width: 720px) {
	.main.catalog .wrapList .list .origin {
		display: none;
	}
}
@media (max-width: 1100px) {
	.main.catalog .wrapList .list .updated_at {
		display: none;
	}
}
