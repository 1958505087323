.searchBox {
	display: block;
	position: relative;
	z-index: 102;
	left: 25px;
	top: 25px;
	width: 330px;
	max-height: calc(100vh - 100px);
	background-color: #333333;
	border-radius: 10px;
	box-shadow: 0 0 6px rgba(0, 0, 0, .5);
	opacity: 0;
	filter: alpha(opacity=0);
	transition: all 0.3s ease;
}
.searchBox.overflowHidden {
	overflow: hidden;
}
.searchBox.visible {
	opacity: 1;
	filter: alpha(opacity=100);
	animation: appearSearchBox 0.25s ease;
}
.searchBox.hidden {
	opacity: 0;
	filter: alpha(opacity=0);
	animation: disappearSearchBox 0.05s ease;
}
.searchBox.side {
	left: -250px;
	opacity: 0.5;
	filter: alpha(opacity=50);
}

.searchBox > .searchBar {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%;
	height: 50px;
	margin: 0;
	padding: 0;
}
.searchBox > .searchBar.noMarginTop {
	height: 40px;
}
.searchBox > .searchBar.noInput {
	justify-content: flex-end;
}
.searchBox > .searchBar > .containerTitle {
	display: flex;
	flex-direction: row;
}
.searchBox > .searchBar > .containerTitle > .backLink {
	display: block;
	fill: #FFFFFF;
	width: 20px;
	height: 20px;
	margin: 15px 0 0 10px;
	padding: 0;
	cursor: pointer;
	transition: all .15s;
}
.searchBox > .searchBar > .containerTitle > .backLink:hover {
	fill: #A8A8A8;
}
.searchBox > .searchBar > .containerTitle > .title {
	margin: 10px 0 0 15px;
	padding: 0;
	font-size: 10pt;
	line-height: 30px;
	color: #FFFFFF;
}
.searchBox > .searchBar > input {
	width: calc(100% - 80px);
	height: 30px;
	margin: 10px 0 0 10px;
	padding: 0 15px;
	border: none;
	outline: none;
	border-radius: 15px;
	font-size: 8pt;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
	color: var(--blackSkyTill);
	transition: all .15s;
}
.searchBox > .searchBar > input.text {
	margin-right: 10px;
}
.searchBox > .searchBar > select {
	width: calc(100% - 25px);
	height: 30px;
	margin: 10px 0 0 10px;
	padding: 0 45px 0 15px;
	border: none;
	outline: none;
	border-radius: 15px;
	font-size: 8pt;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
	color: var(--blackSkyTill);
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-position: right 15px center;
	background-size: 14px 14px;
	background-repeat:  no-repeat;
	background-image: url(../../../../image/ressource/down-arrow.svg);
	transition: all .15s;
}
.searchBox > .searchBar.noMarginTop > select {
	margin-top: 0;
}
.searchBox > .searchBar > input:hover,
.searchBox > .searchBar > select:hover {
	background-color: #F0F0F0;
}
.searchBox > .searchBar > .containerIcons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.searchBox > .searchBar > .containerIcons > svg {
	display: block;
	fill: #FFFFFF;
	width: 20px;
	height: 20px;
	margin: 15px 15px 0 0;
	padding: 0;
	cursor: pointer;
	transition: all .15s;
}
.searchBox > .searchBar > .containerIcons > .close:hover {
	fill: var(--redHoverSkyTill);
}
.searchBox > .searchBar > .containerIcons > .access:hover {
	fill: var(--blueSkyTill);
}

.searchBox > .scroller {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	margin: 0;
	padding: 0;
}

.searchBox > ul {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.searchBox > ul > li {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 0;
	padding: 12px 30px 12px 15px;
	font-size: 8pt;
	line-height: 18px;
	font-family: Montserrat-Regular, Arial, sans-serif;
	color: var(--whiteSkyTill);
	transition: all 0.15s;
	cursor: pointer;
}
.searchBox > ul > li.custom {
	background-color: var(--darkerGraySkyTill);
}
.searchBox > ul > li:not(.custom):hover {
	background-color: #4D4D4D;
}
.searchBox > ul > li.custom:hover {
	background-color: var(--darkerMoreGraySkyTill);
}
.searchBox > ul > li > svg {
	width: 14px;
	height: 14px;
	margin: 0 10px 0 0;
	padding: 0;
	fill: var(--whiteSkyTill);
}
.searchBox > ul > li > .details,
.searchBox > ul > li > .code {
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	padding: 0 10px 0 0;
	color: var(--lightGraySkyTill);
}
.searchBox > ul > li > .details {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
}
.searchBox > ul > li > .code {
	width: 40px;
	min-width: 40px;
	max-width: 40px;
}
.searchBox > ul > li > .name,
.searchBox > ul > li > .label {
	margin: 0;
	padding: 0;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
}
.searchBox > ul > li.keyboarded > .name,
.searchBox > ul > li.keyboarded > .label {
	color: var(--orangeHoverSkyTill);
}
.searchBox > ul > li > .name {
	max-width: calc(100% - 90px);
}
.searchBox > ul > li > .name.container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 0;
	padding: 0;
}
.searchBox > ul > li > .name.indent {
	padding-left: 30px;
	max-width: calc(100% - 120px);
}
.searchBox > ul > li > .name > svg {
	width: 14px;
	height: 14px;
	margin: 0 10px 0 0;
	padding: 0;
	fill: var(--whiteSkyTill);
}
.searchBox > ul > li > .name > p {
	display: block;
	margin: 0;
	padding: 0;
}
.searchBox > ul > li > .label {
	max-width: calc(100% - 50px);
}
.searchBox > ul > li > .details > .catalog {
	margin: 0;
	padding: 0;
}
.searchBox > ul > li > .details > .category {
	margin: 0;
	padding: 0;
}

.searchBox > .scroller > .containerZoneAccess {
	margin: 15px;
	padding: 0;
}
.searchBox > .scroller > .containerZoneAccess > .itemZone {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 10px 0;
	padding: 12px 5px 12px 15px;
	cursor: pointer;
	border-radius: 5px;
	background-color: #444444;
	transition: all .15s;
}
.searchBox > .scroller > .containerZoneAccess > .itemZone:hover {
	background-color: #555555;
}
.searchBox > .scroller > .containerZoneAccess > .itemZone > p {
	margin: 0;
	padding: 0;
	color: white;
	font-size: 8pt;
	text-align: left;
}
.searchBox > .scroller > .containerZoneAccess > .itemZone > svg {
	fill: #FFFFFF;
	width: 10px;
	margin: 0;
	padding: 0 15px 0 0;
	transition: all .15s;
}
.searchBox > .scroller > .containerZoneAccess > .itemZone:hover > svg {
	padding-right: 10px;
}

.searchBox .form .savingBar {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 280px;
	height: 30px;
	margin: 15px 0 0 0;
	padding: 10px;
	background-color: var(--darkerGraySkyTill);
	border-radius: 5px;
}
.searchBox .form .savingBar.wizz {
	animation: wizzSearchBox 1.2s linear;
}
.searchBox .form .savingBar > .reinit {
	display: block;
	margin: 0;
	padding: 0 0 0 5px;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	color: var(--whiteSkyTill);
	font-weight: bold;
	cursor: pointer;
	transition: all 0.15s;
}
.searchBox .form .savingBar > .save {
	display: block;
	margin: 0 0 0 15px;
	padding: 0 15px;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	font-weight: bold;
	background-color: var(--mainColor);
	color: var(--whiteSkyTill);
	border-radius: 5px;
	border: none;
	cursor: pointer;
	transition: all 0.15s;
}
.searchBox .form .savingBar > .reinit:hover {
	color: var(--whiteAlphaSkyTill);
}
.searchBox .form .savingBar > .save:hover {
	background-color: var(--mainColorHover);
}

.searchBox .form .removeButton {
	display: block;
	margin: 15px 0 0 0;
	padding: 0;
	font-size: var(--fontSizeGeneralText);
	line-height: 30px;
	text-align: center;
	color: var(--redSkyTill);
	font-weight: bold;
	cursor: pointer;
	transition: all 0.15s;
}
.searchBox .form .removeButton:hover {
	color: var(--redHoverSkyTill)
}

@keyframes appearSearchBox {
	from {
		left: 10px;
		opacity: 0;
		filter: alpha(opacity=0);
	}
	to {
		left: 25px;
		opacity: 1;
		filter: alpha(opacity=100);
	}
}
@keyframes disappearSearchBox {
	from {
		left: 25px;
		opacity: 1;
		filter: alpha(opacity=100);
	}
	to {
		left: 10px;
		opacity: 0;
		filter: alpha(opacity=0);
	}
}
@keyframes wizzSearchBox {
	4% {
		margin-left: 10px;
		background-color: var(--redSkyTill);
	}
	8% {
		margin-left: -10px;
	}
	12% {
		margin-left: 10px;
	}
	16% {
		margin-left: -10px;
	}
	20% {
		margin-left: 10px;
	}
	24% {
		margin-left: 0;
	}
}