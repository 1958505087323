:root {
	--widthCalendarDay: 860px;
	--widthCalendarMonth: 240px;
	--widthCalendarQuarter: 240px;
	--widthCalendarYear: 500px;
}

.DateSelector {
	overflow: hidden;
	display: flex;
	flex-flow: nowrap;
	flex-direction: row;
	height: 40px;
	margin: 0 0 0 15px;
	padding: 0 15px;
	border-radius: 10px;
	background-color: var(--lighterGraySkyTill);
	cursor: pointer;
	transition: all .2s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.DateSelector.opened {
	background-color: var(--lightGraySkyTill);
}
.DateSelector:hover {
	background-color: var(--lightGraySkyTill);
}
.DateSelector > .icon {
	display: block;
	width: 20px;
	height: 20px;
	margin: 10px 15px 0 0;
	padding: 0;
	fill: var(--darkerGraySkyTill);
	transition: all .2s;
}
.DateSelector.opened > .icon,
.DateSelector:hover > .icon {
	fill: var(--lightBlackSkyTill);
}
.DateSelector > .containerText {
	margin: 0;
	padding: 0;
}
.DateSelector > .containerText > .text {
	margin: 0;
	padding: 9px 0 0 0;
	font-size: 8pt;
	color: var(--darkerGraySkyTill);
}
.DateSelector > .containerText > .text.noSubText {
	padding: 0;
	line-height: 40px;
}
.DateSelector > .containerText > .subText {
	margin: 0;
	padding: 1px 0 0 0;
	font-size: 7pt;
	color: var(--darkGraySkyTill);
}
.DateSelector.opened > .containerText > .text,
.DateSelector:hover > .containerText > .text {
	color: var(--lightBlackSkyTill);
}
.DateSelector:hover > .containerText > .subText,
.DateSelector:hover > .containerText > .subText {
	color: var(--darkerGraySkyTill);
}

.calendar {
	overflow: hidden;
	position: fixed;
	visibility: collapse;
	z-index: 99;
	padding: 15px;
	border-radius: 10px;
	background-color: var(--lighterGraySkyTill);
	transition: opacity .2s, filter .2s;
	opacity: 0;
	filter: alpha(opacity=0);
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.calendar.visible {
	visibility: visible;
	opacity: 1;
	filter: alpha(opacity=100);
}
.calendar.day {
	width: var(--widthCalendarDay);
}
.calendar.month {
	width: var(--widthCalendarMonth);
}
.calendar.quarter {
	width: var(--widthCalendarQuarter);
}
.calendar.year {
	width: var(--widthCalendarYear);
}

.calendar > .arrows {
	position: absolute;
	display: flex;
	flex-direction: row;
	flex-flow: nowrap;
	justify-content: space-between;
	width: var(--widthCalendarDay);
	height: 16px;
}
.calendar > .arrows.day {
	width: var(--widthCalendarDay);
}
.calendar > .arrows.month {
	width: var(--widthCalendarMonth);
}
.calendar > .arrows.quarter {
	width: var(--widthCalendarQuarter);
}
.calendar > .arrows.year {
	visibility: collapse;
}
.calendar > .arrows > .arrow {
	width: 16px;
	height: 16px;
	margin: -8px 0 0 0;
	padding: 7px;
	cursor: pointer;
	fill: var(--darkGraySkyTill);
	transition: all .2s;
}
.calendar > .arrows > .arrow.left {
	margin-left: -8px;
}
.calendar > .arrows > .arrow.right {
	margin-right: -8px;
}
.calendar > .arrows > .arrow:hover {
	fill: var(--darkerMoreGraySkyTill);
}

.calendar > .container {
	display: flex;
	flex-direction: row;
	flex-flow: nowrap;
	margin: 0;
	padding: 0;
}
.calendar.day > .container {
	margin: 0 0 0 calc(var(--widthCalendarDay) * -1);
	width: calc(var(--widthCalendarDay) * 4);
}
.calendar.month > .container {
	margin: 0 0 0 calc(var(--widthCalendarMonth) * -1);
	width: calc(var(--widthCalendarMonth) * 3);
}
.calendar.quarter > .container {
	margin: 0 0 0 calc(var(--widthCalendarQuarter) * -1);
	width: calc(var(--widthCalendarQuarter) * 3);
}
.calendar.year > .container {
	margin: 0;
	width: var(--widthCalendarYear);
}
.calendar > .container > .item {
	margin: 0;
	padding: 0 15px;
}
.calendar.day > .container > .item {
	width: 400px;
}
.calendar.month > .container > .item,
.calendar.quarter > .container > .item {
	width: 240px;
}
.calendar.quarter > .container > .year {
	width: calc(100% - 30px);
}
.calendar > .container > .item > .title {
	margin: 0;
	padding: 0;
	font-size: 9pt;
	line-height: 16px;
	text-align: center;
	color: var(--darkerGraySkyTill);
}
.calendar > .container > .item > .title.now {
	color: var(--orangeSkyTill);
}
.calendar > .container > .item > table {
	width: 100%;
	margin: 0;
	padding: 0;
	table-layout: fixed;
	border-collapse: collapse;
}
.calendar > .container > .item > table > tbody > tr {
	width: 100%;
	margin: 0;
	padding: 0;
	text-align: center;
}
.calendar > .container > .item > table > tbody > tr > th,
.calendar > .container > .item > table > tbody > tr > td {
	margin: 0;
	padding: 0;
	width: 100%;
}
.calendar > .container > .item > table > tbody > .header {
	height: 40px;
}
.calendar > .container > .item > table > tbody > .header.empty {
	height: 20px;
}
.calendar > .container > .item > table > tbody > tr > th > p,
.calendar > .container > .item > table > tbody > tr > td > p {
	display: inline-block;
	width: 50px;
	height: 50px;
	margin: 0;
	padding: 0;
	line-height: 50px;
	text-align: center;
}
.calendar.month > .container > .item > table > tbody > tr > th > p,
.calendar.month > .container > .item > table > tbody > tr > td > p,
.calendar.quarter > .container > .item > table > tbody > tr > th > p,
.calendar.quarter > .container > .item > table > tbody > tr > td > p,
.calendar.year > .container > .item > table > tbody > tr > th > p,
.calendar.year > .container > .item > table > tbody > tr > td > p {
	width: 100%;
}
.calendar > .container > .item > table > tbody > tr > .light {
	color: var(--darkGraySkyTill);
	font-size: 9pt;
	font-family: Montserrat-Light, Arial, sans-serif;
}
.calendar > .container > .item > table > tbody > tr > th > .week {
	border-radius: 25px;
	cursor: pointer;
}
.calendar > .container > .item > table > tbody > tr > th > .week:hover {
	background-color: var(--lightGraySkyTill);
}
.calendar > .container > .item > table > tbody > tr > td > .selectable {
	color: var(--lightBlackSkyTill);
	font-size: 9pt;
	font-family: Montserrat-Regular, Arial, sans-serif;
	font-weight: bold;
	border-radius: 25px;
	cursor: pointer;
}
.calenda.month > .container > .item > table > tbody > tr > td > .selectable,
.calenda.quarter > .container > .item > table > tbody > tr > td > .selectable,
.calenda.year > .container > .item > table > tbody > tr > td > .selectable {
	margin: 0 auto;
}
.calendar > .container > .item > table > tbody > tr > td > .selectable.now {
	color: var(--orangeSkyTill);
}
.calendar > .container > .item > table > tbody > tr > td > .selectable.selected {
	background-color: var(--lightBlueAlphaSkyTill);
}
.calendar > .container > .item > table > tbody > tr > td > .selectable.start,
.calendar > .container > .item > table > tbody > tr > td > .selectable.end,
.calendar > .container > .item > table > tbody > tr > td > .selectable.alone,
.calendar > .container > .item > table > tbody > tr > td > .selectable.between {
	background-color: var(--lightBlueSkyTill);
}
.calendar > .container > .item > table > tbody > tr > td > .selectable.start {
	border-radius: 25px 0 0 25px;
}
.calendar > .container > .item > table > tbody > tr > td > .selectable.end {
	border-radius: 0 25px 25px 0;
}
.calendar > .container > .item > table > tbody > tr > td > .selectable.alone {
	border-radius: 25px;
}
.calendar > .container > .item > table > tbody > tr > td > .selectable.between {
	border-radius: 0;
}
.calendar > .container > .item > table > tbody > tr > td > .selectable:not(.selected):hover {
	background-color: var(--lightGraySkyTill);
}

.calendar > .footer {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 0;
	padding: 0;
}
.calendar > .footer > .shorcuts {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
}
.calendar > .footer > .shortcuts > .short {
	display: inline-block;
	height: 36px;
	margin: 15px 10px 0 0;
	padding: 0 20px;
	font-size: 7pt;
	line-height: 36px;
	color: var(--darkerGraySkyTill);
	border-radius: 20px;
	border: 2px solid var(--lightGraySkyTill);
	transition: all .2s;
	cursor: pointer;
}
.calendar > .footer > .shortcuts > .short:hover {
	background-color: var(--lightGraySkyTill);
	color: var(--darkerMoreGraySkyTill);
}
.calendar > .footer > .valid {
	height: 40px;
	margin: 15px 0 0 0;
	padding: 0 25px;
	font-size: 8pt;
	line-height: 40px;
	color: var(--whiteSkyTill);
	border-radius: 20px;
	background-color: var(--darkBlueSkyTill);
	transition: all .2s;
	cursor: pointer;
}
.calendar > .footer > .valid:hover {
	filter: brightness(130%);
}