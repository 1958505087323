.overbox.FormSellerInformation {
    overflow: visible;
	width: calc(100vw - 100px);
    height: calc(100vh - 100px);
	margin: 0 auto;
	padding: 0;
}
.overbox.FormSellerInformation.start {
	width: 500px;
    height: auto;
	padding: 15px;
}
