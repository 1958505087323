.errorList {
	overflow: hidden;
	height: 40px;
	max-height: 40px;
	margin: 0;
	padding: 0 15px 0 50px;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: var(--fontSizeGeneralText);
	line-height: 40px;
	border-radius: 5px;
	color: var(--whiteSkyTill);
	background-color: var(--redSkyTill);
	background-repeat: no-repeat;
	background-image: url(../../../../image/ressource/error-white.svg);
	background-size: 16pt 16pt;
	background-position: left 15px center;
}